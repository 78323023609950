angular.module 'app'
.controller 'QrcodeResponseCtrl', ($rootScope, $scope, $http, $state, $uibModalInstance, $stateParams, config, Storage, dialogs, growl, data) ->

  $scope.ui =
    qrcode: []
    fansGroups: []
    rule:
      man: false
      male: false
      cn: false
      tw: false
      en: false
    resourcesUrl: config.api.baseUrl

  # 查詢粉絲分組
  findGroups = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      $scope.ui.fansGroups = res.data.data
      $scope.ui.fansGroups.unshift {name: '--- 無 ---', tag_id: 'notagId'}
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  do initialization = ->
    $scope.ui.qrcode = data.qrcode
    $scope.ui.qrcode.action = {} if !$scope.ui.qrcode.action
    if !$scope.ui.qrcode.is_action
      $scope.ui.rule.man = true
      $scope.ui.rule.male = true
      $scope.ui.rule.cn = true
      $scope.ui.rule.tw = true
      $scope.ui.rule.en = true
      $scope.ui.qrcode.is_action = true
      $scope.ui.qrcode.rule = {}
      $scope.ui.qrcode.rule.start_time = "00:00"
      $scope.ui.qrcode.rule.end_time = "23:59"
      if !$scope.ui.qrcode.rule.sexs or $scope.ui.qrcode.rule.sexs.length > 1
        $scope.ui.qrcode.rule.sexs = ["1", "2"]
      if !$scope.ui.qrcode.rule.languages or $scope.ui.qrcode.rule.languages.length > 2
        $scope.ui.qrcode.rule.languages = ["zh_CN", "zh_TW", "en"]
    else
      $scope.ui.rule.man = true if _.contains $scope.ui.qrcode.rule.sexs, "1"
      $scope.ui.rule.male = true if _.contains $scope.ui.qrcode.rule.sexs, "2"
      $scope.ui.rule.cn = true if _.contains $scope.ui.qrcode.rule.languages, "zh_CN"
      $scope.ui.rule.tw = true if _.contains $scope.ui.qrcode.rule.languages, "zh_TW"
      $scope.ui.rule.en = true if _.contains $scope.ui.qrcode.rule.languages, "en"

    findGroups()

  $scope.switchSendAction = (_action) ->
    $scope.ui.qrcode.action.reply_type = _action
    $scope.ui.qrcode.action = {} if !$scope.ui.qrcode.action

  # 选择图文
  $scope.selectGraphic = ->
    dialogs.create('apps/material/graphic/modal/modal', 'MaterialModalCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.qrcode.action.articles = option[0].articles
      $scope.ui.qrcode.action.material_id = option[0]._id
    ), ->

  # 删除图文
  $scope.delGraphic = ->
    $scope.ui.qrcode.action.articles = []
    $scope.ui.qrcode.action.material_id = ''

  $scope.selectImage = ->
    dialogs.create('apps/material/image/choose/choose', 'ImageChooseCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.qrcode.action = {} if !$scope.ui.qrcode.action
      $scope.ui.qrcode.action.path = option[0].file.path
      $scope.ui.qrcode.action.material_id = option[0]._id
    ), ->

  # 删除图片
  $scope.delImage = ->
    $scope.ui.qrcode.action.path = ''
    $scope.ui.qrcode.action.material_id = ''

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $scope.ui.qrcode.rule.sexs = []
    $scope.ui.qrcode.rule.languages = []
    $scope.ui.qrcode.rule.sexs.push "1" if $scope.ui.rule.man == true
    $scope.ui.qrcode.rule.sexs.push "2" if $scope.ui.rule.male == true
    $scope.ui.qrcode.rule.languages.push "zh_CN" if $scope.ui.rule.cn == true
    $scope.ui.qrcode.rule.languages.push "zh_TW" if $scope.ui.rule.tw == true
    $scope.ui.qrcode.rule.languages.push "en" if $scope.ui.rule.en == true

    $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/#{$scope.ui.qrcode._id}?access_token=" + Storage.get('access_token'), $scope.ui.qrcode
    .then (res) ->
      $uibModalInstance.close 'Save'
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
