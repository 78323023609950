angular.module 'app'
.controller 'QrcodeAddCtrl', ($rootScope, $scope, $http, $state, $uibModalInstance, $stateParams, config, Storage, dialogs, growl, data) ->
  $scope.ui =
    data: if data.data then false else true

    flow: 1

    qrcode:
      type: "QR_LIMIT_STR_SCENE"
      # valid_time: ''
      timeout_date: moment().format('YYYY-MM-DD HH:mm')
      name: ''
      scene:
        scene_value: ''

      action:
        reply_type: 'news'
        material_id: ''

      rule:
        start_time: '00:00'
        end_time: '23:59'
        sexs: []
        languages: []

      scan_event:
        tag_id: 'notagId'

      is_action: false

    rule:
      man: true
      male: true
      cn: true
      tw: true
      en: true

    action: {}
    image: {}
    fansGroups: []

    qrcode_url: ''

    tableData: []

    btnDisabled: false

    resourcesUrl: config.resources.baseUrl

    opts:
      autoApply: true
      parentEl: 'div#view-fans-message'
      maxDate: moment().add(30, 'days')
      minDate: moment().format('YYYY-MM-DD HH:mm')
      opens: 'center'
      timePicker: true
      timePicker24Hour: true
      timePickerSeconds: false
      singleDatePicker: true
      locale:
        format: 'YYYY-MM-DD HH:mm'
        applyLabel: '應用'
        cancelLabel: '取消'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']

  if data.data
    $scope.ui.flow = 3
    $scope.ui.tableData = data.data.qrcode_paths
    $scope.ui.wechat_url = data.data.wechat_url
    $scope.ui.qrcode_url = data.data.qrcode_url

  # 查詢粉絲分組
  findGroups = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      $scope.ui.fansGroups = res.data.data
      $scope.ui.fansGroups.unshift {name: '--- 無 ---', tag_id: 'notagId'}
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.switchSendAction = (_action) ->
    return if $scope.ui.qrcode.action.reply_type == _action
    $scope.ui.qrcode.action.reply_type = _action
    if $scope.ui.qrcode.action.reply_type != 'text'
      $scope.ui.qrcode.action.material_id = ''
      delete $scope.ui.qrcode.action.text
    else
      $scope.ui.qrcode.action.text = ''
      delete $scope.ui.qrcode.action.material_id

  # 选择图文
  $scope.selectGraphic = ->
    dialogs.create('apps/material/graphic/modal/modal', 'MaterialModalCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.action = option[0]
      $scope.ui.qrcode.action.material_id = option[0]._id
    ), ->

  # 删除图文
  $scope.delGraphic = ->
    $scope.ui.action = {}
    $scope.ui.qrcode.action.material_id = ''

  # 選擇圖片
  $scope.selectImage = ->
    dialogs.create('apps/material/image/choose/choose', 'ImageChooseCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.image = option[0]
      $scope.ui.qrcode.action.material_id = option[0]._id
    ), ->

  # 删除图片
  $scope.delImage = ->
    $scope.ui.image = {}
    $scope.ui.qrcode.action.material_id = ''


  $scope.cancel = ->
    $uibModalInstance.dismiss 'Canceled'

  $scope.last = ->
    $scope.ui.flow = 1

  $scope.next = ->
    return growl.error "名稱不可為空" if $scope.ui.qrcode.name == ''
    return growl.error "內容不可為空" if $scope.ui.qrcode.scene.scene_value == ''
    # return growl.error "時限不可為空" if $scope.ui.qrcode.type == 'QR_SCENE' && $scope.ui.qrcode.valid_time == ''
    findGroups()
    $scope.ui.flow = 2

  $scope.downloadQRCode = (data) ->
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/down/download?access_token=" + Storage.get('access_token'),
      httpMethod: 'GET'
      data:
        url: $scope.ui.resourcesUrl + data.path
        type: 'image'

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $scope.ui.btnDisabled = true
    growl.info "二維碼正在創建中..."

    $scope.ui.qrcode.rule.sexs = []
    $scope.ui.qrcode.rule.sexs.push '1' if $scope.ui.rule.man == true
    $scope.ui.qrcode.rule.sexs.push '2' if $scope.ui.rule.male == true

    $scope.ui.qrcode.rule.languages = []
    $scope.ui.qrcode.rule.languages.push "zh_CN" if $scope.ui.rule.cn == true
    $scope.ui.qrcode.rule.languages.push "zh_TW" if $scope.ui.rule.tw == true
    $scope.ui.qrcode.rule.languages.push "en" if $scope.ui.rule.en == true

    $scope.ui.qrcode.is_action = true if $scope.ui.qrcode.action.material_id != '' || $scope.ui.qrcode.action.text != ''

    $scope.ui.qrcode.rule = {} if $scope.ui.qrcode.is_action == false

    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew?access_token=" + Storage.get('access_token'), $scope.ui.qrcode
    .then (res) ->
      growl.success "二維碼創建成功"
      $scope.ui.qrcode_url = res.data.qrcode_url
      $scope.ui.tableData = res.data.qrcode_paths
      $scope.ui.flow = 3
      $scope.ui.btnDisabled = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.btnDisabled = false
