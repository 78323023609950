angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    # 素材
    .state 'app.ers-information',
      url: '/ers-information'
      templateUrl: 'apps/ers-information/layout/layout'
      controller: 'ErsInformationLayoutCtrl'

    # 素材－图片
    .state 'app.ers-information.ers-information-image',
      url: '/ers-information-image'
      templateUrl: 'apps/ers-information/image/image'
      controller: 'ErsInformationImageCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 素材－视频
    .state 'app.ers-information.ers-information-video',
      url: '/ers-information-video'
      templateUrl: 'apps/ers-information/video/video'
      controller: 'ErsInformationVideoCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 素材－图文
    .state 'app.ers-information.ers-information-graphic',
      url: '/ers-information-graphic'
      templateUrl: 'apps/ers-information/graphic/graphic'
      controller: 'ErsInformationGraphicCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])

     # 添加圖文
    .state 'app.ers-information.ers-information-detail',
      url: '/ers-information-detail/:_id/:group_id'
      templateUrl: 'apps/ers-information/graphic/detail/detail'
      controller: 'ErsInformationDetailCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])
