angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    # 素材
    .state 'app.anyp',
      url: '/anyp'
      templateUrl: 'apps/anyp/layout/layout'
      controller: 'AnypLayoutCtrl'

    # 素材－图片
    .state 'app.anyp.anyp-image',
      url: '/anyp-image'
      templateUrl: 'apps/anyp/image/image'
      controller: 'AnypImageCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 素材－视频
    .state 'app.anyp.anyp-video',
      url: '/anyp-video'
      templateUrl: 'apps/anyp/video/video'
      controller: 'AnypVideoCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 素材－图文
    .state 'app.anyp.anyp-graphic',
      url: '/anyp-graphic'
      templateUrl: 'apps/anyp/graphic/graphic'
      controller: 'AnypGraphicCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])

     # 添加圖文
    .state 'app.anyp.anyp-detail',
      url: '/anyp-detail/:_id/:group_id'
      templateUrl: 'apps/anyp/graphic/detail/detail'
      controller: 'AnypDetailCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])
