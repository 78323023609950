angular.module 'app'
.controller 'WholeCtrl', ($scope, $http, $stateParams, $state, config, Storage, $sce, growl, $timeout, dialogs, $rootScope) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').analysis.graphic.is_use == false

  $scope.ui =
    isLoading: false

    radioModel: '0'
    radioIndex: '0'
    radioTwo: '0'

    activeTableSort: ''

    yesterdayData: {}
    tableData: []
    trendData: []

    date:
      startDate: moment().subtract(29, 'days')
      endDate: moment()
    _date: moment()

    date1:
      startDate: moment().subtract(29, 'days')
      endDate: moment()
    _date1: moment()

    date2:
      startDate: moment().subtract(29, 'days')
      endDate: moment()
    _date2: moment()

    date3:
      startDate: moment().subtract(29, 'days')
      endDate: moment()
    _date3: moment()

    opts:
      autoApply: false
      singleDatePicker: false
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss')
      locale:
        applyClass: 'btn-green'
        applyLabel: "應用"
        fromLabel: "從"
        format: "YYYY-MM-DD"
        toLabel: "到"
        cancelLabel: '取消'
        customRangeLabel: '自定義範圍'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
      ranges:
          '今天': [moment(), moment()]
          '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
          '過去7天': [moment().subtract(6, 'days'), moment()]
          '最近30天': [moment().subtract(29, 'days'), moment()]
          '這個月': [moment().startOf('month'), moment().endOf('month')]
          '上個月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

    _opts:
      autoApply: true
      singleDatePicker: true
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss')
      locale:
        applyClass: 'btn-green'
        applyLabel: "應用"
        fromLabel: "從"
        format: "YYYY-MM-DD"
        toLabel: "到"
        cancelLabel: '取消'
        customRangeLabel: '自定義範圍'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
      ranges:
          '今天': [moment(), moment()]
          '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
          '過去7天': [moment().subtract(6, 'days'), moment()]
          '最近30天': [moment().subtract(29, 'days'), moment()]
          '這個月': [moment().startOf('month'), moment().endOf('month')]
          '上個月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#whole'
  $scope.ui.opts.opens = 'right'

  $scope.htmlPopover = $sce.trustAsHtml('<div class="help-popover" style="width: 300px"><dt>圖文頁閱讀人數</dt><dd>點擊圖文頁的去重人數，包括非粉絲；閱讀來源包括公眾號會話、朋友圈、好友轉發、歷史消息等</dd><dt>圖文頁閱讀次數</dt><dd>點擊圖文頁的次數，包括非粉絲的點擊；閱讀來源包括公眾號會話、朋友圈、好友轉發、歷史消息等</dd><dt>分享轉發人數</dt><dd>轉發或分享到朋友、朋友圈、微博的去重用戶數，包括非粉絲</dd><dt>分享轉發次數</dt><dd>轉發或分享到朋友、朋友圈、微博的次數，包括非粉絲的點擊</dd><dt>微信收藏人數</dt><dd>收藏到微信的去重用戶數，包括非粉絲</dd><dt>原文頁閱讀人數</dt><dd>點擊原文頁的去重人數，包括非粉絲</dd><dt>原文頁閱讀次數</dt><dd>點擊原文頁的次數，包括非粉絲的點擊</dd><dt>日、周、月</dt><dd>分別計算昨日數據相比1天、7天、30天前的變化情況</dd><dt>圖文頁</dt><dd>點擊圖文消息進入的頁面</dd><dt>原文頁</dt><dd>在圖文頁點擊“閱讀原文”進入的頁面</dd><dd class="border-top-solid padding-top-md">數據從2013年7月1日開始統計。由於服務器緩存，以及指標計算方法和統計時間的差異，數據可能出現微小誤差，一般在1%以內。</dd></div>')

  $scope.onePieChart =
    title:
      text: '閱讀來源分佈'
    tooltip:
      trigger: 'item'
      formatter: "{a} <br/>{b}({d}%)"
    legend:
      x: 'right'
      orient: 'vertical'
      itemGap: 30
      data: []
    series: [
        name: ''
        type: 'pie'
        radius: 0
        label:
          normal:
            position: 'center'
        itemStyle:
          normal:
            label:
              textStyle:
                color: '#000'
                fontSize: 16
        data: [
            name: '0次'
            tooltip:
              show: false
              formatter: ''
          ,
            name: '0人'
            tooltip:
              show: false
              formatter: ''
          ,
            name: '閱讀總數'
            tooltip:
              show: false
              formatter: ''
            itemStyle: normal: label: textStyle: color: '#aaa'
        ]
      ,
        name: '閱讀來源分佈'
        type: 'pie'
        radius: ['40%', '60%']
        label: normal: show: true
        labelLine: normal: show: true
        data: []
    ]

  $scope.twoPieChart =
    title:
      text: '發文位置閱讀量分佈'
    tooltip:
      trigger: 'item'
      formatter: "{a} <br/>{b}({d}%)"
    legend:
      x: 'right'
      orient: 'vertical'
      itemGap: 30
      data: []
    series: [
        name: ''
        type: 'pie'
        radius: [0]
        data: [
            value: 0
            name: null
        ]
      ,
        name: '閱讀來源分佈'
        type: 'pie'
        radius: [0, '60%']
        data: []
    ]

  $scope.oneLineChart =
    title:
      text: ''
    tooltip:
      trigger: 'axis'
    legend:
      x: 'center'
      y: 'bottom'
      data: ['圖文頁閱讀次數', '圖文頁閱讀人數']
    grid:
      containLabel: true
    toolbox:
      show: true
      feature:
        restore:
          show: true
        saveAsImage:
          show: true
    xAxis: [
        type: 'category'
        boundaryGap: false
        splitLine:
          show: false
        data: ['']
    ]
    yAxis: [
        type: 'value'
    ]
    series: [
        name: '圖文頁閱讀次數'
        type: 'line'
        data: [0]
      ,
        name: '圖文頁閱讀人數'
        type: 'line'
        data: [0]
    ]

  # 取绝对值
  $scope.abs = (data) ->
    return Math.abs data

  # 昨日数据
  getYesterdayData = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/keyrate?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      $scope.ui.yesterdayData = res.data
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  # 阅读来源分布
  getreadSourceData = ->
    $scope.ui.isLoading = true
    _type = if $scope.ui.radioModel is '0' then 'userread' else 'userreadhour'
    _start_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00') else moment($scope.ui._date).format('YYYY-MM-DD 00:00:00')
    _end_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59') else moment($scope.ui._date).format('YYYY-MM-DD 23:59:59')
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/all_source_analyse?access_token=" + Storage.get('access_token'),
      params:
        type: _type
        start_release_date: _start_release_date
        end_release_date: _end_release_date
    .then (res) ->
      $scope.onePieChart.series[1].data = []
      $scope.onePieChart.legend.data = ['']
      _user = 0
      _count = 0
      _.each res.data, (data) ->
        $scope.onePieChart.legend.data.push data.source_name + '\n' + data.int_page_read_count + '次，' + data.int_page_read_user + '人'
        $scope.onePieChart.series[1].data.push {value: data.int_page_read_user, name: data.source_name + '\n' + data.int_page_read_count + '次，' + data.int_page_read_user + '人'}
        _user += data.int_page_read_user
        _count += data.int_page_read_count
      $scope.onePieChart.series[0].data[0].name = _count + '次'
      $scope.onePieChart.series[0].data[1].name = _user + '人'
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  # 发文位置阅读量分布
  getreadQuantityData = ->
    $scope.ui.isLoading = true
    _start_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00') else moment($scope.ui._date).format('YYYY-MM-DD 00:00:00')
    _end_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59') else moment($scope.ui._date).format('YYYY-MM-DD 23:59:59')
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/all_location_reads?access_token=" + Storage.get('access_token'),
      params:
        start_release_date: _start_release_date
        end_release_date: _end_release_date
    .then (res) ->
      $scope.twoPieChart.legend.data = []
      $scope.twoPieChart.series[1].data = []
      _.each res.data, (data) ->
        $scope.twoPieChart.legend.data.push data.name + '\n' + data.int_page_read_count + '次，' + data.int_page_read_user + '人'
        $scope.twoPieChart.series[1].data.push {value: data.int_page_read_user, name: data.name + '\n' + data.int_page_read_count + '次，' + data.int_page_read_user + '人'}
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  # 趨勢圖
  getTrendData = ->
    $scope.ui.isLoading = true
    _type = if $scope.ui.radioModel is '0' then 'userread' else 'userreadhour'
    _start_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date1.startDate).format('YYYY-MM-DD 00:00:00') else moment($scope.ui._date1).format('YYYY-MM-DD 00:00:00')
    _end_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date1.endDate).format('YYYY-MM-DD 23:59:59') else moment($scope.ui._date1).format('YYYY-MM-DD 23:59:59')
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/all_tendency?access_token=" + Storage.get('access_token'),
      params:
        type: _type
        start_release_date: _start_release_date
        end_release_date: _end_release_date
    .then (res) ->
      $scope.ui.trendData = res.data
      setTrendData()
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  setTrendData = ->
    _type = 'all_source_int_page_read_' if $scope.ui.radioIndex == '0' && $scope.ui.radioTwo == '0'
    _type = 'session_int_page_read_' if $scope.ui.radioIndex == '0' && $scope.ui.radioTwo == '1'
    _type = 'friends_int_page_read_' if $scope.ui.radioIndex == '0' && $scope.ui.radioTwo == '2'
    _type = 'feed_int_page_read_' if $scope.ui.radioIndex == '0' && $scope.ui.radioTwo == '3'
    _type = 'hist_int_page_read_' if $scope.ui.radioIndex == '0' && $scope.ui.radioTwo == '4'
    _type = 'other_int_page_read_' if $scope.ui.radioIndex == '0' && $scope.ui.radioTwo == '5'
    _type = 'ori_page_read_' if $scope.ui.radioIndex == '1'
    _type = 'share_' if $scope.ui.radioIndex == '2'
    _type = 'add_to_fav_' if $scope.ui.radioIndex == '3'

    $scope.oneLineChart.xAxis[0].data = []
    $scope.oneLineChart.series[0].data = []
    $scope.oneLineChart.series[1].data = []

    $scope.oneLineChart.xAxis[0].data = _.pluck $scope.ui.trendData, 'statis_time'
    $scope.oneLineChart.series[0].data = _.pluck $scope.ui.trendData, _type + 'count'
    $scope.oneLineChart.series[1].data = _.pluck $scope.ui.trendData, _type + 'user'

  # 表格
  getAllData = ->
    $scope.ui.isLoading = true
    _type = if $scope.ui.radioModel is '0' then 'userread' else 'userreadhour'
    _start_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date3.startDate).format('YYYY-MM-DD 00:00:00') else moment($scope.ui._date3).format('YYYY-MM-DD 00:00:00')
    _end_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date3.endDate).format('YYYY-MM-DD 23:59:59') else moment($scope.ui._date3).format('YYYY-MM-DD 23:59:59')
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/all_news_statis?access_token=" + Storage.get('access_token'),
      params:
        type: _type
        start_release_date: _start_release_date
        end_release_date: _end_release_date
    .then (res) ->
      $scope.ui.tableData = res.data
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  $scope.sortTableData = (_index) ->
    return if $scope.ui.tableData.length == 0

    _.each $scope.ui.tableData, (data) ->
      data.statis_time = if $scope.ui.radioModel is '0' then moment(data.statis_time) else moment(moment($scope.ui._date3).format('YYYY-MM-DD ') + data.statis_time)

    $scope.ui.tableData.sort (a, b) ->
      if $scope.ui.activeTableSort != _index
        return b[_index] - a[_index]
      else
        return a[_index] - b[_index]

    if $scope.ui.activeTableSort != _index
      $scope.ui.activeTableSort = _index
    else
      $scope.ui.activeTableSort = ''

    _.each $scope.ui.tableData, (data) ->
      data.statis_time = if $scope.ui.radioModel is '0' then moment(data.statis_time).format('YYYY-MM-DD') else moment(data.statis_time).format('HH:mm')

  # 日报/小时报
  $scope.$watch 'ui.radioModel', (newValue, oldValue) ->
    getTrendData()
    getAllData()

    getYesterdayData() if newValue == '0'

    getreadSourceData() if $scope.ui.radioIndex == '0'

    getreadQuantityData() if $scope.ui.radioIndex == '0'

  #
  $scope.$watch 'ui.radioIndex', (newValue, oldValue) ->
    return if newValue == oldValue
    if newValue == '0'
      getreadSourceData()
      getreadQuantityData()
      setTrendData()
    else
      setTrendData()

  #
  $scope.$watch 'ui.radioTwo', (newValue, oldValue) ->
    return if newValue == oldValue
    setTrendData()

  # 阅读来源分布时间控件
  $scope.$watch 'ui.date', (newValue, oldValue) ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(29, 'days')
        endDate: moment()
      growl.error('请输入正确的时间')

    return if newValue == oldValue
    getreadSourceData()
    getreadQuantityData()
  $scope.$watch 'ui._date', (newValue, oldValue) ->
    if $scope.ui._date is null or String(new Date($scope.ui._date)) is 'Invalid Date'
      $scope.ui._date = moment()
      growl.error('请输入正确的时间')

    return if newValue == oldValue
    getreadSourceData()
    getreadQuantityData()

  # 趋势图时间控件
  $scope.$watch 'ui.date1', (newValue, oldValue) ->
    if $scope.ui.date1.startDate is null or $scope.ui.date1.endDate is null or String(new Date($scope.ui.date1.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date1.endDate)) is 'Invalid Date'
      $scope.ui.date1 =
        startDate: moment().subtract(29, 'days')
        endDate: moment()
      growl.error('请输入正确的时间')

    return if newValue == oldValue
    getTrendData()
  $scope.$watch 'ui._date1', (newValue, oldValue) ->
    if $scope.ui._date1 is null or String(new Date($scope.ui._date1)) is 'Invalid Date'
      $scope.ui._date1 = moment()
      growl.error('请输入正确的时间')

    return if newValue == oldValue
    getTrendData()

  # 表格时间控件
  $scope.$watch 'ui.date3', (newValue, oldValue) ->
    if $scope.ui.date3.startDate is null or $scope.ui.date3.endDate is null or String(new Date($scope.ui.date3.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date3.endDate)) is 'Invalid Date'
      $scope.ui.date3 =
        startDate: moment().subtract(29, 'days')
        endDate: moment()
      growl.error('请输入正确的时间')

    return if newValue == oldValue
    getAllData()
  $scope.$watch 'ui._date3', (newValue, oldValue) ->
    if $scope.ui._date3 is null or String(new Date($scope.ui._date3)) is 'Invalid Date'
      $scope.ui._date3 = moment()
      growl.error('请输入正确的时间')

    return if newValue == oldValue
    getAllData()

  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.downloadData = ->
    $scope.progress = 0
    _start_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date3.startDate).format('YYYY-MM-DD 00:00:00') else moment($scope.ui._date3).format('YYYY-MM-DD 00:00:00')
    _end_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date3.endDate).format('YYYY-MM-DD 23:59:59') else moment($scope.ui._date3).format('YYYY-MM-DD 23:59:59')
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/all_news_export?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        type: if $scope.ui.radioModel is '0' then 'userread' else 'userreadhour'
        start_release_date: _start_release_date
        end_release_date: _end_release_date
      prepareCallback: prepare
      successCallback: success
      failCallback: error
