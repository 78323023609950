angular.module 'app'
.controller 'NewsCtrl', ($scope, $http, $stateParams, $state, config, Storage, growl, $sce, $timeout, dialogs, $rootScope) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').analysis.news.is_use == false

  $scope.ui =
    msg_user: {}
    msg_count: {}
    avg_msg_cost: {}

    msg_dists: []
    msg_statis: []
    table: []
    search_type: "msg_user"
    search_type_name: "粉絲留言人數"

    radioModel: '0'
    radioIndex: '1'

    test: 33  # 進度條數值控制示例

    isContrast: false

    date:
      startDate: moment().subtract(7, 'days')
      endDate: moment()
    _date: moment()

    contrast:
      startDate: moment().subtract(14, 'days')
      endDate: moment().subtract(7, 'days')

    opts:
      autoApply: false
      singleDatePicker: false
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss')
      locale:
        applyClass: 'btn-green'
        applyLabel: "應用"
        fromLabel: "從"
        format: "YYYY-MM-DD"
        toLabel: "到"
        cancelLabel: '取消'
        customRangeLabel: '自定義範圍'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
      ranges:
          '今天': [moment(), moment()]
          '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
          '過去7天': [moment().subtract(6, 'days'), moment()]
          '最近30天': [moment().subtract(29, 'days'), moment()]
          '這個月': [moment().startOf('month'), moment().endOf('month')]
          '上個月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

    _opts:
      autoApply: true
      singleDatePicker: true
      maxDate: moment().format('YYYY-MM-DD HH:mm:ss')
      locale:
        applyClass: 'btn-green'
        applyLabel: "應用"
        fromLabel: "從"
        format: "YYYY-MM-DD"
        toLabel: "到"
        cancelLabel: '取消'
        customRangeLabel: '自定義範圍'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
      ranges:
          '今天': [moment(), moment()]
          '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
          '過去7天': [moment().subtract(6, 'days'), moment()]
          '最近30天': [moment().subtract(29, 'days'), moment()]
          '這個月': [moment().startOf('month'), moment().endOf('month')]
          '上個月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#analysis-news'
  $scope.ui.opts.opens = 'right'

  # 取绝对值
  $scope.abs = (data) ->
    return Math.abs data

  $scope.noContrast = ->
    $scope.ui.isContrast = false
    fetchStatis()

  $scope.htmlPopover = $sce.trustAsHtml('<div class="help-popover" style="width: 300px"><dt>粉絲留言人數</dt><dd>關注著主動粉絲留言的去重用戶數</dd><dt>粉絲留言次數</dt><dd>關注著主動粉絲留言的次數</dd><dt>人均留言次數</dt><dd>粉絲留言總次數/粉絲留言的去重用戶數</dd><dt>日、周、月</dt><dd>分別計算昨日數據相比1天、7天、30天前的變化情況</dd><dd class="border-top-solid padding-top-md">數據從2013年7月1日開始統計。由於服務器緩存，以及指標計算方法和統計時間的差異，數據可能出現微小誤差，壹般在1%以內。</dd></div>')

  $scope.newsChart =
    title:
      text: ''
    tooltip:
      trigger: 'axis'
    legend:
      data: ['粉絲留言人數', '粉絲留言次數', '人均留言次數']
    grid:
      containLabel: true
    toolbox:
      show: true
      feature:
        restore:
          show: true
        saveAsImage:
          show: true
    xAxis: [{
      type: 'category'
      boundaryGap: false
      splitLine:
        show: false
      data: ['2016-07-01', '2016-07-02', '2016-07-03', '2016-07-04', '2016-07-05', '2016-07-06']
    }]
    yAxis: [{
      type: 'value'
      axisLabel:
        formatter: '{value}'
    }]
    series: [
      name: '粉絲留言人數'
      type: 'line'
      data: [0, 0, 0, 0, 0, 0]
    ]

  fetchStatis = ->
    type = if $scope.ui.radioModel is "0" then "day" else "hour"
    _start_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00') else moment($scope.ui._date).format('YYYY-MM-DD 00:00:00')
    _end_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59') else moment($scope.ui._date).format('YYYY-MM-DD 23:59:59')
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/streammsg/msg_statis?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        start_statistical_time: _start_release_date
        end_statistical_time: _end_release_date
        type: type
    .then (resStatis) ->
      statis = resStatis.data
      _.each statis, (val) ->
        val.date = moment(val._id).format('YYYY-MM-DD')
      $scope.ui.msg_statis = statis
      # $scope.newsChart.tooltip.formatter = null
      $scope.newsChart.xAxis[0].data = _.pluck statis, "statis_time"
      $scope.newsChart.legend.data = [$scope.ui.search_type_name]
      obj =
        name: $scope.ui.search_type_name
        type: 'line'
        data: _.pluck statis, $scope.ui.search_type
      $scope.newsChart.series = []
      $scope.newsChart.series.push obj
      $scope.newsChart.series[0].name = $scope.ui.search_type_name
      $scope.newsChart.series[0].data = _.pluck statis, $scope.ui.search_type
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  fetchDist = ->
    type = if $scope.ui.radioModel is "0" then "day" else "hour"
    _start_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00') else moment($scope.ui._date).format('YYYY-MM-DD 00:00:00')
    _end_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59') else moment($scope.ui._date).format('YYYY-MM-DD 23:59:59')
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/streammsg/msg_dist?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        start_statistical_time: _start_release_date
        end_statistical_time: _end_release_date
        type: type
    .then (resDist) ->
      dist = _.sortBy resDist.data, (num) ->
        num.msg_dist
      $scope.ui.msg_dists = dist
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  do fetchKeyrate = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/streammsg/keyrate?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      $scope.ui.msg_user = res.data.msg_user
      $scope.ui.msg_count = res.data.msg_count
      $scope.ui.avg_msg_cost = res.data.avg_msg_cost
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  fetchTable = ->
    type = if $scope.ui.radioModel is "0" then "day" else "hour"
    _start_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00') else moment($scope.ui._date).format('YYYY-MM-DD 00:00:00')
    _end_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59') else moment($scope.ui._date).format('YYYY-MM-DD 23:59:59')
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/streammsg/msg_statis?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        start_statistical_time: _start_release_date
        end_statistical_time: _end_release_date
        type: type
    .then (resStatis) ->
      statis = _.sortBy resStatis.data, (num) ->
        num._id
      _.each statis, (val) ->
        val.date = moment(val._id).format('YYYY-MM-DD')
      $scope.ui.table = statis
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.date', (newDate) ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(7, 'days')
        endDate: moment()
      growl.error('请输入正确的时间')

    if !$scope.ui.isContrast
      fetchStatis()
      fetchDist()
      fetchTable()
    else
      contrastChart $scope.ui.search_type_name

  $scope.$watch 'ui._date', (newDate) ->
    if $scope.ui._date is null or String(new Date($scope.ui._date)) is 'Invalid Date'
      $scope.ui._date = moment()
      growl.error('请输入正确的时间')

    if !$scope.ui.isContrast
      fetchStatis()
      fetchDist()
      fetchTable()
    else
      contrastChart $scope.ui.search_type_name

  $scope.$watch 'ui.contrast', (newDate) ->
    if $scope.ui.contrast.startDate is null or $scope.ui.contrast.endDate is null or String(new Date($scope.ui.contrast.startDate)) is 'Invalid Date' or String(new Date($scope.ui.contrast.endDate)) is 'Invalid Date'
      $scope.ui.contrast =
        startDate: moment().subtract(14, 'days')
        endDate: moment().subtract(7, 'days')
      growl.error('请输入正确的时间')

    if $scope.ui.isContrast
      contrastChart $scope.ui.search_type_name

  $scope.changeType = (type, name) ->
    if $scope.ui.isContrast
      contrastChart name
    else
      $scope.newsChart.legend.data = [name]
      $scope.newsChart.series[0].name = name
      $scope.newsChart.series[0].data = _.pluck $scope.ui.msg_statis, type

    $scope.ui.search_type = type
    $scope.ui.search_type_name = name

  $scope.contrastChart = contrastChart = (type) ->
    $scope.newsChart.tooltip.formatter = (params, ticket, callback) ->
      res = "#{params[0][0]}(#{params[0][1]}) :  #{params[0][2]}"
      old_date = params[0][0].match(/\d{4}.\d{1,2}.\d{1,2}/)[0]
      date_num = moment(params[0][1]).diff(moment(old_date), 'days')
      new_date = params[1][0].match(/\d{4}.\d{1,2}.\d{1,2}/)[0]
      test = moment(new_date).add(date_num, 'days').format('YYYY-MM-DD')
      res += "<br/>#{params[1][0]}(#{test}) :  #{params[1][2]}"
      setTimeout( ->
        callback ticket, res
      , 500)
      return 'loading'

    start_date = moment($scope.ui.date.startDate).format('YYYY.MM.DD')
    end_date = moment($scope.ui.date.endDate).format('YYYY.MM.DD')
    start_contrast_date = moment($scope.ui.contrast.startDate).format('YYYY.MM.DD')
    end_contrast_date = moment($scope.ui.contrast.endDate).format('YYYY.MM.DD')
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/streammsg/msg_statis?access_token=" + Storage.get('access_token'),
      params:
        start_statistical_time: moment(start_date).format('YYYY-MM-DD 00:00:00')
        end_statistical_time: moment(end_date).format('YYYY-MM-DD 23:59:59')
        type: 'day'
    .then (res) ->
      res.data = _.sortBy res.data, (num) ->
        num.statis_time
      _.each res.data, (val) ->
        val.statis_time = moment(val.statis_time).format('YYYY-MM-DD')
      $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/streammsg/msg_statis?access_token=" + Storage.get('access_token'),
        params:
          start_statistical_time: moment(start_contrast_date).format('YYYY-MM-DD 00:00:00')
          end_statistical_time: moment(end_contrast_date).format('YYYY-MM-DD 23:59:59')
          type: 'day'
      .then (resContrast) ->
        resContrast.data = _.sortBy resContrast.data, (num) ->
          num.statis_time
        _.each resContrast.data, (val) ->
          val.statis_time = moment(val.statis_time).format('YYYY-MM-DD')

        Y_date = if res.data.length > resContrast.data.length then res.data else resContrast.data
        $scope.newsChart.xAxis[0] =
          data: _.pluck Y_date, "statis_time"
        seriesData = switch type
          when "粉絲留言人數" then _.pluck res.data, "msg_user"
          when "粉絲留言次數" then _.pluck res.data, "msg_count"
          when "人均留言次數" then _.pluck res.data, "avg_msg_cost"
        seriesContrastData = switch type
          when "粉絲留言人數" then _.pluck resContrast.data, "msg_user"
          when "粉絲留言次數" then _.pluck resContrast.data, "msg_count"
          when "人均留言次數" then _.pluck resContrast.data, "avg_msg_cost"
        $scope.newsChart.legend =
          data: ["#{start_date}至#{end_date}#{type}", "#{start_contrast_date}至#{end_contrast_date}#{type}"]
        $scope.newsChart.series = [
          name: "#{start_date}至#{end_date}#{type}"
          type: 'line'
          data: seriesData
        ,
          name: "#{start_contrast_date}至#{end_contrast_date}#{type}"
          type: 'line'
          data: seriesContrastData
        ]
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.setDay = ->
    $scope.ui.isContrast = false

    $scope.ui.search_type = "msg_user"
    $scope.ui.search_type_name = "粉絲留言人數"

    fetchStatis()
    fetchDist()
    fetchKeyrate()
    fetchTable()

  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.downloadData = ->
    $scope.progress = 0
    _start_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00') else moment($scope.ui._date).format('YYYY-MM-DD 00:00:00')
    _end_release_date = if $scope.ui.radioModel is '0' then moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59') else moment($scope.ui._date).format('YYYY-MM-DD 23:59:59')
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/streammsg/statis_export?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        type: if $scope.ui.radioModel is "0" then "day" else "hour"
        start_statistical_time: _start_release_date
        end_statistical_time: _end_release_date
      prepareCallback: prepare
      successCallback: success
      failCallback: error
