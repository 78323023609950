angular.module 'app'
.controller 'AddListTemplateCtrl', ($rootScope, $scope, $http, $stateParams,$uibModalInstance, $state, config, Storage, dialogs, growl, SweetAlert) ->

  $scope.ui =
    isLoading: false
    user: Storage.get('login_user')
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    totalItemsOne: 0
    currentPageOne: 1
    perPageOne: 10
    maxSizeOne: 6
    sentLists: []
    selectcount: 30
    sentListsSelectedIds: []
    status: ''
    menuActive: 0
    title: ''

  findDatas = ->
    return if $scope.ui.menuActive == 1
    $http.get "#{config.api.baseUrl}/homepage/getSentList?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
        type: 'sent'
        search: $scope.ui.title
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY年MM月DD日')
      $scope.ui.totalItems = res.data.count
      $scope.ui.sentLists = _.chain($scope.ui.sentLists).filter((d) -> d.isChecked).concat(res.data.data).value()
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  fetchMaterialDatas = ->
    return if $scope.ui.menuActive == 0
    $http.get "#{config.api.baseUrl}/homepage/getSentList?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
        type: 'material'
        search: $scope.ui.title
        limit: $scope.ui.perPageOne
        offset: ($scope.ui.currentPageOne - 1) * $scope.ui.perPageOne
    .then (res) ->
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY年MM月DD日')
      $scope.ui.totalItemsOne = res.data.count
      $scope.ui.sentLists = _.chain($scope.ui.sentLists).filter((d) -> d.isChecked).concat(res.data.data).value()
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.currentPage', findDatas
  $scope.$watch 'ui.currentPageOne', fetchMaterialDatas

  # 切换焦点
  $scope.switchMenu = (_index) ->
    $scope.ui.menuActive = _index
    if _index == 0
      $scope.ui.title = ''
      $scope.ui.totalItems = 0
      $scope.ui.sentLists = []
      $scope.ui.currentPage = 1
      findDatas()
    else
      $scope.ui.title = ''
      $scope.ui.totalItemsOne = 0
      $scope.ui.sentLists = []
      $scope.ui.currentPageOne = 1
      fetchMaterialDatas()

  # 搜索
  $scope.search = ->
    if $scope.ui.menuActive == 0
      $scope.ui.totalItems = 0
      $scope.ui.sentLists = []
      $scope.ui.currentPage = 1
      findDatas()
    else
      $scope.ui.totalItemsOne = 0
      $scope.ui.sentLists = []
      $scope.ui.currentPageOne = 1
      fetchMaterialDatas()

  # 复选
  $scope.selectSingle = (data) ->
    if $scope.ui.selectcount <= 0 and data.isChecked
      data.isChecked = false
      return

    data.isChecked == !data.isChecked

    if data.isChecked
      $scope.ui.selectcount--
    else
      $scope.ui.selectcount++
  # 保存
  $scope.save = ->
    _result = []
    _.each _.chain($scope.ui.sentLists).filter((d) -> d.isChecked).value(), (val) ->
      obj = {}
      obj.sent_id = val._id
      obj.url = val.url
      obj.title = val.title
      obj.thumb_url = val.thumb_url
      obj.digest = val.digest
      _result.push obj
    $uibModalInstance.close {
      list: _result,
      list_id: _.chain($scope.ui.sentLists).filter((d) -> d.isChecked).pluck('_id').value()
    }

  do findStatus = ->
    $http.get "#{config.api.baseUrl}/homepage/checkUpdateStatus?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.status = res.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 即時更新
  $scope.updateList = ->
    $http.post "#{config.api.baseUrl}/homepage/updateSentList?access_token=" + Storage.get('access_token'),
      original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.status = "off"
      if res.status == 200
        growl.success "正在更新..."
      else
        growl.error "網絡連接失敗，請稍後重試"
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
