angular.module 'app'
.controller 'MaterialTemplateDetailCtrl', ($rootScope, $stateParams, $scope, $http, FileUploader, $state, $uibModalInstance, config, data, ueditorConfig, Storage, growl, dialogs, CreateHtml) ->
  $scope.ui =
    user: Storage.get('login_user')
    resourcesUrl: config.resources.baseUrl

    isLoad: false

    templateName: '模板名稱'
    templateData: ['', '', '']
    templateDataHtml: ['', '', '']

    activeIndex: 0

    config:
      wordCount: false
      maximumWords: 20000
      wordCountMsg: '&nbsp;當前您已輸入&nbsp;{#count}&nbsp;個字符。'
      elementPathEnabled: false
      autoHeightEnabled: false
      autoFloatEnabled: false
      autoTransWordToList: true
      insertorderedlist:
        'decimal': ''
        'lower-alpha': ''
        'lower-roman': ''
        'upper-alpha': ''
        'upper-roman': ''
      insertunorderedlist:
        circle: ''
        disc: ''
        square: ''
      lang: "zh-tw"
      initialFrameHeight: angular.element('.section-content-wrap').height() - 50
      toolbars: ueditorConfig.ue.config.toolbars
    content: '內文'

  $scope.$on '$destroy', ->
    editor = UE.getEditor 'uecontainer'
    editor.destroy()

  _.defer ->
    uex('uecontainer', ->
      $http.get "#{config.api.baseUrl}/ueditor/group?access_token=" + Storage.get('access_token'), {}
      .then (res) ->
        setTemplate('uecontainer', res.data.data)
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    )

  $scope.ui.isLoad = true
  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
    $scope.ui.isLoad = false
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')
    $scope.ui.isLoad = false

  $scope.setClick = (index) ->
    $scope.ui.activeIndex = index

  # 打開內文圖片choose
  $scope.chooseImgages = ->
    dlg = dialogs.create('apps/material/image/choose/images', 'ImagesChooseCtrl', {radio: false, type: 'template'}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      ue = UE.getEditor("uecontainer")
      _.defer ->
        _.each materials, (valMaterial) ->
          ue.execCommand('inserthtml', "<img style='width:100%;max-width:100%;' src=" + $scope.ui.resourcesUrl + valMaterial.file.path + "></img>")
    ), ->

  # 圖文編輯上傳圖片
  $scope.uploadType = ->
    $scope.uploader.url = "#{config.api.baseUrl}/material/article/uploadimg/file?access_token=#{Storage.get('access_token')}&wechat_id=#{$scope.ui.wechat._id}&created_by=#{$scope.ui.user.id}"

  $scope.uploader = uploader = new FileUploader(
    removeAfterUpload: true
    autoUpload: true
    url: ""
  )
  # FILTERS
  uploader.filters.push
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 2
        growl.error("不能上傳大於2MB的圖片！", {referenceId: 1})
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onBeforeUploadItem = (item) ->
    dialogs.wait '請稍後', '正在上傳...'

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    ue = UE.getEditor("uecontainer")
    _.defer ->
      ue.execCommand('inserthtml', "<img style='width:100%;max-width:100%;' src=" + $scope.ui.resourcesUrl + response.path + "></img>")

    $rootScope.$broadcast 'dialogs.wait.complete'
    growl.success '上傳成功'

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    $rootScope.$broadcast 'dialogs.wait.complete'
    growl.error '上傳失敗'

  # 处理保存
  saveData = (callback) ->
    dialogs.wait '請稍後', '正在保存...'
    ue = UE.getEditor("uecontainer")

    _content_top = ''
    _content_bottom_qr = ''
    _content_bottom_ro = ''

    _content_top = $scope.ui.templateData[0].replace(/\ onclick="editorShowSelect\(this\)"|editor-operation-active|editor-operation/g, '').replace(/\ class=""/g, '').replace(/\<img/g, '\<image').replace(/\'/g, '') if $scope.ui.templateData[0] != '' && $scope.ui.templateData[0] != undefined
    _content_bottom_qr = $scope.ui.templateData[1].replace(/\ onclick="editorShowSelect\(this\)"|editor-operation-active|editor-operation/g, '').replace(/\ class=""/g, '').replace(/\<img/g, '\<image').replace(/\'/g, '') if $scope.ui.templateData[1] != '' && $scope.ui.templateData[1] != undefined
    _content_bottom_ro = $scope.ui.templateData[2].replace(/\ onclick="editorShowSelect\(this\)"|editor-operation-active|editor-operation/g, '').replace(/\ class=""/g, '').replace(/\<img/g, '\<image').replace(/\'/g, '') if $scope.ui.templateData[2] != '' && $scope.ui.templateData[2] != undefined

    if data.material
      $http.put "#{config.api.baseUrl}/ers_material/article/template/#{data.material._id}?access_token=" + Storage.get('access_token'),
        user_group_id: $scope.ui.user.group_id
        name: $scope.ui.templateName
        created_by: $scope.ui.user.id
        content_top: _content_top
        content_bottom_qr: _content_bottom_qr
        content_bottom_ro: _content_bottom_ro
      .then (res) ->
        callback res
        $rootScope.$broadcast 'dialogs.wait.complete'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        callback 'err'
        $rootScope.$broadcast 'dialogs.wait.complete'
    else
      $http.post "#{config.api.baseUrl}/ers_material/article/template?access_token=" + Storage.get('access_token'),
        user_group_id: $scope.ui.user.group_id
        name: $scope.ui.templateName
        created_by: $scope.ui.user.id
        content_top: _content_top
        content_bottom_qr: _content_bottom_qr
        content_bottom_ro: _content_bottom_ro
        material_group_id: (if data.group_id is "" then "0" else data.group_id)
      .then (res) ->
        callback res
        $rootScope.$broadcast 'dialogs.wait.complete'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        callback 'err'
        $rootScope.$broadcast 'dialogs.wait.complete'

  # 取消
  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  # 保存
  $scope.save = ->
    saveData((data) ->
      return if data == 'err'
      growl.success '保存成功'
      $uibModalInstance.close 'Save' if !data.material
    )

  if data.material
    $scope.ui.templateName = data.material.name
    $scope.ui.templateData[0] = data.material.content_top
    $scope.ui.templateData[1] = data.material.content_bottom_qr
    $scope.ui.templateData[2] = data.material.content_bottom_ro

  $scope.$watch 'ui.templateData[0]', (newData) ->
    return $scope.ui.templateDataHtml[0] = CreateHtml.toHtml '<div style="text-align: center;padding: 15px;">頂部區域</div>' if newData == '' || newData == undefined

    $scope.ui.templateDataHtml[0] = CreateHtml.toHtml $scope.ui.templateData[0].replace(/\ onclick="editorShowSelect\(this\)"|editor-operation-active|editor-operation/g, '').replace(/\ class=""/g, '').replace(/\<img/g, '\<image').replace(/\'/g, '')

  $scope.$watch 'ui.templateData[1]', (newData) ->
    return $scope.ui.templateDataHtml[1] = CreateHtml.toHtml '<div style="text-align: center;padding: 15px;">二維碼區域</div>' if newData == '' || newData == undefined

    $scope.ui.templateDataHtml[1] = CreateHtml.toHtml $scope.ui.templateData[1].replace(/\ onclick="editorShowSelect\(this\)"|editor-operation-active|editor-operation/g, '').replace(/\ class=""/g, '').replace(/\<img/g, '\<image').replace(/\'/g, '')

  $scope.$watch 'ui.templateData[2]', (newData) ->
    return $scope.ui.templateDataHtml[2] = CreateHtml.toHtml '<div style="text-align: center;padding: 15px;">底部區域</div>' if newData == '' || newData == undefined

    $scope.ui.templateDataHtml[2] = CreateHtml.toHtml $scope.ui.templateData[2].replace(/\ onclick="editorShowSelect\(this\)"|editor-operation-active|editor-operation/g, '').replace(/\ class=""/g, '').replace(/\<img/g, '\<image').replace(/\'/g, '')
