angular.module 'app'
.controller 'UserBehaviorCtrl', ($rootScope, $scope, $http, $stateParams, $state, $timeout, config, Storage, dialogs, growl) ->

  return $state.go 'app.wechat' if Storage.get('user_permission').analysis.fans.is_use == false

  $scope.ui =
    activity_type: "all"
    hot_activity_type: "all"
    activity_date:
      startDate: moment().subtract(7, 'days')
      endDate: moment()
    hot_activity_date:
      startDate: moment().subtract(7, 'days')
      endDate: moment()

    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#user-behavior'
  $scope.ui.opts.opens = 'right'

  $scope.userBehaviorBarChart =
    title:
      text: ''
    tooltip:
      trigger: 'axis'
    legend:
      data: ['粉絲行為時間分佈']
      y: 'bottom'
    toolbox:
      show: true
      feature:
        restore:
          show: true
        saveAsImage:
          show: true
    calculable: false
    xAxis: [
        type: 'category'
        splitLine: false
        data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
    ]
    yAxis: [
        type: 'value'
    ]
    series: [
        name: '粉絲行為時間分佈'
        type: 'bar'
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        markPoint:
          data: [
              type: 'max'
              name: '最大值'
            ,
              type: 'min'
              name: '最小值'
          ]
        markLine:
          data: [
              type: 'average'
              name: '平均值'
          ]
    ]

  $scope.userBehaviorHeatMapChart =
    tooltip: position: 'top'
    animation: false
    xAxis:
      type: 'category'
      data: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
      splitArea:
        show: true
    yAxis:
      type: 'category'
      data: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      splitArea:
        show: true
    visualMap:
      min: 0
      max: 100
      calculable: true
      orient: 'horizontal'
      left: 'center'
      bottom: '0%'
      color: ['#1fafb1', '#94d5d6']
    toolbox:
      show: true
      feature:
        restore:
          show: true
        saveAsImage:
          show: true
    series: [
        name: '粉絲行為熱力指數:'
        type: 'heatmap'
        data: [
        ]
        label: normal: show: true
        itemStyle: emphasis:
          shadowBlur: 10
          shadowColor: 'rgba(0, 0, 0, 0.5)'
    ]

  fetchActivities = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/follower_activities?access_token=" + Storage.get('access_token'),
      params:
        start_statistical_time: moment($scope.ui.activity_date.startDate).format('YYYY-MM-DD 00:00:00')
        end_statistical_time: moment($scope.ui.activity_date.endDate).format('YYYY-MM-DD 23:59:59')
        type: $scope.ui.activity_type
    .then (res) ->
      lists = _.sortBy res.data, (num) ->
        "hour"
      $scope.userBehaviorBarChart.series[0].data = _.pluck lists, "count"
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.changeActivityType = ->
    fetchActivities()

  $scope.$watch 'ui.activity_date', (newDate) ->
    if $scope.ui.activity_date.startDate is null or $scope.ui.activity_date.endDate is null or String(new Date($scope.ui.activity_date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.activity_date.endDate)) is 'Invalid Date'
      $scope.ui.activity_date =
        startDate: moment().subtract(7, 'days')
        endDate: moment()
      growl.error('请输入正确的时间')

    fetchActivities()

  fetchHotActivities = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/hot_activities?access_token=" + Storage.get('access_token'),
      params:
        start_statistical_time: moment($scope.ui.hot_activity_date.startDate).format('YYYY-MM-DD 00:00:00')
        end_statistical_time: moment($scope.ui.hot_activity_date.endDate).format('YYYY-MM-DD 23:59:59')
        type: $scope.ui.hot_activity_type
    .then (res) ->
      hot_activities = []
      _.each res.data, (val) ->
        hot_activities.push [val.hour, val.week, val.count]
      $scope.userBehaviorHeatMapChart.series[0].data = hot_activities
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.changeHotType = ->
    fetchHotActivities()

  $scope.$watch 'ui.hot_activity_date', (newDate) ->
    if $scope.ui.hot_activity_date.startDate is null or $scope.ui.hot_activity_date.endDate is null or String(new Date($scope.ui.hot_activity_date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.hot_activity_date.endDate)) is 'Invalid Date'
      $scope.ui.hot_activity_date =
        startDate: moment().subtract(7, 'days')
        endDate: moment()
      growl.error('请输入正确的时间')

    fetchHotActivities()

  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.downloadActivity = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/activity_export?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        type: $scope.ui.activity_type
        start_statistical_time: moment($scope.ui.activity_date.startDate).format('YYYY-MM-DD 00:00:00')
        end_statistical_time: moment($scope.ui.activity_date.endDate).format('YYYY-MM-DD 23:59:59')
      prepareCallback: prepare
      successCallback: success
      failCallback: error

  $scope.downloadHot = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/hot_export?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        type: $scope.ui.hot_activity_type
        start_statistical_time: moment($scope.ui.hot_activity_date.startDate).format('YYYY-MM-DD 00:00:00')
        end_statistical_time: moment($scope.ui.hot_activity_date.endDate).format('YYYY-MM-DD 23:59:59')
      prepareCallback: prepare
      successCallback: success
      failCallback: error
