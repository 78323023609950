angular.module 'app'
.controller 'QrcodeListCtrl', ($scope, $http, $stateParams, $state, config, Storage, SweetAlert, dialogs, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').interaction.qrcode.is_use == false

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    isLoading: false
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    qrcodes: []

  fetchQrcode = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      _.each res.data.data, (val) ->
        val.timeout_date = if val.type is "QR_LIMIT_STR_SCENE" then "永久" else moment(val.timeout_date).format('YYYY-MM-DD HH:mm')
        if val.is_action
          if val.rule.sexs.length > 1
            val.rule.sex_name = "全部"
          else if val.rule.sexs[0] == "1"
            val.rule.sex_name = "男性"
          else if val.rule.sexs[0] == "2"
            val.rule.sex_name = "女性"
          else
            val.rule.sex_name = "未知"

          if val.rule.languages.length > 2
            val.rule.language_name = "全部"
          else if _.contains val.rule.language, "zh_CN"
            val.rule.language_name = "簡體中文"
          else if _.contains val.rule.language, "zh_TW"
            val.rule.language_name = "繁體中文"
          else
            val.rule.language_name = "英文"
        else
          val.rule = {}
          val.rule.sex_name = "-"
          val.rule.language_name = "-"
      $scope.ui.qrcodes = res.data.data
      $scope.ui.totalItems = res.data.count
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  $scope.$watch 'ui.currentPage', fetchQrcode

  $scope.add = ->
    dlg = dialogs.create('apps/qrcode/add/add', 'QrcodeAddCtrl', {}, {size: 'md'})
    dlg.result.then ((option) ->
      fetchQrcode()
    ), ->

  $scope.response = (qrcode) ->
    dlg = dialogs.create('apps/qrcode/response/response', 'QrcodeResponseCtrl', {qrcode: qrcode}, {size: 'md'})
    dlg.result.then ((option) ->
      fetchQrcode()
    ), ->

  $scope.delQrcode = (qrcode) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{qrcode.name}”!"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/#{qrcode._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          growl.success "刪除成功！"
          fetchQrcode()
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.download = (data) ->
    dlg = dialogs.create('apps/qrcode/add/add', 'QrcodeAddCtrl', {data: data}, {size: 'md'})
    dlg.result.then ((option) ->
      fetchQrcode()
    ), ->

  $scope.showPeople = (data) ->
    return if data.reply_count == 0
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/qrcodeNew/follower/list?access_token=" + Storage.get('access_token'),
      params:
        qrcodeId: data._id
    .then (res) ->
      dlg = dialogs.create('apps/qrcode/modal/qrcode-modal', 'QrcodeModalCtrl', {type: 'qrcode', data: res.data}, {size: 'md'})
      dlg.result.then ((option) ->
      ), ->
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
