angular.module 'app'
.controller 'SidebarCtrl', ($scope, $rootScope, $http, $state, $stateParams, $location, Storage, config, growl) ->
  $scope.ui =
    menu_type: "expand"
    user: Storage.get('login_user')
    wechats: Storage.get('Wechats')
    permission: Storage.get('user_permission')
    resourcesUrl: config.api.baseUrl
    eadminDisplay: config.eadmin.display
    aboutShow: false
    wechat: null

  $rootScope.nowUrl = $location.$$url

  _.extend $scope.ui,
    isManage: false
    isBase: false
    isAnalysis: false
    isAnalytics: false
    isInteract: false
    isCampus: false

  $scope.ui.isManage = true if $location.$$url.indexOf('/fans') > -1 || $location.$$url.indexOf('/material') > -1 || $location.$$url.indexOf('/template-manage') > -1 || $location.$$url.indexOf('/mobile') > -1
  $scope.ui.isBase = true if $location.$$url.indexOf('/information') > -1 || $location.$$url.indexOf('/template2') > -1 || $location.$$url.indexOf('/pagetemplate') > -1 || $location.$$url.indexOf('/comment') > -1
  $scope.ui.isInteract = true if $location.$$url.indexOf('/msg-manage') > -1 || $location.$$url.indexOf('/response') > -1 || $location.$$url.indexOf('/wechat-menu') > -1 || $location.$$url.indexOf('/qrcode') > -1 || $location.$$url.indexOf('/card-coupons') > -1
  $scope.ui.isAnalysis = true if $location.$$url.indexOf('/user-analysis') > -1 || $location.$$url.indexOf('/graphic-analysis') > -1 || $location.$$url.indexOf('/menu-analysis') > -1 || $location.$$url.indexOf('/news-analysis') > -1
  $scope.ui.isAnalytics = true if $location.$$url.indexOf('/wechat-monitor') > -1 || $location.$$url.indexOf('/wechat-top') > -1 || $location.$$url.indexOf('/hot-article') > -1
  $scope.ui.isCampus = true if $location.$$url.indexOf('/website-set') > -1 || $location.$$url.indexOf('/column-manage') > -1 || $location.$$url.indexOf('/school-information') > -1

  $scope.change = (sidebar, _bool) ->
    _.extend $scope.ui,
      isManage: false
      isBase: false
      isAnalysis: false
      isAnalytics: false
      isInteract: false
      isCampus: false
    $scope.ui[sidebar] = _bool

  setWechat = ->
    if $scope.ui.wechats.length == 0
      $scope.ui.wechat = null
      return

    _original_id = if Storage.get('defalult_wechat') == null then $scope.ui.wechats[0].original_id else Storage.get('defalult_wechat')

    _isNull = true
    _.each $scope.ui.wechats, (wechat) ->
      wechat.version_name = if wechat.version is '0' then '內地版' else '海外版'
      wechat.type_name = '訂閱號' if wechat.type == '0'
      wechat.type_name = '服務號' if wechat.type == '1'
      wechat.type_name = '企業號' if wechat.type == '2'
      _isNull = false if _original_id == wechat.original_id

    _original_id = $scope.ui.wechats[0].original_id if _isNull

    if $stateParams.original_id != ''
      isShow = true
      _.each $scope.ui.wechats, (wechat) ->
        if wechat.original_id == $stateParams.original_id
          $scope.ui.wechat = wechat
          isShow = false

      if isShow == true
        $state.go('app.wechat', {original_id: _original_id})
    else
      $scope.ui.wechat = angular.copy $scope.ui.wechats[0]
      $state.go('app.wechat', {original_id: _original_id})

  findWechat = ->
    $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        limit: 100
        offset: 0
    .then (res) ->
      $scope.ui.wechats = res.data.data
      Storage.set('Wechats', $scope.ui.wechats)
    .then ->
      if $scope.ui.wechats.length == 0
        $scope.ui.wechat = null
        $state.go 'app.wechat', {original_id: ''} if $location.$$url isnt '//wechat'
        return
      setWechat()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.useWechat = (wechat) ->
    $scope.ui.wechat = wechat
    $state.go('app.wechat', {original_id: '123'})

  $rootScope.$on 'sidebarWechat', (event, option) ->
    findWechat()
    _baseUrl = ''
    if $scope.ui.eadminDisplay == true
      _baseUrl = config.eadmin.newBaseUrl
    else
      _baseUrl = config.eadmin.baseUrl
    $http.get "#{_baseUrl}/account/me",
      params:
        access_token: Storage.get('access_token')
    .then (res) ->
      Storage.set('user_permission', res.data.permission)
      $scope.ui.permission = res.data.permission
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $state.go('home')

  $rootScope.$on "changeWechat", (event, option) ->
    findWechat()

  $scope.changeType = ->
    $scope.ui.menu_type = if $scope.ui.menu_type is "expand" then "collapse" else "expand"

  setWechat()
