angular.module 'app'
.constant 'config',
  api:
    baseUrl: '/api'
  resources:
    baseUrl: 'https://cms.umaxdata.com/api'
  eadmin:
    display: true
    newBaseUrl: "/eadmin-api"
    baseUrl: "/eadmin-api"
    clientId: 65
  esocket:
    baseUrl: '/'
  facebook:
    client_id: '1055080221214974'
  twitter:
    client_id: 'ZRFApg3b0oPeOXFIFMLx6z8eg'
  campus:
    webapp:
      baseUrl: 'https://campus-app.ersinfotech.cn'

  opts:
    maxDate: moment().format('YYYY-MM-DD HH:mm:ss')
    locale:
      applyClass: 'btn-green'
      applyLabel: "應用"
      fromLabel: "從"
      format: "YYYY-MM-DD"
      toLabel: "到"
      cancelLabel: '取消'
      customRangeLabel: '自定義範圍'
      daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
      monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']
    ranges:
        '今天': [moment(), moment()]
        '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
        '过去7天': [moment().subtract(6, 'days'), moment()]
        '最近30天': [moment().subtract(29, 'days'), moment()]
        '这个月': [moment().startOf('month'), moment().endOf('month')]
        '上个月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
