angular.module 'app'
.controller 'WechatMenuCtrl', ($rootScope, $stateParams, $scope, $http, $timeout, $state, config, Storage, growl, dialogs, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').interaction.menu.is_use == false

  $scope.ui =
    jurisdiction: false   # 个性化权限

    user: Storage.get('login_user')
    resourcesUrl: config.resources.baseUrl

    isAddBtn: true  # 是否显示主菜单添加按钮
    isSubAddBtn: true  # 是否显示子菜单添加按钮

    isActive: -1  # 主菜单焦点
    isSubActive: -1  # 子菜单焦点

    individualization: []  #个性化菜单列表

    activeIndividualization:  # 当前个性化菜单信息
      _id: ''
      menu_name: ''  # 个性化菜单名称
      matchrule:  # 个性化菜单条件
        tag_id: ''
        country: ''
        province: ''
        city: ''
        language: ''
        sex: ''
        client_platform_type: ''

    matchruleList: []  # 个性化菜单条件列表
    activeCountry: []
    activeProvince: []
    activeCity: []

    menuArr: []  # 菜单数组
    activeMenuArr: {}  # 焦点菜单信息
    viewRadio: '1'  # 列表页单选按钮 1: 默认菜单页  2: 个性化菜单页
    viewActive: 0  # 主页面显示 0: 菜单列表页 1: 默认菜单页  2: 个性化菜单页
    show: false  # 显示/隐藏个性化菜单主要信息

    isDisableSave: false

    isLoading: true

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  # 获取默认菜单
  getDefaultMenu = ->
    $scope.ui.menuArr = []
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/define-menu?access_token=" + Storage.get('access_token'),
      params:
        type: 'define'
    .then (res) ->
      $scope.ui.isLoading = false

      if res.data.change_Array.length > 0
        _tip = ''

        _.each res.data.change_Array, (data, i) ->
          if i == 0
            _tip += ('\"' + data + '\"')
          else
            _tip += ('，"' + data + '\"')

        SweetAlert.swal {
          title: "提示"
          text: _tip + " 有变化，请检查！"
          showCancelButton: false
          confirmButtonClass: 'btn-danger'
          confirmButtonText: '確定'
        }, (isConfirm) ->

      return if res.data.data.length == 0
      $scope.ui.menuArr = res.data.data[0].button
      $scope.ui.isAddBtn = false if $scope.ui.menuArr.length >= 3
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  if $scope.ui.jurisdiction == true
    $scope.ui.viewActive = 0
  else
    $scope.ui.viewRadio = '0'
    $scope.ui.viewActive = 1
    getDefaultMenu()

  # 获取个性化菜单
  getIndividualization = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/define-menu?access_token=" + Storage.get('access_token'),
      params:
        type: 'personality'
    .then (res) ->
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')

        _.each $scope.ui.matchruleList[0].Val, (data1) ->
          data.matchrule.tag_id_name = data1.key if data.matchrule.tag_id == data1.value

        _.each $scope.ui.matchruleList[2].Val, (data2) ->
          data.matchrule.language_name = data2.key if data.matchrule.language == data2.value

        _.each $scope.ui.matchruleList[3].Val, (data3) ->
          data.matchrule.sex_name = data3.key if data.matchrule.sex == data3.value

        _.each $scope.ui.matchruleList[4].Val, (data4) ->
          data.matchrule.client_platform_type_name = data4.key if data.matchrule.client_platform_type == data4.value

      $scope.ui.individualization = res.data.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 获取单个个性化菜单或新增个性化菜单
  getIndividualizationSingle = (_data) ->
    $scope.ui.activeProvince = []
    $scope.ui.activeCity = []

    if _data == 'add'
      $scope.ui.activeIndividualization._id = ''
      $scope.ui.activeIndividualization.menu_name = '個性化菜單名稱'
      $scope.ui.activeIndividualization.matchrule =
        tag_id: ''
        country: ''
        province: ''
        city: ''
        language: ''
        sex: ''
        client_platform_type: ''
      $scope.ui.menuArr = []
    else
      $scope.ui.activeIndividualization._id = _.clone _data.menuid
      $scope.ui.activeIndividualization.menu_name = _.clone _data.menu_name
      $scope.ui.activeIndividualization.matchrule = _.clone _data.matchrule
      $scope.ui.menuArr = _.clone _data.button
      $scope.ui.isAddBtn = false if $scope.ui.menuArr.length >= 3

      $scope.changeCountry()

      $scope.changeProvince()

  $scope.changeCountry = ->
    _.each $scope.ui.activeCountry, (_country) ->
      $scope.ui.activeProvince = _country.value if $scope.ui.activeIndividualization.matchrule.country == _country.key
      $scope.ui.activeCity = []

  $scope.changeProvince = ->
    _.each $scope.ui.activeProvince, (_provincey) ->
      $scope.ui.activeCity = _provincey.value if $scope.ui.activeIndividualization.matchrule.province == _provincey.key

  # 获取个性化菜单全部条件
  getIndividualizationMatchrule = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/menu-term?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      $scope.ui.matchruleList = res.data
      $scope.ui.activeCountry = $scope.ui.matchruleList[1].Val
      getIndividualization()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 添加主菜单
  $scope.addMenu = ->
    return growl.error('請先選擇菜單') if $scope.ui.viewActive == 0
    $scope.ui.menuArr.push({'type': 'click', 'name': '菜單名稱', 'key': '', 'action': {'reply_type': 'news', 'articles': []}, 'sub_button': []})
    $scope.ui.isAddBtn = false if $scope.ui.menuArr.length >= 3

  # 添加子菜单
  $scope.addSubMenu = ->
    if $scope.ui.menuArr[$scope.ui.isActive].sub_button.length == 0
      delete $scope.ui.menuArr[$scope.ui.isActive].type
      delete $scope.ui.menuArr[$scope.ui.isActive].key
      delete $scope.ui.menuArr[$scope.ui.isActive].action
      delete $scope.ui.menuArr[$scope.ui.isActive].url
      $scope.ui.menuArr[$scope.ui.isActive].sub_button = []
      $scope.ui.menuArr[$scope.ui.isActive].sub_button.push({'type': 'click', 'name': '子菜單名稱', 'key': '', 'action': {'reply_type': 'news', 'articles': []}, 'sub_button': []})
    else
      $scope.ui.menuArr[$scope.ui.isActive].sub_button.push({'type': 'click', 'name': '子菜單名稱', 'key': '', 'action': {'reply_type': 'news', 'articles': []}, 'sub_button': []})
    $scope.ui.isSubAddBtn = false if $scope.ui.menuArr[$scope.ui.isActive].sub_button.length >= 5

  # 切换主菜单焦点
  $scope.switchActive = (_index) ->
    $scope.ui.isSubActive = -1
    $scope.ui.isActive = _index
    $scope.ui.activeMenuArr = $scope.ui.menuArr[$scope.ui.isActive]
    if $scope.ui.menuArr[$scope.ui.isActive].sub_button.length >= 5
      $scope.ui.isSubAddBtn = false
    else
      $scope.ui.isSubAddBtn = true

  # 切换子菜单焦点
  $scope.switchSubActive = (_index) ->
    $scope.ui.isSubActive = _index
    $scope.ui.activeMenuArr = $scope.ui.menuArr[$scope.ui.isActive].sub_button[$scope.ui.isSubActive]

  # 切换菜单内容(菜单内容、跳转网页)
  $scope.switchMenuTypeAction = ->
    if $scope.ui.activeMenuArr.type == 'click'
      delete $scope.ui.activeMenuArr.url
      $scope.ui.activeMenuArr.action = {reply_type: "news", articles: []}
    else if $scope.ui.activeMenuArr.type == 'view'
      delete $scope.ui.activeMenuArr.key
      delete $scope.ui.activeMenuArr.action
      $scope.ui.activeMenuArr.url = ''

  # 切换发送消息内容(图文、图片、文字)
  $scope.switchSendAction = (_action) ->
    $scope.ui.activeMenuArr.action.reply_type = _action
    if _action == 'news'
      delete $scope.ui.activeMenuArr.action.text
      delete $scope.ui.activeMenuArr.action.path
      delete $scope.ui.activeMenuArr.action.material_id
      $scope.ui.activeMenuArr.action.reply_type = 'news'
      $scope.ui.activeMenuArr.action.articles = []
      $scope.ui.activeMenuArr.action.material_id = ''
    else if _action == 'image'
      delete $scope.ui.activeMenuArr.action.articles
      delete $scope.ui.activeMenuArr.action.text
      $scope.ui.activeMenuArr.action.reply_type = 'image'
      $scope.ui.activeMenuArr.action.path = ''
      $scope.ui.activeMenuArr.action.material_id = ''
    else if _action == 'text'
      delete $scope.ui.activeMenuArr.action.articles
      delete $scope.ui.activeMenuArr.action.path
      delete $scope.ui.activeMenuArr.action.material_id
      $scope.ui.activeMenuArr.action.reply_type = 'text'
      $scope.ui.activeMenuArr.action.text = ''

  # 选择图文
  $scope.selectGraphic = ->
    dialogs.create('apps/material/graphic/modal/modal', 'MaterialModalCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.activeMenuArr.action.articles = option[0].articles
      $scope.ui.activeMenuArr.action.material_id = option[0]._id
    ), ->

  # 删除图文
  $scope.delGraphic = ->
    $scope.ui.activeMenuArr.action.articles = []
    $scope.ui.activeMenuArr.action.material_id = ''

  # 选择图片
  $scope.selectImage = ->
    dialogs.create('apps/material/image/choose/choose', 'ImageChooseCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.activeMenuArr.action.path = option[0].file.path
      $scope.ui.activeMenuArr.action.material_id = option[0]._id
    ), ->

  # 删除图片
  $scope.delImage = ->
    $scope.ui.activeMenuArr.action.path = ''
    $scope.ui.activeMenuArr.action.material_id = ''

  # 清空状态以及数据
  clear = ->
    $scope.ui.isActive = -1
    $scope.ui.isSubActive = -1
    $scope.ui.menuArr = []
    $scope.ui.activeMenuArr = {}
    $scope.ui.isAddBtn = true
    $scope.ui.isSubAddBtn = true

  # 進入菜單管理
  $scope.menuManage = (_index) ->
    if $scope.ui.viewRadio == '0'
      $scope.ui.viewRadio = '1'
      $scope.ui.viewActive = 0
      clear()
      getIndividualization()
    else if $scope.ui.viewRadio == '1'
      $scope.ui.viewRadio = '0'
      $scope.ui.viewActive = 1
      clear()
      getDefaultMenu()
    else if $scope.ui.viewRadio == '2'
      $scope.ui.viewRadio = '0'
      $scope.ui.viewActive = 2
      clear()
      getIndividualizationSingle(_index)

  # 删除菜单
  $scope.delete = ->
    if $scope.ui.isActive != -1 && $scope.ui.isSubActive == -1
      $scope.ui.menuArr.splice $scope.ui.isActive, 1
      $scope.ui.isActive = -1
      $scope.ui.isSubActive = -1
      $scope.ui.activeMenuArr = {}
      $scope.ui.isAddBtn = true
    else if $scope.ui.isSubActive != -1
      $scope.ui.menuArr[$scope.ui.isActive].sub_button.splice $scope.ui.isSubActive, 1
      $scope.ui.isSubActive = -1
      $scope.ui.activeMenuArr = $scope.ui.menuArr[$scope.ui.isActive]
      $scope.ui.isSubAddBtn = true
      if $scope.ui.activeMenuArr.sub_button.length == 0
        delete $scope.ui.activeMenuArr.sub_button
        $scope.ui.activeMenuArr.type = 'click'
        $scope.ui.activeMenuArr.key = ''
        $scope.ui.activeMenuArr.action = {'reply_type': 'news', 'articles': []}
        $scope.ui.activeMenuArr.sub_button = []

  $scope.show = (_sta) ->
    $scope.ui.show = _sta

  # 菜單保存并發佈
  $scope.save = ->
    $scope.ui.isDisableSave = true

    if $scope.ui.viewActive == 1
      if $scope.ui.menuArr.length == 0
        $scope.ui.isDisableSave = false
        return growl.error "菜單不可為空"

      _keyStatus = _urlStatus = true
      _.each $scope.ui.menuArr, (data) ->
        data.key = '1' if data.type == 'click'
        if data.sub_button != undefined && data.sub_button.length > 0
          _.each data.sub_button, (subData) ->
            subData.key = '1' if subData.type == 'click'
        # _keyStatus = false if data.type == 'click' && data.key == ''
        _urlStatus = false if data.type == 'view' && data.url == ''

      # if _keyStatus == false
      #   $scope.ui.isDisableSave = false
      #   return growl.error "菜單編號不可為空"

      if _urlStatus == false
        $scope.ui.isDisableSave = false
        return growl.error "鏈接不可為空"

      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/define-menu?access_token=" + Storage.get('access_token'),
        button: $scope.ui.menuArr
      .then (res) ->
        $scope.ui.isDisableSave = false
        growl.success "保存成功"
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.isDisableSave = false

    else if $scope.ui.viewActive == 2
      _status = false

      _.each $scope.ui.activeIndividualization.matchrule, (data) ->
        _status = true if data != ''

      if _status == false
        $scope.ui.isDisableSave = false
        return growl.error "至少選擇一項條件"

      if $scope.ui.menuArr.length == 0
        $scope.ui.isDisableSave = false
        return growl.error "菜單不可為空"

      _keyStatus = _urlStatus = true
      _.each $scope.ui.menuArr, (data) ->
        data.key = '1' if data.type == 'click'
        # _keyStatus = false if data.type == 'click' && data.key == ''
        if data.sub_button != undefined && data.sub_button.length > 0
          _.each data.sub_button, (subData) ->
            subData.key = '1' if subData.type == 'click'
            # _keyStatus = false if subData.type == 'click' && subData.key == ''
        _urlStatus = false if data.type == 'view' && data.url == ''

      # if _keyStatus == false
      #   $scope.ui.isDisableSave = false
      #   return growl.error "菜單編號不可為空"

      if _urlStatus == false
        $scope.ui.isDisableSave = false
        return growl.error "鏈接不可為空"

      if $scope.ui.activeIndividualization._id == ''
        $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/personality-menu?access_token=" + Storage.get('access_token'),
          button: $scope.ui.menuArr
          matchrule: $scope.ui.activeIndividualization.matchrule
          menu_name: $scope.ui.activeIndividualization.menu_name
        .then (res) ->
          $scope.ui.isDisableSave = false
          growl.success "保存成功"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
          $scope.ui.isDisableSave = false
      else
        $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/personality-menu/#{$scope.ui.activeIndividualization._id}?access_token=" + Storage.get('access_token'),
          button: $scope.ui.menuArr
          matchrule: $scope.ui.activeIndividualization.matchrule
          menu_name: $scope.ui.activeIndividualization.menu_name
        .then (res) ->
          $scope.ui.isDisableSave = false
          growl.success "保存成功"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
          $scope.ui.isDisableSave = false

  $scope.deleteManage = (_data) ->
    $http.delete "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/personality-menu/#{_data.menuid}?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      getIndividualization()
      growl.success "刪除成功！"
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 個性菜單
  # getIndividualizationMatchrule()
