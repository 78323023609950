angular.module 'app'
.controller 'ColumnManageCtrl', ($scope, $http, $stateParams, $state, config, Storage, growl, dialogs, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').campus.columnManage.is_use == false

  $scope.ui =
    isLoading: false
    user: Storage.get('login_user')

    currentPage: 1
    perPage: 6
    maxSize: 8
    totalItems: 0

    columns: []
    homePage: {}

  do findHomePage = ->
    $http.get "#{config.api.baseUrl}/cumpus/homepage",
      params:
        group_id: $scope.ui.user.group_id
    .then (res) ->
      if res.data.data.length > 0
        $scope.ui.homePage = res.data.data[0]

  findData = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/cumpus/programa",
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        group_id: $scope.ui.user.group_id
    .then (res) ->
      $scope.ui.columns = res.data.data
      $scope.ui.totalItems = res.data.count
      $scope.ui.isLoading = false
    .catch (error) ->
      $scope.ui.isLoading = false
      growl.error '查詢錯誤'

  $scope.$watch 'ui.currentPage', findData

  # 添加栏目
  $scope.createColumn = ->
    dlg = dialogs.create("apps/campus/column-manage/modal/column-detail/column-detail", "ColumnDetailCtrl", {type: 'add'}, {size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findData()
    ), ->

  # 编辑栏目
  $scope.editColumn = (column) ->
    dlg = dialogs.create("apps/campus/column-manage/modal/column-detail/column-detail", "ColumnDetailCtrl", {type: 'edit', content: column}, {size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findData()
    ), ->

  # 修改链接
  $scope.editLink = (column) ->
    dlg = dialogs.create("apps/campus/column-manage/modal/edit-link/edit-link", "EditLinkCtrl", column, {size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findData()
    ), ->

  # 预览
  $scope.previewQrcode = (column) ->
    dlg = dialogs.create("apps/campus/column-manage/modal/preview-modal/preview-modal", "PreviewModalCtrl", {type: 'column', data: column}, {size: 'lg'})
    dlg.result.then ((option) ->
    ), ->

  $scope.articleManage = (column) ->
    $state.go("app.article-manage", {_id: column._id})

  $scope.linkManage = (column) ->
    # $state.go("project.link_manage", {columnId: column._id})

  $scope.pictures = (column) ->
    # $state.go("project.column_pictures", {columnId: column._id})

  $scope.singleArticle = (column) ->
    # $state.go("project.single_article", {columnId: column._id})

  $scope.del = (column) ->
    # 檢查欄目是否使用中
    isDel = true
    if $scope.ui.homePage.layouts?.length > 0
      _.each $scope.ui.homePage.layouts, (layout) ->
        menus = _.union layout.bottomColumns, layout.centerColumns, layout.headMenus
        ids = _.pluck menus, '_id'
        if _.contains(ids, String(column._id)) is true
          growl.error '這個欄目在版面設定中有使用，不能刪除！'
          isDel = false
          return
    if isDel is true
      SweetAlert.swal {
        title: "你確定嗎?"
        text: "是否確定刪除“#{column.programa_name}”欄目嗎？"
        type: "warning"
        showCancelButton: true
        confirmButtonClass: 'btn-danger'
        cancelButtonText: '取消'
        confirmButtonText: '確定'
      }, (isConfirm) ->
        if isConfirm
          $http.delete "#{config.api.baseUrl}/cumpus/programa/#{column._id}", {}
          .then (res) ->
            findData()
            growl.success '刪除成功'
          .catch (error) ->
            growl.error '刪除失敗'

  $scope.editIsShow = (column) ->
    $http.put "#{config.api.baseUrl}/cumpus/programa/#{column._id}", column
    .then (res) ->
      growl.success '保存成功'
    .catch (error) ->
      column.is_show = !column.is_show
      growl.error '保存失敗'

  $scope.toUp = (index) ->
    if index is 0
      growl.error '已是第一位'
    updata = angular.copy $scope.ui.columns[index]
    updata.sort = updata.sort - 1
    downdata = angular.copy $scope.ui.columns[index - 1]
    downdata.sort = downdata.sort + 1
    datas = [updata, downdata]
    promise = _.map datas, (val) ->
      $http.put "#{config.api.baseUrl}/cumpus/programa/#{val._id}", val
      .then (res) ->
    $q.all promise
    .then (res) ->
      growl.success '排序成功'
      findData()
    .catch (res) ->
      growl.error '排序失敗'


  $scope.toDown = (index) ->
    if (index + 1) is $scope.ui.totalItems
      growl.error '已是最後一位'
    updata = angular.copy $scope.ui.columns[index + 1]
    updata.sort = updata.sort - 1
    downdata = angular.copy $scope.ui.columns[index]
    downdata.sort = downdata.sort + 1
    datas = [updata, downdata]
    promise = _.map datas, (val) ->
      $http.put "#{config.api.baseUrl}/cumpus/programa/#{val._id}", val
      .then (res) ->
    $q.all promise
    .then (res) ->
      growl.success '排序成功'
      findData()
    .catch (res) ->
      growl.error '排序失敗'


