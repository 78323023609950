angular.module 'app'
.controller 'PageTemplateCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').issue.pageTemplate.is_use == false
  $scope.ui =
    isLoading: false
    selectPublishList: []
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    selectpublishurl: ''
    resourcesUrl: config.api.baseUrl

  findDatas = ->
    $http.get "#{config.api.baseUrl}/homepage/getList?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
      $scope.ui.totalItems = res.data.count
      # _.each res.data.data, (valData) ->
      #   _.each valData.list, (val) ->
      #     val.thumb_url = config.api.baseUrl + '/imgserve?_url=' + val.thumb_url if val.thumb_url.indexOf('mmbiz.qpic.cn') >= 0
      $scope.ui.selectPublishList = res.data.data
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 刪除
  $scope.delSelectPublish = (selectpublish) ->
    SweetAlert.swal {
      title: "確定刪除此頁面?"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/homepage/delete/#{selectpublish._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findDatas()

          growl.success "刪除成功！"
        .finally ->
          $scope.ui.isLoading = false
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  # 修改模板
  $scope.editSelectPublish = (_selectpublish) ->
    $state.go("app.listtemplate", {_id: _selectpublish._id})

  # 复制链接成功
  $scope.saveSuccess = ->
    growl.success "複製鏈接成功！"


  $scope.$watch 'ui.currentPage', findDatas


