angular.module 'app'
.controller 'MainCtrl', ($rootScope, $location, $scope, $stateParams, $state, $http, $timeout, Storage, SweetAlert, config, growl, SocketIO) ->
  _user = Storage.get('login_user')
  _wechats = Storage.get('Wechats')

  if _user != null
    SocketIO.removeListener 'Cms-Login-' + _user.id

    SocketIO.on 'Cms-Login-' + _user.id, (data) ->
      if data is 'logout'
        SocketIO.emit 'clientID', 'Cms-Login-' + _user.id, null
        growl.info '您的帳號已在別處登錄...'
        $state.go 'home'

    SocketIO.emit 'clientID', 'Cms-Login-' + _user.id, 'success'

  $rootScope.$on '$stateChangeStart', ->

  $rootScope.$on '$stateChangeSuccess', ->
    # $rootScope.nowUrl = $location.$$url

    # if $stateParams.original_id != '' && _wechats != null
    #   isShow = true

    #   _.each _wechats, (wechat) ->
    #     if wechat.original_id == $stateParams.original_id
    #       isShow = false

    #   if isShow == true
    #     if _wechats.length > 0
    #       $state.go('app.wechat', {original_id: _wechats[0].original_id})
    #     else
    #       $state.go('app.wechat', {original_id: '123'})

    # if $location.$$url isnt '/home' and $location.$$url.indexOf('gh_') is -1 and $location.$$url isnt '//wechat' and $location.$$url.indexOf('/user-management') is -1 and $location.$$url.indexOf('/log') is -1
    #   return $state.go('app.wechat', {original_id: '123'}) if _wechats.length is 0
