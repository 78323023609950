angular.module 'app'
.controller 'ReleaseCtrl', ($scope, $http, $stateParams, $state, config, Storage, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').analysis.graphic.is_use == false

  $scope.ui =
    isLoading: false
    allData: {}
    wechat: ''
    date:
      startDate: moment().subtract(31, 'days')
      endDate: moment()
    opts: config.opts

    thisDate:
      startDate: moment().subtract(31, 'days').format('YYYY-MM-DD')
      endDate: moment().subtract(1, 'days').format('YYYY-MM-DD')

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#release'
  $scope.ui.opts.opens = 'right'

  _.each Storage.get('Wechats'), (data) ->
    $scope.ui.wechat = data if data.original_id == $stateParams.original_id

  getData = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/article/release_analysis?access_token=" + Storage.get('access_token'),
      params:
        start_date: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_date: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
    .then (res) ->
      $scope.ui.allData = res.data
      $scope.ui.allData.start_date = moment($scope.ui.date.startDate).format('YYYY-MM-DD')
      $scope.ui.allData.end_date = moment($scope.ui.date.endDate).format('YYYY-MM-DD')
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $scope.ui.isLoading = false

  # 时间控件
  $scope.$watch 'ui.date', (newValue, oldValue) ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(29, 'days')
        endDate: moment()
      growl.error('请输入正确的时间')

    return if newValue == oldValue
    getData()

  getData()
