angular.module 'app'
.controller 'ResearchDetailCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert,ueditorConfig, FileUploader) ->

  $scope.ui =
    user: Storage.get('login_user')
    resourcesUrl: config.resources.baseUrl
    article:
      id: ''
      title: ''
      thumb_media_id: '' # 图文消息的封面图片素材id
      author: ''
      digest: '' #图文消息的正文
      cover_pic_url: ''# 封面
      show_cover_pic_bool: false# 是否显示封面，0为false，即不显示，1为true，即显示
      content: ''
      content_source_url: '' # 原文地址
      description: ''
    articles: []
    menutype: 'News'
    is_file: false
    isLoading: false
    uploadFile: false
    fileLinkInput: false
    fileLink: 'nothing'
    config:
      wordCount: false
      maximumWords: 20000
      wordCountMsg: '&nbsp;當前您已輸入&nbsp;{#count}&nbsp;個字符。'
      elementPathEnabled: false
      autoHeightEnabled: false
      autoFloatEnabled: false
      autoTransWordToList: true
      imagePopup: true
      insertorderedlist:
        'decimal': ''
        'lower-alpha': ''
        'lower-roman': ''
        'upper-alpha': ''
        'upper-roman': ''
      insertunorderedlist:
        circle: ''
        disc: ''
        square: ''
      lang: "zh-tw"
      initialFrameHeight: angular.element('#graphic-detail').height() - 100
      toolbars: ueditorConfig.ue.config.toolbars
    content: '內文'

    randomLength: 16  # 隨機ID長度
    articleId: []  # 圖文ID集合
    newArticleId: []  # 新增圖文ID集合
    activeIndex: 0  # 當前焦點下標
    intervalIndex: '--'
    date: moment().format('YYYY-MM-DD HH:mm:ss')
    opts:
      autoApply: true
      parentEl: 'div#view-fans-message'
      minDate: moment().format('YYYY-MM-DD HH:mm:ss')
      opens: 'center'
      timePicker: true
      timePicker24Hour: true
      timePickerSeconds: true
      singleDatePicker: true
      locale:
        format: 'YYYY-MM-DD HH:mm:ss'
        applyLabel: '應用'
        cancelLabel: '取消'
        daysOfWeek: ['日', '一', '二', '三', '四', '五','六']
        monthNames: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月']


  ### 生成随机字符 ###
  buildRandom = (length) ->
    charts = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    i = 0
    _id = ''
    while i < length
      _id += charts.charAt(Math.floor(Math.random() * charts.length))
      i++
    return buildRandom($scope.ui.randomLength) if _.contains($scope.ui.articleId, _id) == true
    return _id


  $scope.$on '$destroy', ->
    editor = UE.getEditor 'uecontainer'
    editor.destroy()

  ### 获取编辑器模板 ###
  _.defer ->
    $scope.ui.config.initialFrameHeight = angular.element('#graphic-detail').height()
    uex('uecontainer', ->
      $http.get "#{config.api.baseUrl}/ueditor/group?access_token=" + Storage.get('access_token'), {}
      .then (res) ->
        setTemplate('uecontainer', res.data.data)
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    )

  # 通过_id查找到相关数据
  findData = ->
    $http.get "#{config.api.baseUrl}/ers-website/material/article/articleinfo/#{$stateParams._id}?access_token=" + Storage.get('access_token'),
      params: {}
    .then (res) ->
      $scope.ui.date =  moment(res.data.created_at).format('YYYY-MM-DD HH:mm:ss')
      $scope.ui.article.title = res.data.articles[0].title
      $scope.ui.article.author = res.data.articles[0].author
      $scope.ui.article.digest = res.data.articles[0].digest
      $scope.ui.article.cover_pic_url = res.data.articles[0].cover_pic_url
      $scope.ui.article.description = res.data.articles[0].description


  ### 初始化 ###
  if $stateParams._id
    findData()


  # ### 获取wechat ###
  # $scope.ui.isLoad = true
  # $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
  #   params:
  #     user_group_id: $scope.ui.user.group_id
  #     original_id: $stateParams.original_id
  # .then (res) ->
  #   if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
  #   $scope.ui.isShowTemplateManage = true if $scope.ui.wechat.user_group_id == '1'
  #   $scope.ui.isLoad = false
  # .catch (error) ->
  #   if error.data.error.indexOf('errtext') >= 0
  #     err = JSON.parse error.data.error
  #     growl.error err.errtext
  #   else
  #     growl.error('網絡連接失敗，請稍後重試')
  #   $scope.ui.isLoad = false


  # 設置日曆
  $scope.ui.opts.parentEl = 'div#activity-modal'
  $scope.ui.opts.opens = 'right'
  delete $scope.ui.opts.maxDate

  #傳圖片
  uploaderImage = $scope.uploaderImage = new FileUploader(
    autoUpload: true
    removeAfterUpload: true
    url: "#{config.api.baseUrl}/ers-website/material/fileupload?access_token=#{Storage.get('access_token')}&product_id=research&user_id=#{Storage.get('login_user').id}&user_group_id=#{Storage.get('login_user').group_id}"
  )

  uploaderImage.onBeforeUploadItem = (item) ->

  uploaderImage.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.article.cover_pic_url = response.file.path
    growl.success("上傳成功")


  uploaderImage.onErrorItem = (fileItem, response, status, headers) ->
    growl.error("上傳失敗")

  #保存
  $scope.save = ->
    articles = []
    obj = {}
    obj.author = $scope.ui.article.author
    obj.title = $scope.ui.article.title
    obj.cover_pic_url = $scope.ui.article.cover_pic_url
    obj.digest = $scope.ui.article.digest
    obj.description = $scope.ui.article.description
    articles.push obj
    return growl.error '標題不能為空' if $scope.ui.title == ''
    if $stateParams._id
      $http.put "#{config.api.baseUrl}/ers-website/material/article/articleinfo/#{$stateParams._id}?access_token=" + Storage.get('access_token'),
          articles: articles
          user_group_id: $scope.ui.user.group_id
          material_group_id: $stateParams.group_id
          product_id: 'research'
          type: "news"
          created_at: moment($scope.ui.date).format('YYYY-MM-DD HH:mm:ss')
      .then (res) ->
        growl.success "保存成功"
        $state.go('app.research.research-graphic')
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    else
      $http.post "#{config.api.baseUrl}/ers-website/material/article/articleinfo?access_token=" + Storage.get('access_token'),
          articles: articles
          user_group_id: $scope.ui.user.group_id
          material_group_id: $stateParams.group_id
          product_id: 'research'
          type: "news"
          created_at: moment($scope.ui.date).format('YYYY-MM-DD HH:mm:ss')
      .then (res) ->
        growl.success "保存成功"
      .finally ->
        $scope.ui.isLoading = false
        $state.go('app.research.research-graphic')
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  ### 封面 && 內文 图片本地上傳 ###
  $scope.uploadType = (type) ->
    dlg = dialogs.create('apps/research/upload-file/upload-file', 'ResearchImageUploadFileCtrl', {type: 'graphic', location: type}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancle'
      if type == 1
        ue = UE.getEditor("uecontainer")
        ue.execCommand('inserthtml', "<img style='width:100%;max-width:100%;' src=" + $scope.ui.resourcesUrl + option + "></img>")
    ), ->

  ### 从图片库选择 內文 图片 ###
  $scope.chooseImgages = ->
    dlg = dialogs.create('apps/research/image/choose/images', 'ResearchImagesChooseCtrl', {radio: false, type: 'graphic'}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      ue = UE.getEditor("uecontainer")
      _.defer ->
        _.each materials, (valMaterial) ->
          _alt = valMaterial.name.replace(".png", "").replace(".jpg", "")
          ue.execCommand('inserthtml', "<img style='width:100%;max-width:100%;' alt='" + _alt + "' src=" + $scope.ui.resourcesUrl + valMaterial.file.path + "></img>")
    ), ->

  ### 从视频库选择 視頻 ###
  $scope.chooseVideo = ->
    dlg = dialogs.create('apps/research/video/choose/choose', 'ResearchVideoChooseCtrl', {type: 'graphic', radio: true}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      ue = UE.getEditor("uecontainer")
      _.defer ->
        ue.execCommand('inserthtml', '<iframe class="video_iframe" data-vidtype="2" style="z-index:1;" height="375" width="500" frameborder="0" src="' + $scope.ui.resourcesUrl + materials[0].file.url + '" allowfullscreen=""></iframe>')
    ), ->

  # 引用微信文章
  $scope.quoteWeChat = ->
    dlg = dialogs.create('apps/research/graphic-choose/graphic-choose', 'ResearchGraphicChooseCtrl', {radio: true, type: 'graphic'}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      $scope.ui.article.title = materials[0].articles[0].title
      $scope.ui.article.author = materials[0].articles[0].author
      $scope.ui.article.cover_pic_url = materials[0].articles[0].cover_pic_url
      $scope.ui.article.digest = materials[0].articles[0].content
      $scope.ui.article.description = materials[0].articles[0].digest
    ), ->

  $scope.back = ->
    $state.go 'app.research.research-graphic'
