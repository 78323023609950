angular.module 'app'
.controller 'LargedataImageUploadFileCtrl', ($scope, $stateParams, $uibModalInstance, data, growl, config, FileUploader, Storage, $http) ->
  $scope.ui =
    type: angular.copy data.type
    material_group_id: angular.copy data.material_group_id
    waterMark: false
    _filters: []
    url: ''

  # end cancel
  $scope.cancel = ->
    $uibModalInstance.close 'Cancle'

  uploader = $scope.uploader = new FileUploader(
    removeAfterUpload: true
    queueLimit: if $scope.ui.type == 'graphic' && data.location == 0 then 1 else 4
    url: "#{config.api.baseUrl}/ers-website/material/fileupload?access_token=#{Storage.get('access_token')}&user_group_id=#{Storage.get('login_user').group_id}&wechat_id=#{data.wechat_id}&type=#{$scope.ui.type}&material_group_id=#{$scope.ui.material_group_id}&product_id=largedata"
  )
  # FILTERS
  uploader.filters.push
    fn: (item, options) ->
      if $scope.ui.type is 'image' or $scope.ui.type is 'graphic'
        # if item.size / 1024 / 1024 > 2
        #   growl.error("不能上傳大於2MB的圖片！")
        #   return
        type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
        '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

      else if $scope.ui.type is 'voice'
        if item.size / 1024 / 1024 > 5
          growl.error("不能上傳大於5MB的音頻！")
          return
        type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
        '|mp3|wma|wav|amr|'.indexOf(type) != -1
      else if $scope.ui.type is 'video'
        if item.size / 1024 / 1024 > 20
          growl.error("不能上傳大於20MB的視頻！")
          return
        type = '|' + item.name.slice(item.name.lastIndexOf('.') + 1) + '|'
        '|rm|rmvb|wmv|avi|mpg|mpeg|mp4|'.indexOf(type) != -1

  uploader.onBeforeUploadItem = (item) ->

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    growl.success("上傳成功！")
    if $scope.ui.type == 'graphic'
      $scope.ui.url = response.file.url
      $uibModalInstance.close $scope.ui.url

    # if $scope.ui.type == 'graphic'
    #   if $scope.ui.waterMark
    #     _tip = _tip + "正在打水印，請勿關閉..."
    #   else
    #     _tip = _tip + "正在上傳至微信，請勿關閉..."
    #   growl.success(_tip)

    #   _url = "coverImg"
    #   _url = "uploadimg" if data.location == 1

    #   _params =
    #     original_id: $stateParams.original_id
    #     is_wm: $scope.ui.waterMark

    #   _params.material_id = response.material_id if data.location == 0
    #   _params.url = response.path if data.location == 1

    #   $http.post "#{config.api.baseUrl}/ers-website/material/article/" + _url + "/wm?access_token=" + Storage.get('access_token'), _params
    #   .then (res) ->
    #     $scope.ui._filters.push res.data
    #     if $scope.ui._filters.length == uploader._nextIndex
    #       $uibModalInstance.close $scope.ui._filters

    #     growl.success('打水印成功！') if $scope.ui.waterMark
    #   .catch (error) ->
    #     if error.data.error.indexOf('errtext') >= 0
    #       err = JSON.parse error.data.error
    #       growl.error('微信連接失敗：' + err.errmsg)
    #     else
    #       err = JSON.parse error.data.error
    #       growl.error('網絡連接失敗，請稍後重試')

    # else if $scope.ui.type == 'image'
    #   _tip = _tip + "正在打水印..." if $scope.ui.waterMark
    #   growl.success(_tip)

    #   if $scope.ui.waterMark
    #     $http.post "#{config.api.baseUrl}/ers-website/material/article/wm?access_token=" + Storage.get('access_token'),
    #       material_id: response.material_id
    #       original_id: $stateParams.original_id
    #     .then (res) ->
    #       growl.success('打水印成功！')
    #     .catch (error) ->
    #       if error.data.error.indexOf('errtext') >= 0
    #         err = JSON.parse error.data.error
    #         growl.error err.errtext
    #       else
    #         growl.error('網絡連接失敗，請稍後重試')

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    growl.error("上傳失敗！")





