angular.module 'app'
.controller 'PageSettingCtrl', ($scope, $http, $stateParams, $state, config, Storage, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').campus.websiteSet.is_use == false

  $scope.ui =
    isLoading: false
    isSave: false
    user: Storage.get('login_user')

    homePage: {}
    columns: []
    mobileMenus: [{}, {}, {}, {}, {}, {}]
    headMenus: []
    centerColumns: []
    bottomColumns: []

  do findData = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa",
      params:
        limit: 200
        offset: 0
        group_id: $scope.ui.user.group_id
        sort: 'sort'
    .then (res) ->
      $scope.ui.columns = res.data.data
    .catch (error) ->
      growl.error '查詢列表失敗'

  do findHomePage = ->
    $http.get "#{config.api.baseUrl}/cumpus/homepage",
      params:
        group_id: $scope.ui.user.group_id
    .then (res) ->
      if res.data.data.length > 0
        $scope.ui.homePage = res.data.data[0]

        if $scope.ui.homePage.layouts?.length > 0
          _.each $scope.ui.homePage.layouts, (layout) ->
            _.each layout.mobileMenus, (val) ->
              if val is null or val is 'null'
                val = {}
            _.each layout.headMenus, (val) ->
              if val is null or val is 'null'
                val = {}
            _.each layout.centerColumns, (val) ->
              if val is null or val is 'null'
                val = {}
            _.each layout.bottomColumns, (val) ->
              if val is null or val is 'null'
                val = {}
          $scope.ui.mobileMenus = $scope.ui.homePage.layouts[0].mobileMenus if $scope.ui.homePage.layouts[0].mobileMenus
          $scope.ui.headMenus = $scope.ui.homePage.layouts[0].headMenus
          $scope.ui.centerColumns = $scope.ui.homePage.layouts[0].centerColumns
          $scope.ui.bottomColumns = $scope.ui.homePage.layouts[0].bottomColumns
    .catch (error) ->
      growl.error '查詢內容失敗', {ttl: 2000}

  $scope.mobileMenusDel = (index) ->
    $scope.ui.mobileMenus[Number(index)] = {}

  $scope.headMenuDel = (index) ->
    $scope.ui.headMenus[Number(index)] = {}

  $scope.centerColumnsDel = (index) ->
    $scope.ui.centerColumns[Number(index)] = {}

  $scope.bottomColumnsDel = (index) ->
    $scope.ui.bottomColumns[Number(index)] = {}

  $scope.save = ->
    $scope.ui.isSave = true

    layouts = []
    layouts[0] =
      is_useing: true
      mobileMenus: $scope.ui.mobileMenus
      headMenus: $scope.ui.headMenus
      centerColumns: $scope.ui.centerColumns
      bottomColumns: $scope.ui.bottomColumns

    option =
      layouts: layouts
      group_id: $scope.ui.user.group_id

    if _.isUndefined $scope.ui.homePage._id
      $http.post "#{config.api.baseUrl}/cumpus/homepage", option
      .then (res) ->
        growl.success '保存成功'
        $scope.ui.isSave = false
      .catch (error) ->
        growl.error '保存失敗'
        $scope.ui.isSave = false
    else
      $http.put "#{config.api.baseUrl}/cumpus/homepage/#{$scope.ui.homePage._id}", option
      .then (res) ->
        growl.success '保存成功'
        $scope.ui.isSave = false
      .catch (error) ->
        growl.error '保存失敗'
        $scope.ui.isSave = false
