angular.module 'app'
.controller 'GraphicDetailCtrl', ($rootScope, $stateParams, $scope, $http, $state, $uibModalInstance, config, ueditorConfig, Storage, FileUploader, SweetAlert, growl, dialogs, data, SocketIO, $interval, $timeout) ->
  $scope.ui =
    user: Storage.get('login_user')
    resourcesUrl: config.resources.baseUrl

    isLoad: false
    is_new: true  # 新增 or 修改圖文
    is_delete: false  # 是否有刪減
    is_add: false  # 是否有增加
    is_sort: false  # 是否有修改排序
    isSortDisabled: false  # 排序立即更新按钮禁用状态
    is_tips: false  # 是否显示提示
    tip_text: ''  # 提示内容
    tip_text_status: ''  # 提示内容状态
    isShowTemplateManage: false
    buttonShow: false

    article:
      id: ''
      title: ''
      thumb_media_id: '' # 图文消息的封面图片素材id
      author: ''
      digest: '' #图文消息的摘要
      cover_pic_url: ''# 封面
      show_cover_pic_bool: false# 是否显示封面，0为false，即不显示，1为true，即显示
      content: ''
      content_source_url: '' # 原文地址
    articles: []

    config:
      wordCount: false
      maximumWords: 20000
      wordCountMsg: '&nbsp;當前您已輸入&nbsp;{#count}&nbsp;個字符。'
      elementPathEnabled: false
      autoHeightEnabled: false
      autoFloatEnabled: false
      autoTransWordToList: true
      imagePopup: true
      insertorderedlist:
        'decimal': ''
        'lower-alpha': ''
        'lower-roman': ''
        'upper-alpha': ''
        'upper-roman': ''
      insertunorderedlist:
        circle: ''
        disc: ''
        square: ''
      lang: "zh-tw"
      initialFrameHeight: angular.element('#graphic-detail').height() - 100
      toolbars: ueditorConfig.ue.config.toolbars
    content: '內文'

    randomLength: 16  # 隨機ID長度
    articleId: []  # 圖文ID集合
    newArticleId: []  # 新增圖文ID集合
    activeIndex: 0  # 當前焦點下標
    intervalIndex: '--'

  if Storage.get('user_permission').test == true
    $scope.ui.buttonShow = true
  else
    $scope.ui.buttonShow = false


  $scope.$on '$destroy', ->
    editor = UE.getEditor 'uecontainer'
    editor.destroy()

  ### 获取编辑器模板 ###
  _.defer ->
    $scope.ui.config.initialFrameHeight = angular.element('#graphic-detail').height()
    uex('uecontainer', ->
      $http.get "#{config.api.baseUrl}/ueditor/group?access_token=" + Storage.get('access_token'), {}
      .then (res) ->
        setTemplate('uecontainer', res.data.data)
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    )

  ### 生成随机字符 ###
  buildRandom = (length) ->
    charts = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    i = 0
    _id = ''
    while i < length
      _id += charts.charAt(Math.floor(Math.random() * charts.length))
      i++
    return buildRandom($scope.ui.randomLength) if _.contains($scope.ui.articleId, _id) == true
    return _id

  ### 获取wechat ###
  $scope.ui.isLoad = true
  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
    $scope.ui.isShowTemplateManage = true if $scope.ui.wechat.user_group_id == '1'
    $scope.ui.isLoad = false
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')
    $scope.ui.isLoad = false

  ### 图片处理 ###
  setContentImg = (text) ->
    return text.replace(/\"http\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\(http\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn')

  ### 图文处理 ###
  setContent = ->
    _.each $scope.ui.articles, (article, i) ->
      article.content = setContentImg article.content
      article.show_cover_pic_bool = false if article.show_cover_pic == 0
      article.show_cover_pic_bool = true if article.show_cover_pic == 1
      $scope.ui.articleId.push article.id

  ### 添加socket监听 ###
  addSocketLister = ->
    SocketIO.on 'WeChat-Graphic-' + data.material._id, (data) ->
      deleteGraphic(data) if data.type == 'delete-graphic'
      getLatestContet(data, 'all') if data.type == 'save-all'
      if data.id && data.id != $scope.ui.user.id
        getLatestContet(data, 'single') if data.type == 'save-single'
        getLatestSort(data) if data.type == 'save-sort'

  ### 初始化 ###
  if data.material
    addSocketLister()
    $scope.ui.is_new = false
    $scope.ui.articles = angular.copy data.material.articles
    setContent()
    $scope.ui.activeArticle = $scope.ui.articles[0]
  else
    $scope.ui.is_new = true
    _initialArticle = angular.copy $scope.ui.article
    _initialArticle.id = buildRandom($scope.ui.randomLength)
    $scope.ui.articles.push _initialArticle
    $scope.ui.activeArticle = $scope.ui.articles[0]

  ### 新增 ###
  $scope.addArticle = ->
    return growl.error('圖文數量有刪減，請先保存全部再繼續操作！') if $scope.ui.is_new == false && $scope.ui.is_delete == true
    return growl.error('圖文順序有變更，請待自動更新或立即更新后再繼續操作！') if $scope.ui.is_new == false && $scope.ui.is_sort == true
    # $scope.ui.article.index = $scope.ui.articles.length + 1
    _initialArticle = angular.copy $scope.ui.article
    _initialArticle.id = buildRandom($scope.ui.randomLength)
    $scope.ui.articles.push _initialArticle
    $scope.ui.newArticleId.push _initialArticle.id
    $scope.ui.is_add = true

    $scope.ui.activeArticle = $scope.ui.articles[$scope.ui.articles.length - 1]
    $scope.ui.activeIndex = $scope.ui.articles.length - 1

  ### 切换焦点 ###
  $scope.setActive = (article, i) ->
    $scope.ui.activeArticle = article
    $scope.ui.activeIndex = i

  ### 删除 ###
  $scope.delArtice = (index) ->
    return growl.error('圖文數量有增加，請先將新增圖文保存再繼續操作！') if $scope.ui.is_new == false && $scope.ui.is_add == true && _.contains($scope.ui.articleId, $scope.ui.articles[index].id) == true
    return growl.error('圖文順序有變更，請待自動更新或立即更新后再繼續操作！') if $scope.ui.is_new == false && $scope.ui.is_sort == true

    if index == $scope.ui.activeIndex || $scope.ui.activeIndex >= $scope.ui.articles.length
      $scope.ui.activeArticle = $scope.ui.articles[0]
      $scope.ui.activeIndex = 0

    if _.contains($scope.ui.articleId, $scope.ui.articles[index].id) == true
      $scope.ui.is_delete = true
    else
      $scope.ui.newArticleId = _.reject($scope.ui.newArticleId, (obj) ->
        return obj == $scope.ui.articles[index].id
      )
      $scope.ui.is_add = false if $scope.ui.newArticleId.length == 0
    $scope.ui.articles.splice(index, 1)

  ### 交换数组元素 ###
  swapArrayElements = (indexA, indexB) ->
    return growl.error('圖文數量有刪減，請先保存全部再繼續操作！') if $scope.ui.is_new == false && $scope.ui.is_delete == true
    return growl.error('圖文數量有增加，請先將新增的圖文保存再繼續操作！') if $scope.ui.is_new == false && $scope.ui.is_add == true

    temp = angular.copy $scope.ui.articles[indexA]
    $scope.ui.articles[indexA] = angular.copy $scope.ui.articles[indexB]
    $scope.ui.articles[indexB] = temp

    sortData() if $scope.ui.is_new == false

  ### 上移 ###
  $scope.upRecord = (index) ->
    swapArrayElements(index, index - 1)

  ### 下移 ###
  $scope.downRecord = (index) ->
    swapArrayElements(index, index + 1)

  ### 排序 ###
  sortData = ->
    $scope.ui.is_sort = true if $scope.ui.is_sort == false

    $interval.cancel($scope.timer) if $scope.timer

    $scope.ui.intervalIndex = 10

    $scope.timer = $interval (->), 1000, 10
    $scope.timer.then ((success) ->
      # 执行完毕
      $scope.saveSort()
    ), ((error) ->
      # 重新计时
    ), ((notify) ->
      $scope.ui.intervalIndex = ($scope.ui.intervalIndex - 1).toFixed(0)
    )

  ### 从图片库选择 封面 图片 ###
  $scope.chooseImgage = ->
    dlg = dialogs.create('apps/material/image/choose/choose', 'ImageChooseCtrl', {radio: true}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      $scope.ui.articles[$scope.ui.activeIndex].thumb_media_id = materials[0]._id
      $scope.ui.articles[$scope.ui.activeIndex].cover_pic_url = materials[0].file.url
    ), ->

  ### 从图片库选择 內文 图片 ###
  $scope.chooseImgages = ->
    dlg = dialogs.create('apps/material/image/choose/images', 'ImagesChooseCtrl', {radio: false, type: 'graphic'}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      ue = UE.getEditor("uecontainer")
      _.defer ->
        _.each materials, (valMaterial) ->
          ue.execCommand('inserthtml', "<img style='width:100%;max-width:100%;' src=" + $scope.ui.resourcesUrl + valMaterial.file.path + "></img>")
    ), ->

  ### 處理橫向滾動圖片###
  div = (divcss) -> "<div style='margin: 1em auto;padding: 5px;max-width: 100%;box-sizing: border-box;text-align: center;border-width: 1px;border-style: solid;border-color: rgb(221, 221, 221);overflow: hidden;word-wrap: break-word !important;'> " + divcss + "</div>"

  p = (imgs) -> "<div style='max-width: 100% !important;max-height: 100% !important;white-space: nowrap;overflow-x: scroll;box-sizing: border-box !important;word-wrap: break-word !important;'>" + imgs + "</div>"

  img = (path) -> "<img style='border-width: 1px;border-style: solid;border-color: rgb(238, 237, 235);display: inline-block;background-color: rgb(238, 237, 235);background-size: 22px;background-position: 50% 50%;background-repeat: no-repeat;box-sizing: border-box !important;word-wrap: break-word !important;width: 656px !important;height: auto !important;visibility: visible !important;' src=" + $scope.ui.resourcesUrl + path + "></img>"

  fontdiv = (fontdiv) -> "<div style='margin: 5px;max-width: 100%;min-height: 1em;line-height: 32px;box-sizing: border-box !important;word-wrap: break-word !important;'> " + fontdiv + "</div>"

  fontspan = "<div style='max-width: 100%;font-size: 12px;box-sizing: border-box !important;word-wrap: break-word !important;'> " + '←左右滑動查看更多精美圖片→' + "</div>"

  ### 从图片库选择 內文 图片（橫向滾動） ###
  $scope.chooseImgagesHorizontal = ->
    dlg = dialogs.create('apps/material/image/choose/images', 'ImagesChooseCtrl', {radio: false, type: 'graphic'}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      ue = UE.getEditor("uecontainer")
      _.defer ->
        imgs = _.map materials, (valMaterial) -> img(valMaterial.file.path)
        html = div fontdiv p(imgs) + fontspan
        ue.execCommand('inserthtml', html)
    ), ->

  ### 封面 && 內文 图片本地上傳 ###
  $scope.uploadType = (type) ->
    _url = "#{config.api.baseUrl}/material/fileupload?access_token=#{Storage.get('access_token')}&user_group_id=#{Storage.get('login_user').group_id}&wechat_id=#{$scope.ui.wechat._id}&type=image&material_group_id=0"

    _url = "#{config.api.baseUrl}/material/article/uploadimg/file?access_token=#{Storage.get('access_token')}&wechat_id=#{$scope.ui.wechat._id}&created_by=#{$scope.ui.user.id}" if type == 1

    dlg = dialogs.create('apps/material/upload-file/upload-file', 'UploadFileCtrl', {type: 'graphic', url: _url, location: type}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancle'
      if type == 0
        $scope.ui.articles[$scope.ui.activeIndex].thumb_media_id = option[0].material_id
        $scope.ui.articles[$scope.ui.activeIndex].cover_pic_url = option[0].path
      else if type == 1
        ue = UE.getEditor("uecontainer")
        _.defer ->
          _.each option, (data) ->
            ue.execCommand('inserthtml', "<img style='width:100%;max-width:100%;' src=" + $scope.ui.resourcesUrl + data + "></img>")
    ), ->

  ### 内文图片上传处理(至微信) ###
  uploadImgData = (data, callback) ->
    dialogs.wait '請稍後', '正在上傳...'
    $http.post "#{config.api.baseUrl}/material/article/uploadimg/url?access_token=" + Storage.get('access_token'),
      img_urls: data
      original_id: $stateParams.original_id
    .then (res) ->
      $rootScope.$broadcast 'dialogs.wait.complete'
      callback res.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $rootScope.$broadcast 'dialogs.wait.complete'
      callback 'err'

  ### 内文图片上传 ###
  $scope.uploadImg = ->
    $rows = $("#{$scope.ui.activeArticle.content}")
    img_list = []

    _.each $rows.find('img').toArray(), (data, i) ->
      $el = $(data)
      img_url = $el.attr('src')
      img_list.push img_url if img_url && img_url.indexOf('mmbiz.qpic.cn') < 0

    if img_list.length > 0
      uploadImgData(img_list, (data) ->
        return if data == 'err'

        _.each data, (val) ->
          $scope.ui.activeArticle.content = $scope.ui.activeArticle.content.replace(val.url, config.api.baseUrl + '/imgserve?_url=' + val.wechatUrl)

        growl.success '上傳成功'
      )

  ### 使用图文模板 ###
  $scope.chooseTemplate = ->
    dlg = dialogs.create('apps/material/template/modal/modal', 'MaterialTemplateModalCtrl', {}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      ue = UE.getEditor("uecontainer")
      _.defer ->
        _content = ue.newGetContent()
        ue.setContent(option.content_top + _content + option.content_bottom_qr + option.content_bottom_ro)
    ), ->

  ### 从视频库选择 視頻 ###
  $scope.chooseVideo = ->
    dlg = dialogs.create('apps/material/video/choose/choose', 'VideoChooseCtrl', {type: 'graphic', radio: true}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      contentStr = angular.element($scope.ui.activeArticle.content).find('iframe.video_iframe')
      return growl.error '最多添加3個素材庫視頻，微信小視頻（帳號需認證）或騰訊視頻' if contentStr.length > 2
      ue = UE.getEditor("uecontainer")
      _.defer ->
        ue.execCommand('inserthtml', '<iframe class="video_iframe" data-vidtype="2" style="z-index:1;" height="375" width="500" frameborder="0" src="' + materials[0].file.wechat_url + '" allowfullscreen=""></iframe>')
    ), ->

  ### 编辑器模板管理 ###
  $scope.templateManage = ->
    return if $scope.ui.wechat.user_group_id != '1'
    _content = _.clone $scope.ui.activeArticle.content
    _content = _content.replace(/\ onclick="editorShowSelect\(this\)"|editor-operation-active|editor-operation/g, '').replace(/\ class=""/g, '').replace(/\<img/g, '\<image').replace(/\'/g, '')
    dlg = dialogs.create('apps/material/graphic/template-manage/template-manage', 'MaterialTemplateManageCtrl', {content: _content}, {size: 'md'})
    dlg.result.then ((materials) ->
      $http.get "#{config.api.baseUrl}/ueditor/group?access_token=" + Storage.get('access_token'), {}
      .then (res) ->
        setTemplate('uecontainer', res.data.data)
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    ), ->

  ### 处理保存 ###
  saveData = (bool, callback) ->
    ## 验证
    titleState = true
    contentState = true
    coverState = true

    _.each $scope.ui.articles, (article, i) ->
      titleState = false if article.title == ''
      contentState = false if article.content == ''
      coverState = false if article.thumb_media_id == ''

    return growl.error '標題不可為空' if titleState == false
    return growl.error '正文不可為空' if contentState == false
    return growl.error '封面不可為空' if coverState == false

    ## 处理数据
    ue = UE.getEditor("uecontainer")
    _articleArr = angular.copy $scope.ui.articles
    _.each _articleArr, (article, i) ->
      # 去除平台图片跨域接口
      while article.content.indexOf(config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn') > -1
        article.content = article.content.replace(config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn', "http://mmbiz.qpic.cn")

      # 澳大要求，处理图片边框
      if $stateParams.original_id == 'gh_a391794191aa' or $stateParams.original_id == 'gh_b1fd47ef00a3' or $stateParams.original_id == 'gh_fce9b5689850' or $stateParams.original_id == 'gh_7d575ee8da8d' or $stateParams.original_id == 'gh_8e880d9377e6' or $stateParams.original_id == 'gh_1fdd75103989'  or $stateParams.original_id == 'gh_158d66e084c8'
        $rows2 = $("<div>#{article.content}</div>")
        _.each $rows2.find('img').toArray(), (data, i) ->
          _style = $(data).attr('style')
          _style += "border:0px;" if _style and _style.indexOf('border:0px;') < 0
          $(data).attr('style', _style)
        article.content = $rows2.html()

      # 处理微信data-src、data-backsrc属性及src路径
      $rows = $("#{article.content}")
      _.each $rows.find('img').toArray(), (data, i) ->
        _oldHtml = _.unescape $('<p>').append($(data).clone()).html().replace('>', '/>')
        _src = $(data).data('src')
        _backsrc = $(data).data('backsrc')

        $(data).attr('data-src', 'ers') if _src != undefined
        $(data).attr('data-backsrc', 'ers') if _backsrc != undefined

        if _src == undefined && _backsrc != undefined
          $(data).attr('src', _backsrc)
        else if _src != undefined && _backsrc == undefined
          $(data).attr('src', _src)
        else if _src != undefined && _backsrc != undefined
          $(data).attr('src', _src) if _src.length <= _backsrc.length && _src.length < $(data).attr('src').length
          $(data).attr('src', _backsrc) if _backsrc.length < _src.length && _backsrc.length < $(data).attr('src').length

        _newHtml = _.unescape $('<p>').append($(data).clone()).html().replace('>', '/>').replace(' data-src="ers"', '').replace('data-backsrc="ers"', '')
        article.content = article.content.replace(_oldHtml, _newHtml) if _src != undefined || _backsrc != undefined

      # 处理基本信息和内文
      article.digest = ue.getContentTxt().substr(0, 54) if _articleArr.length <= 1 && article.digest == ''
      article.content = article.content.replace(/\ onclick="editorShowSelect\(this\)"|editor-operation-active|editor-operation/g, '').replace(/\ class=""/g, '').replace(/\<img/g, '\<image').replace(/\'/g, '')
        # .replace(/\"http\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn')
        # .replace(/\(http\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn')
      article.show_cover_pic = if article.show_cover_pic_bool is false then 0 else 1

      # 处理每篇图文的ID
      article.id = buildRandom($scope.ui.randomLength)

    ## 保存
    dialogs.wait '請稍後', '正在保存...'

    _params =
      wechat_id: $scope.ui.wechat._id
      articles: _articleArr
      original_id: $stateParams.original_id
      material_group_id: '0'

    if $scope.ui.is_new == false
      _params.material_id = data.material._id
      _params.material_group_id = data.material.material_group_id
    else
      _params.material_group_id = data.group_id if data.group_id != ""
      _params.created_by = $scope.ui.user.id

    _url = '/save'
    _url = '/save/preview' if bool

    $http.post "#{config.api.baseUrl}/material/article#{_url}?access_token=" + Storage.get('access_token'), _params
    .then (res) ->
      if $scope.ui.is_new == true
        data.material = res.data
        SocketIO.emit 'clientID', 'WeChat-Graphic-' + data.material._id, 'success'
        addSocketLister()

      callback res.data
      $rootScope.$broadcast 'dialogs.wait.complete'
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $rootScope.$broadcast 'dialogs.wait.complete'
      # callback 'err'

  ### 取消 ###
  $scope.cancel = ->
    if $scope.ui.is_new == false
      SocketIO.emit 'clientID', 'WeChat-Graphic-' + data.material._id, null
      SocketIO.removeListener 'WeChat-Graphic-' + data.material._id
      $interval.cancel($scope.timer) if $scope.timer
    $uibModalInstance.close 'Cancel'

  # 保存全部成功后触发
  triggerSaveAll = ->
    $scope.ui.is_new = false
    $scope.ui.is_delete = false
    $scope.ui.is_add = false
    $scope.ui.is_sort = false
    SocketIO.emit 'clientID', 'WeChat-Graphic-' + data.material._id, {id: $scope.ui.user.id, name: $scope.ui.user.username, type: 'save-all'}

    $interval.cancel($scope.timer) if $scope.timer

  # 保存全部前触发
  detectionSaveAll = (callback) ->
    if $scope.ui.is_new == false && $scope.ui.is_delete == true
      SweetAlert.swal {
        title: "你確定嗎?"
        text: "圖文數量有變動，確認保存修改嗎？"
        type: "warning"
        showCancelButton: true
        confirmButtonClass: 'btn-danger'
        cancelButtonText: '取消'
        confirmButtonText: '確定'
      }, (isConfirm) ->
        if isConfirm
          callback()
        else
          return
    else
      callback()

  ### 保存 ###
  $scope.save = ->
    detectionSaveAll( ->
      if data.material
        $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/check/check_material?access_token=" + Storage.get('access_token'),
          params:
            material_id: data.material._id
        .then (res) ->
          if res.data.appmsg + res.data.qrcode + res.data.menu > 0
            msg = ""
            msg += "#{res.data.appmsg}項自動應答設置" if res.data.appmsg > 0
            if res.data.qrcode > 0
              msg += if msg.length > 0 then "；#{res.data.qrcode}項二維碼設置" else "#{res.data.qrcode}項二維碼設置"
            if res.data.menu > 0
              msg += if msg.length > 0 then "；#{res.data.menu}項菜單設置" else "#{res.data.menu}項菜單設置"
            SweetAlert.swal {
              title: "你確定嗎?"
              text: "本圖文信息已有#{msg}使用中，修改圖文會同步更新"
              type: "warning"
              showCancelButton: true
              confirmButtonClass: 'btn-danger'
              cancelButtonText: '取消'
              confirmButtonText: '確定'
            }, (isConfirm) ->
              if isConfirm
                saveData(false, (_data) ->
                  dialogs.wait '請稍後', '正在處理数据...'
                  $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/sync/sync_material?access_token=" + Storage.get('access_token'),
                    material_id: data.material._id
                  .then (res) ->
                    $rootScope.$broadcast 'dialogs.wait.complete'
                    growl.success '保存成功' if _data != 'err'
                    triggerSaveAll()
                  .catch (error) ->
                    $rootScope.$broadcast 'dialogs.wait.complete'
                    if error.data.error.indexOf('errtext') >= 0
                      err = JSON.parse error.data.error
                      growl.error err.errtext
                    else
                      growl.error('網絡連接失敗，請稍後重試')
                )
          else
            saveData(false, (_data) ->
              growl.success '保存成功' if _data != 'err'
              triggerSaveAll()
            )
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
      else
        saveData(false, (_data) ->
          growl.success '保存成功' if _data != 'err'
          triggerSaveAll()
        )
    )

  ### 预览并保存 ###
  $scope.preview = ->
    if data.material
      $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/check/check_material?access_token=" + Storage.get('access_token'),
        params:
          material_id: data.material._id
      .then (res) ->
        if res.data.appmsg + res.data.qrcode + res.data.menu > 0
          msg = ""
          msg += "#{res.data.appmsg}項自動應答設置" if res.data.appmsg > 0
          if res.data.qrcode > 0
            msg += if msg.length > 0 then "；#{res.data.qrcode}項二維碼設置" else "#{res.data.qrcode}項二維碼設置"
          if res.data.menu > 0
            msg += if msg.length > 0 then "；#{res.data.menu}項菜單設置" else "#{res.data.menu}項菜單設置"
          SweetAlert.swal {
            title: "你確定嗎?"
            text: "本圖文信息已有#{msg}使用中，修改圖文會同步更新"
            type: "warning"
            showCancelButton: true
            confirmButtonClass: 'btn-danger'
            cancelButtonText: '取消'
            confirmButtonText: '確定'
          }, (isConfirm) ->
            if isConfirm
              saveData(true, (_data) ->
                return if _data == 'err'
                triggerSaveAll()

                dlg = dialogs.create('apps/material/graphic/select/select', 'SelectPeopleCtrl', {text: '預覽不能多於 100 人。'}, {size: 'md'})
                dlg.result.then ((option) ->
                  return if option == 'Cancel'
                  dialogs.wait '請稍後', '正在發送預覽...'
                  $http.post "#{config.api.baseUrl}/material/article/preview?access_token=" + Storage.get('access_token'),
                    original_id: $stateParams.original_id
                    openids: option
                    material_id: data.material._id
                  .then (res) ->
                    growl.success '發送成功'
                    $rootScope.$broadcast 'dialogs.wait.complete'
                  .catch (error) ->
                    if error.data.error.indexOf('errtext') >= 0
                      err = JSON.parse error.data.error
                      growl.error err.errtext
                    else
                      growl.error('網絡連接失敗，請稍後重試')
                    $rootScope.$broadcast 'dialogs.wait.complete'
                ), ->
              )
        else
          saveData(true, (_data) ->
            return if _data == 'err'
            triggerSaveAll()

            dlg = dialogs.create('apps/material/graphic/select/select', 'SelectPeopleCtrl', {text: '預覽不能多於 100 人。'}, {size: 'md'})
            dlg.result.then ((option) ->
              return if option == 'Cancel'

              dialogs.wait '請稍後', '正在發送預覽...'
              $http.post "#{config.api.baseUrl}/material/article/preview?access_token=" + Storage.get('access_token'),
                original_id: $stateParams.original_id
                openids: option
                material_id: data.material._id
              .then (res) ->
                growl.success '發送成功'
                $rootScope.$broadcast 'dialogs.wait.complete'
              .catch (error) ->
                if error.data.error.indexOf('errtext') >= 0
                  err = JSON.parse error.data.error
                  growl.error err.errtext
                else
                  growl.error('網絡連接失敗，請稍後重試')
                $rootScope.$broadcast 'dialogs.wait.complete'
            ), ->
          )
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    else
      saveData(true, (_data) ->
        return if _data == 'err'
        triggerSaveAll()

        dlg = dialogs.create('apps/material/graphic/select/select', 'SelectPeopleCtrl', {text: '預覽不能多於 100 人。'}, {size: 'md'})
        dlg.result.then ((option) ->
          return if option == 'Cancel'

          dialogs.wait '請稍後', '正在發送預覽...'
          $http.post "#{config.api.baseUrl}/material/article/preview?access_token=" + Storage.get('access_token'),
            original_id: $stateParams.original_id
            openids: option
            material_id: data.material._id
          .then (res) ->
            growl.success '發送成功'
            $rootScope.$broadcast 'dialogs.wait.complete'
          .catch (error) ->
            if error.data.error.indexOf('errtext') >= 0
              err = JSON.parse error.data.error
              growl.error err.errtext
            else
              growl.error('網絡連接失敗，請稍後重試')
            $rootScope.$broadcast 'dialogs.wait.complete'
        ), ->
      )

  ### 刷新图文 ###
  $scope.refresh = ->
    return growl.error('請先保存當前圖文！') if !data.material
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "刷新預覽會導致本圖文之前的預覽鏈接失效，是否確定刷新？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        dialogs.wait '請稍後', '正在刷新預覽...'
        $http.post "#{config.api.baseUrl}/material/article/refresh?access_token=" + Storage.get('access_token'),
          original_id: $stateParams.original_id
          material_id: data.material._id
        .then (res) ->
          growl.success '刷新成功'
          $rootScope.$broadcast 'dialogs.wait.complete'
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
          $rootScope.$broadcast 'dialogs.wait.complete'

  ### 保存单篇 ###
  $scope.saveSingle = (index) ->
    return if $scope.ui.is_new == true
    return growl.error('圖文數量有刪減，請先保存全部再繼續操作！') if $scope.ui.is_delete == true
    return growl.error('圖文順序有變更，請待自動更新或立即更新后再繼續操作！') if $scope.ui.is_new == false && $scope.ui.is_sort == true

    ue = UE.getEditor("uecontainer")
    titleState = true
    contentState = true
    coverState = true

    _article = angular.copy $scope.ui.articles[index]

    # 去除平台图片跨域接口
    while _article.content.indexOf(config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn') > -1
      _article.content = _article.content.replace(config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn', "http://mmbiz.qpic.cn")

    # 处理微信data-src、data-backsrc属性及src路径
    $rows = $("#{_article.content}")
    _.each $rows.find('img').toArray(), (data, i) ->
      _oldHtml = _.unescape $('<p>').append($(data).clone()).html().replace('>', '/>')
      _src = $(data).data('src')
      _backsrc = $(data).data('backsrc')

      $(data).attr('data-src', 'ers') if _src != undefined
      $(data).attr('data-backsrc', 'ers') if _backsrc != undefined

      if _src == undefined && _backsrc != undefined
        $(data).attr('src', _backsrc)
      else if _src != undefined && _backsrc == undefined
        $(data).attr('src', _src)
      else if _src != undefined && _backsrc != undefined
        $(data).attr('src', _src) if _src.length <= _backsrc.length && _src.length < $(data).attr('src').length
        $(data).attr('src', _backsrc) if _backsrc.length < _src.length && _backsrc.length < $(data).attr('src').length

      _newHtml = _.unescape $('<p>').append($(data).clone()).html().replace('>', '/>').replace(' data-src="ers"', '').replace('data-backsrc="ers"', '')
      _article.content = _article.content.replace(_oldHtml, _newHtml) if _src != undefined || _backsrc != undefined

    # 处理信息
    _article.digest = ue.getContentTxt().substr(0, 54) if $scope.ui.articles.length <= 1 && _article.digest == ''
    _article.content = _article.content.replace(/\ onclick="editorShowSelect\(this\)"|editor-operation-active|editor-operation/g, '').replace(/\ class=""/g, '').replace(/\<img/g, '\<image').replace(/\'/g, '')
      # .replace(/\"http\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn')
      # .replace(/\(http\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn')
    _article.show_cover_pic = if _article.show_cover_pic_bool is false then 0 else 1
    titleState = false if _article.title == ''
    contentState = false if _article.content == ''
    coverState = false if _article.thumb_media_id == ''

    return growl.error '標題不可為空' if titleState == false
    return growl.error '正文不可為空' if contentState == false
    return growl.error '封面不可為空' if coverState == false

    dialogs.wait '請稍後', '正在保存...'

    _is_new = false
    _is_new = true if _.contains($scope.ui.articleId, _article.id) == false

    _article.is_new = _is_new

    $http.post "#{config.api.baseUrl}/material/article/save/one?access_token=" + Storage.get('access_token'),
      article: _article
      original_id: $stateParams.original_id
      material_id: data.material._id
    .then (res) ->
      $scope.ui.newArticleId = _.reject($scope.ui.newArticleId, (obj) ->
        return obj == _article.id
      )
      $scope.ui.is_add = false if $scope.ui.newArticleId.length == 0
      $scope.ui.articleId.push _article.id

      growl.success('保存成功')
      $rootScope.$broadcast 'dialogs.wait.complete'
      SocketIO.emit 'clientID', 'WeChat-Graphic-' + data.material._id, {id: $scope.ui.user.id, name: $scope.ui.user.username, type: 'save-single'}
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $rootScope.$broadcast 'dialogs.wait.complete'

  ### 保存排序 ###
  $scope.saveSort = ->
    $interval.cancel($scope.timer) if $scope.timer
    $scope.ui.isSortDisabled = true
    $scope.ui.intervalIndex = 0

    $http.post "#{config.api.baseUrl}/material/article/save/order?access_token=" + Storage.get('access_token'),
      ids: _.pluck $scope.ui.articles, 'id'
      material_id: data.material._id
      original_id: $stateParams.original_id
    .then (res) ->
      $scope.ui.is_sort = false
      $scope.ui.isSortDisabled = false
      growl.success('保存成功')
      SocketIO.emit 'clientID', 'WeChat-Graphic-' + data.material._id, {id: $scope.ui.user.id, name: $scope.ui.user.username, type: 'save-sort'}
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  ### 获取最新内容 ###
  getLatestContet = (obj, type) ->
    $scope.ui.is_tips = false
    $scope.ui.tip_text = obj.name + ' 修改了單個圖文，' if type == 'single'
    $scope.ui.tip_text = obj.name + ' 修改了全部圖文，' if type == 'all'
    $scope.ui.tip_text_status = '正在更新...'
    $scope.ui.is_tips = true
    getLatestContetData(type)

  ### 获取最新内容 - 请求 ###
  getLatestContetData = (type) ->
    $http.get "#{config.api.baseUrl}/material/article/articleinfo/#{data.material._id}?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      $scope.ui.articleId = []
      if type == 'single'
        _.each res.data.articles, (data, i) ->
          $scope.ui.articleId.push data.id
          if i < $scope.ui.articles.length
            if $scope.ui.articles[i].updated_at < data.updated_at
              data.content = setContentImg data.content
              $scope.ui.articles[i] = data
              $scope.ui.activeArticle = $scope.ui.articles[i] if i == $scope.ui.activeIndex
          else
            data.content = setContentImg data.content
            $scope.ui.articles.push(data)
      else if type == 'all'
        $scope.ui.articles = res.data.articles
        setContent()
        $scope.ui.activeArticle = $scope.ui.articles[0]
        $scope.ui.activeIndex = 0

      $scope.ui.tip_text_status = '更新完成！'
      $timeout (->
        $scope.ui.is_tips = false
      ), 3000
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  ### 获取最新排序 ###
  getLatestSort = (obj) ->
    $scope.ui.is_tips = false
    $scope.ui.tip_text = obj.name + '修改了圖文順序，'
    $scope.ui.tip_text_status = '正在更新...'
    $scope.ui.is_tips = true
    getLatestSortData()

  ### 获取最新排序 - 请求 ###
  getLatestSortData = ->
    $http.get "#{config.api.baseUrl}/material/article/getOrder?access_token=" + Storage.get('access_token'),
      params:
        material_id: data.material._id
    .then (res) ->
      _arr = angular.copy $scope.ui.articles
      $scope.ui.articles = []
      _.each res.data.ids, (data) ->
        $scope.ui.articles.push(_.findWhere(_arr, {id: data}))

      $scope.ui.tip_text_status = '更新完成！'
      $timeout (->
        $scope.ui.is_tips = false
      ), 3000
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  ### 被删除处理 ###
  deleteGraphic = (obj) ->
    growl.error '該圖文已被 ' + obj.name + ' 刪除'
    if $scope.ui.is_new == false
      SocketIO.emit 'clientID', 'WeChat-Graphic-' + data.material._id, null
      SocketIO.removeListener 'WeChat-Graphic-' + data.material._id
      $interval.cancel($scope.timer) if $scope.timer
    $uibModalInstance.close 'Cancel'

  ### 從素材庫引用文章 ###
  $scope.selectSingleGraphic = ->
    dlg = dialogs.create('apps/material/graphic/modal-single/modal-single', 'SingleGraphicCtrl', {radio: true}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      option.content = option.content.replace(/\"http\:\/\/mmbiz.qpic.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn').replace(/\(http\:\/\/mmbiz.qpic.cn/g, '(' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn')
      $scope.ui.activeArticle.title = option.title
      $scope.ui.activeArticle.content = option.content
      $scope.ui.activeArticle.cover_pic_url = option.cover_pic_url
      $scope.ui.activeArticle.thumb_media_id = option.thumb_media_id
    ), ->

  ### 線上採集 ###
  $scope.selectGraphicUrl = ->
    dlg = dialogs.create('apps/material/graphic/modal-url/modal-url', 'GraphicUrlModalCtrl', {}, {size: 'md'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'

      dialogs.wait '請稍後', '正在採集圖文信息...'
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/collect/url?access_token=" + Storage.get('access_token'),
        url: option
      .then (res) ->
        if res.data.content == null
          growl.error '採集圖文不存在，請檢查鏈接'
          $rootScope.$broadcast 'dialogs.wait.complete'
          return

        $scope.ui.activeArticle.title = res.data.title

        _content = res.data.content
        _content = $("#{_content}").html()

        $rows = $("#{_content}")
        _.each $rows.find('img').toArray(), (data, i) ->
          _oldHtml = _.unescape $('<p>').append($(data).clone()).html()
          _src = $(data).data('src')
          _backsrc = $(data).data('backsrc')

          _newHtml = angular.copy _oldHtml
          if _backsrc != undefined
            _newHtml = _.unescape $('<p>').append($(data).clone()).html().replace('data-backsrc', 'src')
          else if _src != undefined
            _newHtml = _.unescape $('<p>').append($(data).clone()).html().replace('data-src', 'src')

          _content = _content.replace(_oldHtml, _newHtml)

        _content = _content.replace(/\"http\:\/\/mmbiz\.qpic\.cn/g, '"' + config.api.baseUrl + '/imgserve?_url=http://mmbiz.qpic.cn')

        $scope.ui.activeArticle.content = _content

        growl.success '採集成功'
        $rootScope.$broadcast 'dialogs.wait.complete'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), ->
