angular.module 'app'
.controller 'ArticleManageCtrl', ($scope, $state, $http, $q, Storage, $stateParams, config, SweetAlert, growl, dialogs) ->
  $scope.ui =
    isLoading: false
    user: Storage.get('login_user')
    baseUrl: config.api.baseUrl

    column: {}
    articles: []

    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0

  do findColumn = ->
    $http.get "#{config.api.baseUrl}/cumpus/programa/#{$stateParams._id}", {}
    .then (res) ->
      $scope.ui.column = res.data
    .catch (error) ->

  $scope.goBack = ->
    $state.go("app.column-manage", {})

  findData = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/cumpus/programa/list",
      params:
        is_content: true
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        group_id: $scope.ui.user.group_id
        programa_id: $stateParams._id
        sort: '-created_at'
    .then (res) ->
      $scope.ui.articles = res.data.data
      $scope.ui.totalItems = res.data.count

      _.each $scope.ui.articles, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm')
        data.release_time = moment(data.release_time).format('YYYY-MM-DD HH:mm')

      $scope.ui.isLoading = false
    .catch (error) ->
      $scope.ui.isLoading = false
      growl.error '查詢錯誤'

  $scope.$watch 'ui.currentPage', findData

  $scope.addArticle = ->
    dlg = dialogs.create("apps/campus/column-manage/modal/add-article-select/add-article-select", "AddArticleSelectCtrl", {}, {size: 'lg'})
    dlg.result.then ((flag) ->
      if flag is 'new'
        dlg = dialogs.create('apps/campus/column-manage/modal/article-detail/article-detail', 'ArticleDetailCtrl', {type: 'add', columnId: $stateParams._id}, {size: 'lg'})
        dlg.result.then ((option) ->
          return if option == 'Cancel'
          findData()
        ), ->
      else if flag is 'sel'
        dlg = dialogs.create('apps/material/graphic/modal-single/modal-single', 'SingleGraphicCtrl', {radio: true}, {size: 'md'})
        dlg.result.then ((option) ->
          return if option == 'Cancel'
          $http.post "#{config.api.baseUrl}/cumpus/programa/list", option
          .then (res) ->
            growl.success '保存成功'
            findData()
          .catch (res) ->
            growl.error '保存失敗'
        ), ->
    ), ->

  # chooseMaterial = ->
    # modalInstance = $modal.open
    #   templateUrl: "modules/website/column-manage/choose-material"
    #   windowClass: "new-task-win"
    #   controller: "ChooseMaterialCtrl"

    # modalInstance.dismiss = (datas) ->
    #   articles = []
    #   _.each datas, (val) ->
    #     article =
    #       group_id: $scope.ui.user.group_id
    #       programa_id: $stateParams._id
    #       type: 'article'
    #       data:
    #         is_head: false
    #         cover_img: "/file/uploadfiles" + val.picUrl.split('file/uploadfiles')[1]
    #         is_show: true
    #         author: val.author
    #         title: val.title
    #         description: val.digest
    #         content: val.content
    #     articles.push article

      # promise = _.map articles, (val) ->
      #   $http.post "#{config.api.baseUrl}/cumpus/programa-list/list", val
      #   .then (res) ->
      # $q.all promise
      # .then (res) ->
      #   growl.success '保存成功'
      #   findData()
      # .catch (res) ->
      #   growl.error '保存失敗'

  $scope.editArticle = (article) ->
    dlg = dialogs.create('apps/campus/column-manage/modal/article-detail/article-detail', 'ArticleDetailCtrl', {type: 'edit', _id: article._id, columnId: $stateParams._id}, {size: 'lg'})
    dlg.result.then ((option) ->
      return if option == 'Cancel'
      findData()
    ), ->

  $scope.editIsShow = (article) ->
    $http.put "#{config.api.baseUrl}/cumpus/programa/list/#{article._id}", article
    .then (res) ->
      growl.success '保存成功'
    .catch (error) ->
      article.data.is_show = !article.is_show
      growl.error '保存失敗'

  $scope.editIsHead = (article) ->
    $http.put "#{config.api.baseUrl}/cumpus/programa-list/list/#{article._id}", article
    .then (res) ->
      growl.success '保存成功'
    .catch (error) ->
      article.data.is_head = !article.is_head
      growl.error '保存失敗'

  $scope.delArticle = (article) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{article.data.title}”文章嗎？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/cumpus/programa/list/#{article._id}", {}
        .then (res) ->
          findData()
          growl.success '刪除成功'
        .catch (error) ->
          growl.error '刪除失敗'

  $scope.previewQrcode = (article) ->
    dlg = dialogs.create("apps/campus/column-manage/modal/preview-modal/preview-modal", "PreviewModalCtrl", {type: 'article', data: article}, {size: 'lg'})
    dlg.result.then ((option) ->
    ), ->
