angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    # 版面设定
    .state 'app.website-set',
      url: '/website-set'
      templateUrl: 'apps/campus/website-set/layout/layout'
      controller: 'WebsiteSetLayoutCtrl'

    # 版面设定 - 基础信息
    .state 'app.website-set.basic-information',
      url: '/basic-information'
      templateUrl: 'apps/campus/website-set/basic-information/basic-information'
      controller: 'BasicInformationCtrl'

    # 版面设定 - 版面设定
    .state 'app.website-set.page-setting',
      url: '/page-setting'
      templateUrl: 'apps/campus/website-set/page-setting/page-setting'
      controller: 'PageSettingCtrl'

    # 版面设定 - 头条设置
    .state 'app.website-set.headline-setting',
      url: '/headline-setting'
      templateUrl: 'apps/campus/website-set/headline-setting/headline-setting'
      controller: 'HeadlineSettingCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])

    # 版面设定 - 广告设置
    .state 'app.website-set.advertising-setting',
      url: '/advertising-setting'
      templateUrl: 'apps/campus/website-set/advertising-setting/advertising-setting'
      controller: 'AdvertisingSettingCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])

    # 欄目管理
    .state 'app.column-manage',
      url: '/column-manage'
      templateUrl: 'apps/campus/column-manage/column-manage'
      controller: 'ColumnManageCtrl'

    # 欄目管理 - 图文管理
    .state 'app.article-manage',
      url: '/column-manage/article-manage/:_id'
      templateUrl: 'apps/campus/column-manage/article-manage/article-manage'
      controller: 'ArticleManageCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])

    # 學籍資料
    .state 'app.school-information',
      url: '/school-information'
      templateUrl: 'apps/campus/school-information/layout/layout'
      controller: 'SchoolInformationLayoutCtrl'

    # 學籍資料 - 學生
    .state 'app.school-information.student',
      url: '/student'
      templateUrl: 'apps/campus/school-information/student/student'
      controller: 'StudentCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
              ]
          ])

    # 學籍資料 - 校友資料
    .state 'app.school-information.alumni',
      url: '/alumni'
      templateUrl: 'apps/campus/school-information/alumni/alumni'
      controller: 'AlumniCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'jqueryFileDownload/jquery.fileDownload.js'
              ]
          ])
