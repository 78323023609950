angular.module 'app'
.controller 'MenuTestCtrl', ($rootScope, $stateParams, $scope, $http, $timeout, $state, config, Storage, growl, dialogs, SweetAlert) ->

  $scope.ui =
    menuArr: []
    by_user:
      tagid_list: ''
      country: ''
      province: ''
      city: ''
      sex: ''
      nickname: ''
      language: ''
    by_menu: {}
    is_match: false
    menu_id: $stateParams.menu_id
    user: Storage.get('login_user')
    isActive: -1  # 主菜单焦点
    isSubActive: -1  # 子菜单焦点
    activeMenuArr: {}  # 焦点菜单信息

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  do findata = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/menu-test?access_token=" + Storage.get('access_token'),
      params:
        user_id: $stateParams.openid
        menu_id: $scope.ui.menu_id
    .then (res) ->
      res.data.by_menu.created_at = moment(res.data.by_menu.created_at).format('YYYY-MM-DD HH:mm:ss')
      $scope.ui.by_user = res.data.by_user
      $scope.ui.menuArr = res.data.by_menu.button
      $scope.ui.by_menu = res.data.by_menu
      $scope.ui.is_match = res.data.is_match
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 切换主菜单焦点
  $scope.switchActive = (_index) ->
    $scope.ui.isSubActive = -1
    $scope.ui.isActive = _index
    $scope.ui.activeMenuArr = $scope.ui.menuArr[$scope.ui.isActive]

  # 切换子菜单焦点
  $scope.switchSubActive = (_index) ->
    $scope.ui.isSubActive = _index
    $scope.ui.activeMenuArr = $scope.ui.menuArr[$scope.ui.isActive].sub_button[$scope.ui.isSubActive]


  $scope.back = ->
    $state.go 'app.menumanagement.personalized-menu'
