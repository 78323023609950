angular.module 'app'
.controller 'ResearchImagesChooseCtrl', ($scope, $http, $state, $stateParams, $uibModalInstance, config, Storage, FileUploader, growl, data) ->
  $scope.ui =
    waterMark: false
    radio: data.radio
    type: data.type
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    materials: []
    selectMaterials: []
    ungroups: [
        name: '全部'
        _id: 'all'
      ,
        name: '未分組'
        _id: 'unclass'
    ]
    activeGroup:
      name: '全部'
      _id: 'all'
    saveState: false

  # $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
  #   params:
  #     user_group_id: $scope.ui.user.group_id
  #     original_id: $stateParams.original_id
  # .then (res) ->
  #   if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
  #   $scope.$watch 'ui.currentPage', findDatas
  #   findGroups()
  # .catch (error) ->
  #   if error.data.error.indexOf('errtext') >= 0
  #     err = JSON.parse error.data.error
  #     growl.error err.errtext
  #   else
  #     growl.error('網絡連接失敗，請稍後重試')

  findDatas = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/ers-website/material?access_token=" + Storage.get('access_token'),
      params:
        type: 'image'
        material_group_id: $scope.ui.activeGroup._id
        user_group_id: $scope.ui.user.group_id
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        product_id: 'research'
    .then (res) ->
      $scope.ui.totalItems = res.data.count
      $scope.ui.materials = res.data.data

      _.each $scope.ui.materials, (data) ->
        data.isSelect = false
        _.each $scope.ui.selectMaterials, (obj) ->
          data.isSelect = true if data._id == obj._id
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 查詢素材分組
  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/ers-website/material/group?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        material_group_id: $scope.ui.activeGroup._id
        type: 'image'
        product_id: 'research'
    .then (res) ->
      $scope.ui.ungroups[0].material_count = res.data.total
      $scope.ui.ungroups[1].material_count = res.data.no_group
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findGroups()
  $scope.$watch 'ui.currentPage', findDatas

  # 選中分組
  $scope.setActiveGroup = (group) ->
    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  $scope.setSelect = (image) ->
    if $scope.ui.radio is true
      $scope.ui.selectMaterials = []
      _.each $scope.ui.materials, (material) ->
        if material._id isnt image._id then material.isSelect = false

    if image.isSelect == false
      image.isSelect = true
      $scope.ui.selectMaterials.push image
    else
      image.isSelect = false
      _selectIndex = -1
      _.each $scope.ui.selectMaterials, (data, i) ->
        _selectIndex = i if data._id == image._id
      $scope.ui.selectMaterials.splice(_selectIndex, 1) if _selectIndex != -1

  $scope.uploaderClick = ->
    material_group_id = if $scope.ui.activeGroup._id is 'all' or $scope.ui.activeGroup._id is 'unclass' then '0' else $scope.ui.activeGroup._id
    waterMark_str = if $scope.ui.waterMark is true then $scope.ui.wechat.name else ''
    $scope.uploader.url = "#{config.api.baseUrl}/ers-website/material/fileupload?access_token=#{Storage.get('access_token')}&user_group_id=#{Storage.get('login_user').group_id}&type=image&material_group_id=#{material_group_id}&waterMark_str=#{waterMark_str}&product_id=research"

  uploader = $scope.uploader = new FileUploader(
    autoUpload: true
    removeAfterUpload: true
    url: ""
  )
  # FILTERS
  uploader.filters.push
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 2
        growl.error("不能上傳大於2MB的圖片！", {referenceId: 1})
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onBeforeUploadItem = (item) ->
    growl.success("上傳中！", {referenceId: 1})

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findGroups()
    findDatas()

  uploader.onErrorItem = (fileItem, response, status, headers) ->

  getMaterialId = (_id, _wechatId, callback) ->
    $http.put "#{config.api.baseUrl}/material/uploadWechat/#{_id}?access_token=" + Storage.get('access_token'),
      wechat_id: _wechatId
    .then (res) ->
      callback(res.data)
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      callback('err')

  $scope.clearSelect = ->
    $scope.ui.selectMaterials = []
    _.each $scope.ui.materials, (data) ->
      data.isSelect = false

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $scope.ui.saveState = true

    if $scope.ui.selectMaterials.length < 1
      $scope.ui.saveState = false
      growl.error("請至少選擇一張圖片")
      return

    if $scope.ui.waterMark
      $http.post "#{config.api.baseUrl}/material/waterMark/materialId?access_token=" + Storage.get('access_token'),
        material_ids: _.pluck $scope.ui.selectMaterials, '_id'
        original_id: $stateParams.original_id
      .then (res) ->
        $uibModalInstance.close res.data
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

    # if materials[0].file.media_id == undefined && materials[0].file.wechat_url == undefined
    #   getMaterialId(materials[0]._id, materials[0].wechat_id, (_data) ->
    #     if _data == 'err'
    #       $scope.ui.saveState = false
    #       growl.error("網絡連接失敗，請稍後重試")
    #       return

    #     materials[0].file.media_id = _data.media_id
    #     materials[0].file.wechat_url = _data.url
    #     $uibModalInstance.close materials
    #   )
    else
      $uibModalInstance.close $scope.ui.selectMaterials
