angular.module 'app'
.controller 'WechatCtrl', ($scope, $rootScope, $stateParams, $http, $state, $timeout, config, Storage, dialogs, SweetAlert, growl, CreateDateText, FileUploader) ->
  # return $state.go 'app.home' if Storage.get('user_permission').system.wechat.is_use == false
  $scope.ui =
    authority: Storage.get('user_permission').system.wechat.is_use

    user: Storage.get('login_user')
    isLoading: false
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    wechats: []
    defaultWechat: null
    search:
      name: ''
      weixin_id: ''

  findDatas = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        name: $scope.ui.search.name
        weixin_id: $scope.ui.search.weixin_id
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      # return growl.error "你還未錄入公眾號" if res.data.count == 0 && $scope.ui.search.name == '' && $scope.ui.search.weixin_id == ''
      $scope.ui.totalItems = res.data.count
      $scope.ui.defaultWechat = res.data.default
      $scope.ui.wechats = res.data.data
      _.each $scope.ui.wechats, (wechat) ->
        wechat.created_at_text = CreateDateText.format wechat.created_at
        wechat.version_name = if wechat.version is '0' then '內地版' else '海外版'
        wechat.type_name = if wechat.type is '0' then '訂閱號' else '服務號'

    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.currentPage', findDatas

  search = ->
    $scope.ui.totalItems = 0
    $scope.ui.wechats = []
    $scope.ui.currentPage = 1
    findDatas()

  $scope.search = search

  $scope.newDetail = ->
    dlg = dialogs.create('apps/wechat/detail/detail', 'WechatDetailCtrl', {wechat: null}, {size: 'md'})
    dlg.result.then ((option) ->
      search()
      $rootScope.$emit 'sidebarWechat'
    ), ->

  $scope.editDetail = (wechat) ->
    dlg = dialogs.create('apps/wechat/detail/detail', 'WechatDetailCtrl', {wechat: wechat}, {size: 'md'})
    dlg.result.then ((option) ->
      search()
      $rootScope.$emit 'sidebarWechat'
    ), ->

  $scope.showKey = (wechat) ->
    dlg = dialogs.create('apps/wechat/keyword/keyword', 'WechatKeyWordCtrl', {url: wechat.url, token: wechat.token}, {size: 'md'})
    dlg.result.then ((option) ->
    ), ->

  toDel = (wechat) ->
    $http.delete "#{config.api.baseUrl}/wechat/#{wechat._id}?access_token=" + Storage.get('access_token'), {}
    .then (res) ->
      $scope.ui.wechats.splice($scope.ui.wechats.indexOf(wechat), 1)
      $rootScope.$emit 'sidebarWechat'
      growl.success "刪除成功！"
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.delWechat = (wechat) ->
    if $stateParams.original_id is wechat.original_id
      SweetAlert.swal {
        title: "提示"
        text: "公眾號使用中不能刪除！"
        type: "warning"
        showCancelButton: false
        confirmButtonClass: 'btn-danger'
        confirmButtonText: '關閉'
      }, (isConfirm) ->
    else
      SweetAlert.swal {
        title: "你確定嗎?"
        text: "是否確定刪除“#{wechat.name}”公眾號？"
        type: "warning"
        showCancelButton: true
        confirmButtonClass: 'btn-danger'
        cancelButtonText: '取消'
        confirmButtonText: '確定'
      }, (isConfirm) ->
        if isConfirm
          toDel(wechat)

  $scope.uploadImg = (obj) ->
    dlg = dialogs.create('apps/wechat/watermark/watermark', 'WatermarkCtrl', obj, {size: 'md'})
    dlg.result.then ((option) ->
      search()
    ), ->

  $scope.setDefault = (data) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: if data.original_id == $scope.ui.defaultWechat then "是否確定取消默認公眾號？" else "是否確定將“#{data.name}”公眾號設為默認？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        if data.original_id == $scope.ui.defaultWechat
          $http.post "#{config.api.baseUrl}/wechat/deleteIsDefault?access_token=" + Storage.get('access_token'), {}
          .then (res) ->
            growl.success "取消成功！"
            $scope.ui.totalItems = 0
            $scope.ui.wechats = []
            $scope.ui.currentPage = 1
            Storage.set('defalult_wechat', null)
            findDatas()
          .catch (error) ->
            if error.data.error.indexOf('errtext') >= 0
              err = JSON.parse error.data.error
              growl.error err.errtext
            else
              growl.error('網絡連接失敗，請稍後重試')
        else
          $http.post "#{config.api.baseUrl}/wechat/modifyIsDefault?access_token=" + Storage.get('access_token'),
            original_id: data.original_id
          .then (res) ->
            growl.success "設置成功！"
            $scope.ui.totalItems = 0
            $scope.ui.wechats = []
            $scope.ui.currentPage = 1
            Storage.set('defalult_wechat', data.original_id)
            findDatas()
          .catch (error) ->
            if error.data.error.indexOf('errtext') >= 0
              err = JSON.parse error.data.error
              growl.error err.errtext
            else
              growl.error('網絡連接失敗，請稍後重試')


# 消息加解密方式 #
  $scope.lock = (wechat) ->
    dlg = dialogs.create('apps/wechat/lock/lock', 'LockCtrl', {wechat: wechat}, {size: 'md'})
    dlg.result.then ((option) ->
    ), ->

