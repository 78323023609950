angular.module 'app'
.controller 'TemplateExamineCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').issue.template.templateExamine.is_use == false

  $scope.ui =
    user: Storage.get('login_user')
    resourcesUrl: config.resources.baseUrl

    activeList: 0
    listArr: []


  findAll = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/task/getTaskInfo?access_token=" + Storage.get('access_token'),
      params:
        status: 'create'
    .then (res) ->
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
        data.send_at = moment(data.send_at).format('YYYY-MM-DD HH:mm:ss')
        if data.template.content != undefined
          html_str = data.template.content.replace(new RegExp('\n','g'),'<br>').split('<br>')
          keys = []
          _.each html_str, (val) ->
            _name = _key = _val = _color = ''
            _name = val.split('{{')[0]
            _key = val.split('{{')[1].split('.DATA}}')[0] if val.split('{{')[1] != undefined
            _.each data.data, (obj, name) ->
              _val = obj.value if name == _key
              _color = obj.color if name == _key
            keys.push {name: _name, key: _key, val: _val, color: _color}
          data.content = keys
      $scope.ui.listArr = res.data.data
    .finally ->
      $scope.$watch 'ui.currentPage', -> $scope.findData
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 处理审核
  examine = (type, callback) ->
    $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/task/#{$scope.ui.listArr[$scope.ui.activeList]._id}?access_token=" + Storage.get('access_token'),
      status: type
    .then (res) ->
      callback res
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      callback error

  # 批准
  $scope.approval = ->
    return if $scope.ui.listArr.length == 0
    examine('pass', (data) ->
      if data.status == 200
        growl.success('操作成功')
        findAll()
    )

  # 驳回
  $scope.reject = ->
    return if $scope.ui.listArr.length == 0
    examine('reject', (data) ->
      if data.status == 200
        growl.success('操作成功')
        findAll()
    )

  $scope.selectList = (_index) ->
    return if $scope.ui.activeList == _index
    $scope.ui.activeList = _index

  findAll()
