angular.module 'app'
.controller 'TwLargedataVideoCtrl', ($rootScope, $scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert) ->
  return $state.go 'app.weclome' if Storage.get('user_permission').twlargedata.is_use == false

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    materials: []
    selectMaterials: []
    selectState: false
    ungroups: [
        name: '全部'
        _id: 'all'
      ,
        name: '未分組'
        _id: 'unclass'
    ]
    activeGroup:
      name: '全部'
      _id: 'all'
    date: ''
      # startDate: moment().subtract(29, 'days')
      # endDate: moment()
    opts: config.opts

    isOfficial: ''

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#material-video'
  $scope.ui.opts.opens = 'right'

  # $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
  #   params:
  #     user_group_id: $scope.ui.user.group_id
  #     original_id: $stateParams.original_id
  # .then (res) ->
  #   if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
  #   $scope.$watch 'ui.currentPage', findDatas
  #   findGroups()
  # .catch (error) ->
  #   if error.data.error.indexOf('errtext') >= 0
  #     err = JSON.parse error.data.error
  #     growl.error err.errtext
  #   else
  #     growl.error('網絡連接失敗，請稍後重試')

  #
  $scope.$watch 'ui.isOfficial', (newValue, oldValue) ->
    return if newValue == oldValue
    findDatas()

  findDatas = ->
    _start_created_at = ''
    _end_created_at = ''

    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      _start_created_at = ''
      _end_created_at = ''
    else
      _start_created_at = moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
      _end_created_at = moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')

    $scope.ui.selectMaterials = []
    $scope.ui.selectState = false

    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/ers-website/material?access_token=" + Storage.get('access_token'),
      params:
        type: 'video'
        original_id: $stateParams.original_id
        material_group_id: $scope.ui.activeGroup._id
        user_group_id: $scope.ui.user.group_id
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        start_created_at: _start_created_at
        end_created_at: _end_created_at
        is_official: $scope.ui.isOfficial
        product_id: 'twlargedata'
    .then (res) ->
      $scope.ui.totalItems = res.data.count
      $scope.ui.materials = res.data.data
      _.each $scope.ui.materials, (data) ->
        data.file.thumb = "#{config.api.baseUrl}/imgserve?_url=" + encodeURIComponent(data.file.thumb) if data.file.thumb != '' && data.file.thumb.indexOf('https://') == -1 && data.file.is_official == true
        data.file.thumb = config.api.baseUrl + data.file.thumb if data.file.thumb != '' && data.file.is_official == false
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.search = ->
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  # 查詢素材分組
  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/ers-website/material/group?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        material_group_id: $scope.ui.activeGroup._id
        type: 'video'
        original_id: $stateParams.original_id
        product_id: 'twlargedata'
    .then (res) ->
      $scope.ui.ungroups[0].material_count = res.data.total
      $scope.ui.ungroups[1].material_count = res.data.no_group
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findGroups()
  $scope.$watch 'ui.currentPage', findDatas

  # 選中分組
  $scope.setActiveGroup = (group) ->
    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  # 打開上傳
  $scope.uploadFile = ->
    material_group_id = if $scope.ui.activeGroup._id is 'all' or $scope.ui.activeGroup._id is 'unclass' then '0' else $scope.ui.activeGroup._id
    dlg = dialogs.create('apps/tw-largedata/upload-file/upload-file', 'TwLargedataImageUploadFileCtrl', {type: 'video', material_group_id: material_group_id, wechat_id: ''}, {backdrop: 'static', size: 'lg'})
    dlg.result.then ((option) ->
      findDatas()
      findGroups()
    ), ->


  # toDel = (image) ->
  #   $http.delete "#{config.api.baseUrl}/ers-website/material/#{image._id}?access_token=" + Storage.get('access_token'), {}
  #   .then (res) ->
  #     findDatas()
  #     findGroups()
  #     growl.success "刪除成功！"
  #   .catch (error) ->
  #     if error.data.error.indexOf('errtext') >= 0
  #       err = JSON.parse error.data.error
  #       growl.error err.errtext
  #     else
  #       growl.error('網絡連接失敗，請稍後重試')

  # $scope.delImage = (image) ->
  #   SweetAlert.swal {
  #     title: "你確定嗎?"
  #     text: "是否確定刪除視頻？"
  #     type: "warning"
  #     showCancelButton: true
  #     confirmButtonClass: 'btn-danger'
  #     cancelButtonText: '取消'
  #     confirmButtonText: '確定'
  #   }, (isConfirm) ->
  #     if isConfirm
  #       toDel(image)

  # 刪除视频
  $scope.delImage = (image) ->
    SweetAlert.swal {
      title: "是否確定刪除視頻?"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/ers-website/material/#{image._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findDatas()
          findGroups()
          growl.success "刪除成功"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')


  # 新建分組
  $scope.groupPopover =
    isOpen: false
    title: "請填寫分組名稱"
    name: ''
    templateUrl: 'groupPopoverTemplate.html'
    open: ->
      $scope.groupPopover.isOpen = true
    close: ->
      $scope.groupPopover.isOpen = false
    save: ->
      $http.post "#{config.api.baseUrl}/ers-website/material/create/group?access_token=" + Storage.get('access_token'),
        wechat_id: $stateParams.original_id
        user_group_id: $scope.ui.user.group_id
        name: $scope.groupPopover.name
        type: 'video'
        product_id: 'twlargedata'
      .then (res) ->
        findGroups()
        growl.success "保存成功！"
        $scope.groupPopover.isOpen = false
        $scope.groupPopover.name = ''
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  # 修改組名
  $scope.editGroupPopover =
    isOpen: false
    title: "修改組名"
    name: ''
    templateUrl: 'editGroupPopoverTemplate.html'
    open: ->
      $scope.editGroupPopover.isOpen = true
    close: ->
      $scope.editGroupPopover.isOpen = false
    save: ->
      $http.put "#{config.api.baseUrl}/ers-website/material/group/#{$scope.ui.activeGroup._id}?access_token=" + Storage.get('access_token'),
        name: $scope.editGroupPopover.name
      .then (res) ->
        $scope.ui.activeGroup.name = $scope.editGroupPopover.name
        _.each $scope.ui.groups, (group) ->
          if group._id is $scope.ui.activeGroup._id
            group.name = $scope.editGroupPopover.name
        growl.success "保存成功！"
        $scope.editGroupPopover.isOpen = false
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  # 修改圖片名
  $scope.imgNamePopover =
    data: null
    title: "視頻名稱"
    templateUrl: 'imgNamePopoverTemplate.html'
    save: ->
      $http.put "#{config.api.baseUrl}/ers-website/material/#{$scope.imgNamePopover.data._id}?access_token=" + Storage.get('access_token'),
        name: $scope.imgNamePopover.data.name
      .then (res) ->
        growl.success "保存成功！"
        _.each $scope.ui.materials, (image) ->
          if image._id is $scope.imgNamePopover.data._id
            image.name = $scope.imgNamePopover.data.name
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  $scope.setImgNamePopover = (image) ->
    $scope.imgNamePopover.data = angular.copy image


  # 移動分組
  $scope.exchangePopover =
    data: null
    title: "移動分組"
    templateUrl: 'exchangePopoverTemplate.html'
    save: ->
      $http.put "#{config.api.baseUrl}/ers-website/material/#{$scope.exchangePopover.data._id}?access_token=" + Storage.get('access_token'),
        material_group_id: if $scope.exchangePopover.data.material_group_id is 'unclass' then '0' else $scope.exchangePopover.data.material_group_id
      .then (res) ->
        growl.success "移動分組成功！"
        findDatas()
        findGroups()
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  $scope.setExchangePopover = (image) ->
    $scope.exchangePopover.data = angular.copy image

  # 刪除分組
  $scope.delGroup = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{$scope.ui.activeGroup.name}”分組？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/ers-website/material/group/#{$scope.ui.activeGroup._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findGroups()
          $scope.ui.activeGroup = $scope.ui.groups[0]
          $scope.ui.totalItems = 0
          $scope.ui.materials = []
          $scope.ui.currentPage = 1
          findDatas()
          growl.success "刪除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.setSelect = (material) ->
    material.isSelect = !material.isSelect

    if material.isSelect == true
      $scope.ui.selectMaterials.push material._id
    else
      deleteArr = -1
      _.each $scope.ui.selectMaterials, (data, i) ->
        deleteArr = i if material._id == data
      $scope.ui.selectMaterials.splice deleteArr, 1

    $scope.ui.selectState = if $scope.ui.selectMaterials.length > 0 then true else false

  $scope.delData = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除當前所選視頻？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.post "#{config.api.baseUrl}/ers-website/material/delete/bitch?access_token=" + Storage.get('access_token'),
          material_ids: $scope.ui.selectMaterials
        .then (res) ->
          findDatas()
          findGroups()
          growl.success "刪除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.sync = ->
    dialogs.wait '請稍後', '正在同步視頻信息，請勿做其他操作...'
    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/ers-website/init/sync/video?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      $rootScope.$broadcast 'dialogs.wait.complete'
      if res.status == 200
        findGroups()
        findDatas()
        growl.success "同步視頻信息成功"
      else
        growl.error "網絡連接失敗，請稍後重試"
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')
      $rootScope.$broadcast 'dialogs.wait.complete'
