angular.module 'app'
.controller 'TwLargedataGraphicCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl, SweetAlert, SocketIO) ->
  return $state.go 'app.weclome' if Storage.get('user_permission').twlargedata.is_use == false

  $scope.ui =
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    materials: []
    selectMaterials: []
    selectState: false
    ungroups: [
        name: '全部'
        _id: ''
      ,
        name: '未分組'
        _id: '0'
    ]
    activeGroup:
      name: '全部'
      _id: ''
    search:
      text: ''
    date: ''
      # startDate: moment().subtract(29, 'days')
      # endDate: moment()
    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#material-graphic'
  $scope.ui.opts.opens = 'right'

  # $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
  #   params:
  #     user_group_id: $scope.ui.user.group_id
  #     original_id: $stateParams.original_id
  # .then (res) ->
  #   if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
  #   findGroups()
  #   $scope.$watch 'ui.currentPage', findDatas
  # .catch (error) ->
  #   if error.data.error.indexOf('errtext') >= 0
  #     err = JSON.parse error.data.error
  #     growl.error err.errtext
  #   else
  #     growl.error('網絡連接失敗，請稍後重試')

  findDatas = ->
    _start_created_at = ''
    _end_created_at = ''

    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      _start_created_at = ''
      _end_created_at = ''
    else
      _start_created_at = moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
      _end_created_at = moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')

    $scope.ui.selectMaterials = []
    $scope.ui.selectState = false

    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/ers-website/material/article/articleinfo?access_token=" + Storage.get('access_token'),
      params:
        type: "news"
        title: $scope.ui.search.text
        user_group_id: $scope.ui.user.group_id
        material_group_id: $scope.ui.activeGroup._id
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        start_created_at: _start_created_at
        end_created_at: _end_created_at
        product_id: 'twlargedata'
    .then (res) ->
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
      $scope.ui.totalItems = res.data.count
      $scope.ui.materials = res.data.data
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 查詢素材分組
  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/ers-website/material/group?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        material_group_id: $scope.ui.activeGroup._id
        type: 'news'
        product_id: 'twlargedata'
    .then (res) ->
      $scope.ui.ungroups[0].material_count = res.data.total
      $scope.ui.ungroups[1].material_count = res.data.no_group
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findGroups()
  $scope.$watch 'ui.currentPage', findDatas

  # 選中分組
  $scope.setActiveGroup = (group) ->
    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  # 添加图文
  $scope.addMaterial = (group_id) ->
    $state.go("app.twlargedata.twlargedata-detail", {group_id: group_id})

  # 修改图文
  $scope.editMaterial = (_material) ->
    $state.go("app.twlargedata.twlargedata-detail", {_id: _material._id})

  # 刪除图文
  $scope.delMaterial = (material) ->
    SweetAlert.swal {
      title: "是否確定刪除圖文?"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/ers-website/material/article/articleinfo/#{material}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findDatas()
          findGroups()
          growl.success "刪除成功"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  # 新建分組
  $scope.groupPopover =
    isOpen: false
    title: "請填寫分組名稱"
    name: ''
    templateUrl: 'groupPopoverTemplate.html'
    open: ->
      $scope.groupPopover.isOpen = true
    close: ->
      $scope.groupPopover.isOpen = false
    save: ->
      $http.post "#{config.api.baseUrl}/ers-website/material/create/group?access_token=" + Storage.get('access_token'),
        wechat_id: $stateParams.original_id
        user_group_id: $scope.ui.user.group_id
        name: $scope.groupPopover.name
        type: 'news'
        product_id: 'twlargedata'
      .then (res) ->
        findGroups()
        growl.success "保存成功！"
        $scope.groupPopover.isOpen = false
        $scope.groupPopover.name = ''
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  # 修改組名
  $scope.editGroupPopover =
    isOpen: false
    title: "修改組名"
    name: ''
    templateUrl: 'editGroupPopoverTemplate.html'
    open: ->
      $scope.editGroupPopover.isOpen = true
    close: ->
      $scope.editGroupPopover.isOpen = false
    save: ->
      $http.put "#{config.api.baseUrl}/ers-website/material/group/#{$scope.ui.activeGroup._id}?access_token=" + Storage.get('access_token'),
        name: $scope.editGroupPopover.name
      .then (res) ->
        $scope.ui.activeGroup.name = $scope.editGroupPopover.name
        _.each $scope.ui.groups, (group) ->
          if group._id is $scope.ui.activeGroup._id
            group.name = $scope.editGroupPopover.name
        growl.success "保存成功！"
        $scope.editGroupPopover.isOpen = false
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  # 移動分組
  $scope.exchangePopover =
    data: null
    title: "移動分組"
    templateUrl: 'exchangePopoverTemplate.html'
    save: ->
      $http.put "#{config.api.baseUrl}/ers-website/material/#{$scope.exchangePopover.data._id}?access_token=" + Storage.get('access_token'),
        material_group_id: if $scope.exchangePopover.data.material_group_id is 'unclass' then '0' else $scope.exchangePopover.data.material_group_id
      .then (res) ->
        growl.success "移動分組成功！"
        findDatas()
        findGroups()
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')

  $scope.setExchangePopover = (material) ->
    $scope.exchangePopover.data = angular.copy material

  # 刪除分組
  $scope.delGroup = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{$scope.ui.activeGroup.name}”分組？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/ers-website/material/group/#{$scope.ui.activeGroup._id}?access_token=" + Storage.get('access_token'), {}
        .then (res) ->
          findGroups()

          $scope.ui.activeGroup = $scope.ui.groups[0]
          $scope.ui.totalItems = 0
          $scope.ui.materials = []
          $scope.ui.currentPage = 1
          findDatas()

          growl.success "刪除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')

  $scope.searchText = ->
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  $scope.setSelect = (material) ->
    material.isSelect = !material.isSelect

    if material.isSelect == true
      $scope.ui.selectMaterials.push material._id
    else
      deleteArr = -1
      _.each $scope.ui.selectMaterials, (data, i) ->
        deleteArr = i if material._id == data
      $scope.ui.selectMaterials.splice deleteArr, 1

    $scope.ui.selectState = if $scope.ui.selectMaterials.length > 0 then true else false

  # $scope.delData = ->
    # $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/check/check_material?access_token=" + Storage.get('access_token'),
    #   params:
    #     material_id: $scope.ui.selectMaterials
    # .then (res) ->
    #   _text = ''
    #   if res.data.appmsg + res.data.qrcode + res.data.menu > 0
    #     msg = ""
    #     msg += "#{res.data.appmsg}項自動應答設置" if res.data.appmsg > 0
    #     if res.data.qrcode > 0
    #       msg += if msg.length > 0 then "、#{res.data.qrcode}項二維碼設置" else "#{res.data.qrcode}項二維碼設置"
    #     if res.data.menu > 0
    #       msg += if msg.length > 0 then "、#{res.data.menu}項菜單設置" else "#{res.data.menu}項菜單設置"

    #     _text = "共有#{res.data.material_used.length}篇素材已有#{msg}使用中，是否確定刪除所選素材？"
    #   else
    #     _text = "是否確定刪除當前所選素材？"

    #   SweetAlert.swal {
    #     title: "你確定嗎?"
    #     text: _text
    #     type: "warning"
    #     showCancelButton: true
    #     confirmButtonClass: 'btn-danger'
    #     cancelButtonText: '取消'
    #     confirmButtonText: '確定'
    #   }, (isConfirm) ->
    #     if isConfirm
    #       $http.post "#{config.api.baseUrl}/material/delete/bitch?access_token=" + Storage.get('access_token'),
    #         material_ids: $scope.ui.selectMaterials
    #       .then (res) ->
    #         findDatas()
    #         growl.success "刪除成功！"
    #       .catch (error) ->
    #         if error.data.error.indexOf('errtext') >= 0
    #           err = JSON.parse error.data.error
    #           growl.error err.errtext
    #         else
    #           growl.error('網絡連接失敗，請稍後重試')

  # 刪除
  $scope.delData = ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除當前所選圖文？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.post "#{config.api.baseUrl}/ers-website/material/delete/bitch?access_token=" + Storage.get('access_token'),
          material_ids: $scope.ui.selectMaterials
        .then (res) ->
          findDatas()
          findGroups()
          growl.success "刪除成功！"
        .catch (error) ->
          if error.data.error.indexOf('errtext') >= 0
            err = JSON.parse error.data.error
            growl.error err.errtext
          else
            growl.error('網絡連接失敗，請稍後重試')
