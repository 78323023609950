angular.module 'app'
.controller 'MessageListCtrl', ($rootScope, $scope, $http, $stateParams, $state, config, Storage, growl, dialogs, SweetAlert, $timeout, Emoji) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').issue.comment.is_use == false

  $scope.ui =
    searchContent: ''
    totalItems: 0
    currentPage: 1
    perPage: 7
    maxSize: 8
    commentArr: []
    openidList: []
    wechats: Storage.get('Wechats')
    wechat: null
    isLoading: false
    resourcesUrl: config.api.baseUrl
    date:
      startDate: moment().subtract(7, 'days')
      endDate: moment()
    opts: config.opts

  _.each $scope.ui.wechats, (wechat) ->
    if wechat.original_id == $stateParams.original_id
      $scope.ui.wechat = wechat

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#meaasge-list'
  $scope.ui.opts.opens = 'right'

  # 查詢圖文評論列表
  findData = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/comment?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        sort: '-created_at'
        content: $scope.ui.searchContent
        comment_type: 'all'
        start_date: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_date: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
    .then (res) ->
      _.each res.data.data, (data) ->
        if typeof(data.content) == 'string'
          data.content = Emoji.replaceFace data.content
        if typeof(data.reply?.content) == 'string'
          data.reply.content = Emoji.replaceFace data.reply.content
          data.isShow = true
        else
          data.isShow = false

        data.create_time = moment(data.create_time).format('YYYY-MM-DD HH:mm:ss')

      $scope.ui.commentArr = res.data.data
      $scope.ui.totalItems = res.data.count
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 搜索
  $scope.searchName = ->
    findData()

  # 按粉絲搜索
  # $scope.fanSearch = ->
  #   dialogs.create('apps/message-management/fan-information/fan-information', 'FanInformationCtrl', {}, {size: 'lg'})
  #   .result.then ((option) ->
  #     return if option == 'Cancel'
  #     $scope.ui.openidList = option.openidList
  #     findData()
  #    ), ->

  # 回復
  $scope.toReply = (operation,data) ->
    dialogs.create('apps/message-management/reply-information/reply-information', 'ReplyInformationCtrl', {status: operation,replyCommentdata: data}, {size: 'md'})
    .result.then ((option) ->
      findData()
     ), ->

  # 操作評論
  $scope.operationComment = (operation,data) ->
    $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/mass/comment?access_token=" + Storage.get('access_token'),
        mass_send_id: data.mass_id
        index: data.index
        user_comment_ids: [data.user_comment_id]
        status: operation
    .then (res) ->
      growl.success "移入精選成功！" if operation == 'markelect'
      growl.success "移出精選成功！" if operation == 'unmarkelect'
      growl.success "刪除成功！" if operation == 'delete'
      growl.success "回復成功！" if operation == 'reply-add'
      growl.success "刪除回復成功！" if operation == 'reply-delete'
      findData()
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.currentPage', findData
