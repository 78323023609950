angular.module 'app'
.controller 'TemplateLibraryCtrl', ($scope, $http, $stateParams, $state, config, Storage, dialogs, growl) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').manage.template.templateLibrary.is_use == false

  $scope.ui =
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 10
    dataArr: []
    keys: []
    name: ''
    main: ''
    vice: ''

  $scope.findData = findData = ->
    $scope.ui.activeDataArr = []
    $http.get "#{config.api.baseUrl}/template/list?access_token=" + Storage.get('access_token'),
      params:
        original_id: $stateParams.original_id
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        name: $scope.ui.name
        main_industry: $scope.ui.main
        vice_industry: $scope.ui.vice
    .then (res) ->
      $scope.ui.dataArr = res.data.data
      $scope.ui.totalItems = res.data.count
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.$watch 'ui.currentPage', findData

  $scope.add = ->
    dialogs.create('apps/template/template-library/add/add', 'TemplateAddCtrl', {}, {size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.findData()
    ), ->

  $scope.getDetails = (_index) ->
    dialogs.create('apps/template/template-library/detail/detail', 'TemplateDetailCtrl', {_id: $scope.ui.dataArr[_index]._id, type: "library"}, {size: 'lg'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.findData()
    ), ->
