angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    # 素材
    .state 'app.twlargedata',
      url: '/twlargedata'
      templateUrl: 'apps/tw-largedata/layout/layout'
      controller: 'TwLargedataLayoutCtrl'

    # 素材－图片
    .state 'app.twlargedata.twlargedata-image',
      url: '/twlargedata-image'
      templateUrl: 'apps/tw-largedata/image/image'
      controller: 'TwLargedataImageCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 素材－视频
    .state 'app.twlargedata.twlargedata-video',
      url: '/twlargedata-video'
      templateUrl: 'apps/tw-largedata/video/video'
      controller: 'TwLargedataVideoCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 素材－图文
    .state 'app.twlargedata.twlargedata-graphic',
      url: '/twlargedata-graphic'
      templateUrl: 'apps/tw-largedata/graphic/graphic'
      controller: 'TwLargedataGraphicCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])

     # 添加圖文
    .state 'app.twlargedata.twlargedata-detail',
      url: '/twlargedata-detail/:_id/:group_id'
      templateUrl: 'apps/tw-largedata/graphic/detail/detail'
      controller: 'TwLargedataDetailCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])
