angular.module 'app'
.controller 'HomeCtrl', ($scope, $http, $timeout, $state, config, Storage, dialogs, growl, SocketIO, SweetAlert) ->
  $scope.ui =
    hasNull: false
    loading: false
    email: ''
    password: ''
    isAutoSignIn: false
    eadminDisplay: config.eadmin.display

  $scope.thisYear = moment().format('YYYY')

  $scope.keyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      $scope.doLogin()

  do isAutoSignIn = ->
    if localStorage.isAutoSignIn and localStorage.isAutoSignIn isnt "false"
      $scope.ui.loading = true
      _baseUrl = ''
      if $scope.ui.eadminDisplay == true
        _baseUrl = config.eadmin.newBaseUrl
      else
        _baseUrl = config.eadmin.baseUrl
      $http.post "#{_baseUrl}/oauth/signin",
        email: localStorage.email
        password: localStorage.password
        client_id: config.eadmin.clientId
      .then (res) ->
        if res.data.access_token
          Storage.set('access_token', res.data.access_token)
          socketClick()
        else
          $scope.ui.error = true
          $scope.ui.loading = false
      .catch (err) ->
        if err.status == 400
          $scope.ui.hasError = true
        else
          $scope.ui.error = true
        $scope.ui.loading = false

  $scope.doLogin = ->
    $scope.ui.loading = true
    if $scope.ui.email.length is 0 or $scope.ui.password.length is 0
      $scope.ui.hasNull = true
      $timeout((->
        $scope.ui.hasNull = false
      ), 2000)
      $scope.ui.loading = false
      return

    localStorage.email = $scope.ui.email
    localStorage.password = $scope.ui.password

    localStorage.isAutoSignIn = $scope.ui.isAutoSignIn if $scope.ui.isAutoSignIn

    _baseUrl = ''
    if $scope.ui.eadminDisplay == true
      _baseUrl = config.eadmin.newBaseUrl
    else
      _baseUrl = config.eadmin.baseUrl
    $http.post "#{_baseUrl}/oauth/signin",
      email: $scope.ui.email
      password: $scope.ui.password
      client_id: config.eadmin.clientId
    .then (res) ->
      if res.data.access_token
        Storage.set('access_token', res.data.access_token)
        socketClick()
      else
        $scope.ui.error = true
        $scope.ui.loading = false
    .catch (err) ->
      if err.status == 400
        $scope.ui.hasError = true
      else
        $scope.ui.error = true
      $scope.ui.loading = false

  $scope.retrieve = ->
    dlg = dialogs.create('apps/user/retrieve-password/retrieve-password', 'RetrievePasswordCtrl', {}, {size: 'md'})
    dlg.result.then ((option) ->
    ), ->

  socketClick = ->
    _baseUrl = ''
    if $scope.ui.eadminDisplay == true
      _baseUrl = config.eadmin.newBaseUrl
    else
      _baseUrl = config.eadmin.baseUrl
    $http.get "#{_baseUrl}/account/me",
      params:
        access_token: Storage.get('access_token')
    .then (res) ->
      Storage.set('login_user', res.data)
      Storage.set('user_permission', res.data.permission)

      _userId = Storage.get('login_user').id

      _loginOnly = Storage.get('user_permission').loginOnly

      return login() if _loginOnly == false

      SocketIO.emit 'clientID', 'Cms-Login-' + _userId, (n) ->
        return login() if n <= 0

        SweetAlert.swal {
          title: "你確定嗎?"
          text: "您的帳號已在別處登錄，是否強制登錄？"
          type: "warning"
          showCancelButton: true
          confirmButtonClass: 'btn-danger'
          cancelButtonText: '取消'
          confirmButtonText: '確定'
        }, (isConfirm) ->
          return login() if isConfirm

          Storage.remove('access_token')
          Storage.remove('login_user')
          Storage.remove('user_permission')
          $scope.ui.loading = false
    .catch (error) ->
      Storage.remove('access_token')
      $state.go('home')

  login = ->
    SocketIO.emit 'clientID', 'Cms-Login-' + Storage.get('login_user').id, 'logout' if Storage.get('user_permission').loginOnly != false
    growl.info '登錄成功，正在跳轉...'
    $scope.ui.loading = false
    $state.go 'app.weclome', {original_id: 'ers'}
