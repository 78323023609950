angular.module 'app'
.controller 'HktwMinerVideoChooseCtrl', ($scope, $http, $state, $stateParams, $uibModalInstance, config, Storage, FileUploader, growl, data) ->
  $scope.ui =
    waterMark: false
    radio: data.radio
    type: data.type
    resourcesUrl: config.resources.baseUrl
    user: Storage.get('login_user')
    isLoading: false
    isGroupLoading: false
    currentPage: 1
    perPage: 10
    maxSize: 8
    totalItems: 0
    materials: []
    ungroups: [
        name: '全部'
        _id: 'all'
      ,
        name: '未分組'
        _id: 'unclass'
    ]
    activeGroup:
      name: '全部'
      _id: 'all'
    saveState: false

  $http.get "#{config.api.baseUrl}/wechat?access_token=" + Storage.get('access_token'),
    params:
      user_group_id: $scope.ui.user.group_id
      original_id: $stateParams.original_id
  .then (res) ->
    if res.data.count > 0 then $scope.ui.wechat = res.data.data[0]
    $scope.$watch 'ui.currentPage', findDatas
    findGroups()
  .catch (error) ->
    if error.data.error.indexOf('errtext') >= 0
      err = JSON.parse error.data.error
      growl.error err.errtext
    else
      growl.error('網絡連接失敗，請稍後重試')

  findDatas = ->
    $scope.ui.isLoading = true

    _params =
      type: 'video'
      original_id: $stateParams.original_id
      material_group_id: $scope.ui.activeGroup._id
      user_group_id: $scope.ui.user.group_id
      limit: $scope.ui.perPage
      offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
      product_id: 'twMiner'

    # _params.is_official = true if $scope.ui.type == 'graphic'
    # _params.is_official = false if $scope.ui.type == 'information'

    $http.get "#{config.api.baseUrl}/ers-website/material?access_token=" + Storage.get('access_token'),
      params: _params
    .then (res) ->
      $scope.ui.totalItems = res.data.count
      $scope.ui.materials = res.data.data
      _.each $scope.ui.materials, (data) ->
        data.file.thumb = "#{config.api.baseUrl}/imgserve?_url=" + encodeURIComponent(data.file.thumb) if data.file.thumb != '' && data.file.thumb.indexOf('https://') == -1 && data.file.is_official == true
        data.file.thumb = config.api.baseUrl + data.file.thumb if data.file.thumb != '' && data.file.is_official == false
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 查詢素材分組
  findGroups = ->
    $scope.ui.isGroupLoading = true
    $http.get "#{config.api.baseUrl}/ers-website/material/group?access_token=" + Storage.get('access_token'),
      params:
        user_group_id: $scope.ui.user.group_id
        material_group_id: $scope.ui.activeGroup._id
        type: 'video'
        original_id: $stateParams.original_id
        product_id: 'twMiner'
    .then (res) ->
      $scope.ui.ungroups[0].material_count = res.data.total
      $scope.ui.ungroups[1].material_count = res.data.no_group
      $scope.ui.groups = []
      $scope.ui.groups = _.union($scope.ui.ungroups, res.data.data)
    .finally ->
      $scope.ui.isGroupLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  findGroups()
  $scope.$watch 'ui.currentPage', findDatas

  # 選中分組
  $scope.setActiveGroup = (group) ->
    $scope.ui.activeGroup = group
    $scope.ui.totalItems = 0
    $scope.ui.materials = []
    $scope.ui.currentPage = 1
    findDatas()

  $scope.setSelect = (image) ->
    image.isSelect = !image.isSelect
    if $scope.ui.radio is true
      _.each $scope.ui.materials, (material) ->
        if material._id isnt image._id then material.isSelect = false

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    $scope.ui.saveState = true

    materials = _.where($scope.ui.materials, {isSelect: true})

    if materials.length < 1
      $scope.ui.saveState = false
      growl.error("請至少選擇一個視頻")
      return

    $uibModalInstance.close materials
