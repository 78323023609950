angular.module 'app'
.controller 'TemplateMsgModalCtrl', ($scope, $http, $state, $sce, $stateParams, $uibModalInstance, config, Storage, dialogs, growl, data) ->
  $scope.ui =
    user: Storage.get('login_user')
    totalItems: 0
    currentPage: 1
    perPage: 10
    maxSize: 6
    dataArr: []

  do getTemplate = ->
    $scope.ui.dataArr = []
    $scope.ui.totalItems = 0
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/template?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
    .then (res) ->
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
        data.tip = $sce.trustAsHtml '<h4 style="color:#555;">' + data.name + '</h4><div class="margin-bottom-md">' + data.created_at + '</div><div style="font-size:13px;">' + data.example.replace(new RegExp('\n','g'),'<br>') + '</div>'
      $scope.ui.dataArr = res.data.data
      $scope.ui.totalItems = res.data.count
    .finally ->
      $scope.$watch 'ui.currentPage', -> $scope.findData
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.save = (data) ->
    $uibModalInstance.close data

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
