angular.module 'app'
.controller 'ResponseAddCtrl', ($scope, $http, $state, $uibModalInstance, $stateParams, config, Storage, dialogs, growl, data) ->
  $scope.ui =
    font_color: "#999"
    appmsg: {}
    reply_state: data.appmsg.reply_state
    resourcesUrl: config.resources.baseUrl
    rule:
      man: false
      male: false
      cn: false
      tw: false
      en: false
    fansGroups: []
    saveDisabled: false

  do initialization = ->
    $scope.ui.appmsg.rule = {}
    if data.appmsg._id
      $scope.ui.appmsg = data.appmsg
      $scope.ui.rule.man = true if _.contains $scope.ui.appmsg.rule.sexs, "1"
      $scope.ui.rule.male = true if _.contains $scope.ui.appmsg.rule.sexs, "2"
      $scope.ui.rule.cn = true if _.contains $scope.ui.appmsg.rule.languages, "zh_CN"
      $scope.ui.rule.tw = true if _.contains $scope.ui.appmsg.rule.languages, "zh_TW"
      $scope.ui.rule.en = true if _.contains $scope.ui.appmsg.rule.languages, "en"
    else
      $scope.ui.appmsg.name = ''
      $scope.ui.appmsg.full_keywords = [] if !$scope.ui.appmsg.full_keywords
      $scope.ui.appmsg.fuzzy_keywords = [] if !$scope.ui.appmsg.fuzzy_keywords
      $scope.ui.appmsg.rule.start_time = "00:00"
      $scope.ui.appmsg.rule.end_time = "23:59"
      $scope.ui.appmsg.rule.tag_id = "all"
      $scope.ui.appmsg.scan_event = {tag_id: 'notagId'}
      $scope.ui.appmsg.type = 'news'
      $scope.ui.appmsg.content = {articles: [], material_id: ''}
      $scope.ui.rule.man = true
      $scope.ui.rule.male = true
      $scope.ui.rule.cn = true
      $scope.ui.rule.tw = true
      $scope.ui.rule.en = true
    if !$scope.ui.appmsg.rule.sexs or $scope.ui.appmsg.rule.sexs.length > 1
      $scope.ui.appmsg.rule.sexs = ["1", "2"]
    if !$scope.ui.appmsg.rule.languages or $scope.ui.appmsg.rule.languages.length > 2
      $scope.ui.appmsg.rule.languages = ["zh_CN", "zh_TW", "en"]

  # 查詢粉絲分組
  do findGroups = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/follower/tag?access_token=" + Storage.get('access_token'),{}
    .then (res) ->
      $scope.ui.fansGroups = res.data.data
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 輸入框Enter事件(完全匹配)
  $scope.PerfectMatchkeyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      if !_.contains $scope.ui.appmsg.full_keywords, $scope.ui.appmsg.full_keyword
        $scope.ui.appmsg.full_keywords = [] if !$scope.ui.appmsg.full_keywords
        $scope.ui.appmsg.full_keywords.push $scope.ui.appmsg.full_keyword
      else
        growl.error "已存在"

  # 輸入框Enter事件(模糊匹配)
  $scope.FuzzyMatchkeyUp = (e) ->
    keycode = if window.event then e.keyCode else e.which
    if keycode == 13
      if !_.contains $scope.ui.appmsg.fuzzy_keywords, $scope.ui.appmsg.fuzzy_keyword
        $scope.ui.appmsg.fuzzy_keywords = [] if !$scope.ui.appmsg.fuzzy_keywords
        $scope.ui.appmsg.fuzzy_keywords.push $scope.ui.appmsg.fuzzy_keyword
      else
        growl.error "已存在"

  # 删除已选关键字
  $scope.deleteWord = (type, _index) ->
    $scope.ui.appmsg.full_keywords.splice _index, 1 if type == 'full'
    $scope.ui.appmsg.fuzzy_keywords.splice _index, 1 if type == 'fuzzy'

  $scope.switchSendAction = (_action) ->
    $scope.ui.appmsg.type = _action
    $scope.ui.appmsg.content = {articles: [], material_id: ''} if _action == 'news'
    $scope.ui.appmsg.content = {path: '', material_id: ''} if _action == 'image'
    $scope.ui.appmsg.content = {text: ''} if _action == 'text'

  # 选择图文
  $scope.selectGraphic = ->
    dialogs.create('apps/material/graphic/modal/modal', 'MaterialModalCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.appmsg.content = {}
      $scope.ui.appmsg.content.articles = option[0].articles
      $scope.ui.appmsg.content.material_id = option[0]._id
    ), ->

  # 删除图文
  $scope.delGraphic = ->
    $scope.ui.appmsg.content.articles = []
    $scope.ui.appmsg.content.material_id = ''

  $scope.selectImage = ->
    dialogs.create('apps/material/image/choose/choose', 'ImageChooseCtrl', {radio: true}, {backdrop: 'static', size: 'md'})
    .result.then ((option) ->
      return if option == 'Cancel'
      $scope.ui.appmsg.content = {}
      $scope.ui.appmsg.content.path = option[0].file.path
      $scope.ui.appmsg.content.material_id = option[0]._id
    ), ->

  # 删除图片
  $scope.delImage = ->
    $scope.ui.appmsg.content.path = ''
    $scope.ui.appmsg.content.material_id = ''

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    return growl.error "請輸入規則名稱" if $scope.ui.appmsg.name == ''

    if $scope.ui.reply_state == 2
      if $scope.ui.appmsg.fuzzy_keywords.length is 0 and $scope.ui.appmsg.full_keywords.length is 0
        $scope.ui.font_color = "red"
        return
    $scope.ui.appmsg.rule.sexs = []
    $scope.ui.appmsg.rule.languages = []
    $scope.ui.appmsg.rule.sexs.push "1" if $scope.ui.rule.man == true
    $scope.ui.appmsg.rule.sexs.push "2" if $scope.ui.rule.male == true
    $scope.ui.appmsg.rule.languages.push "zh_CN" if $scope.ui.rule.cn == true
    $scope.ui.appmsg.rule.languages.push "zh_TW" if $scope.ui.rule.tw == true
    $scope.ui.appmsg.rule.languages.push "zh_HK" if $scope.ui.rule.tw == true
    $scope.ui.appmsg.rule.languages.push "en" if $scope.ui.rule.en == true
    $scope.ui.appmsg.reply_state = $scope.ui.reply_state

    return growl.error "粉絲性別至少選擇一項" if $scope.ui.appmsg.rule.sexs.length == 0
    return growl.error "粉絲語言至少選擇一項" if $scope.ui.appmsg.rule.languages.length == 0

    $scope.ui.saveDisabled = true
    if data.appmsg._id
      $http.put "#{config.api.baseUrl}/#{$stateParams.original_id}/appmsg/#{data.appmsg._id}?access_token=" + Storage.get('access_token'), $scope.ui.appmsg
      .then (res) ->
        growl.success "修改成功"
        $uibModalInstance.close 'Save'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          # err = JSON.parse error.data.error
          growl.error '創建失敗'
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.saveDisabled = false
    else
      $http.post "#{config.api.baseUrl}/#{$stateParams.original_id}/appmsg?access_token=" + Storage.get('access_token'), $scope.ui.appmsg
      .then (res) ->
        growl.success "創建成功"
        $uibModalInstance.close 'Save'
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          # err = JSON.parse error.data.error
          growl.error '創建失敗'
        else
          growl.error('網絡連接失敗，請稍後重試')
        $scope.ui.saveDisabled = false
