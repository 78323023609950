angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    # 素材
    .state 'app.dMiner',
      url: '/dMiner'
      templateUrl: 'apps/dMiner/layout/layout'
      controller: 'dMinerLayoutCtrl'

    # 素材－图片
    .state 'app.dMiner.dMiner-image',
      url: '/dMiner-image'
      templateUrl: 'apps/dMiner/image/image'
      controller: 'dMinerImageCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 素材－视频
    .state 'app.dMiner.dMiner-video',
      url: '/dMiner-video'
      templateUrl: 'apps/dMiner/video/video'
      controller: 'dMinerVideoCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
              ]
          ])

    # 素材－图文
    .state 'app.dMiner.dMiner-graphic',
      url: '/dMiner-graphic'
      templateUrl: 'apps/dMiner/graphic/graphic'
      controller: 'dMinerGraphicCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])

     # 添加圖文
    .state 'app.dMiner.dMiner-detail',
      url: '/dMiner-detail/:_id/:group_id'
      templateUrl: 'apps/dMiner/graphic/detail/detail'
      controller: 'dMinerDetailCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
                'ueditor/jquery.minicolors/jquery.minicolors.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'ueditor/ueditor.config.js'
                'ueditor/ueditor.all.js'
                'ueditor/jquery.minicolors/jquery.minicolors.min.js'
                'ueditor/ueditor.setting.js'
              ]
          ])
