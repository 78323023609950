angular.module 'app'
.controller 'UserGrowCtrl', ($rootScope, $sce, $scope, $http, $stateParams, $state, config, $timeout, Storage, dialogs, growl) ->

  return $state.go 'app.wechat' if Storage.get('user_permission').analysis.fans.is_use == false

  $scope.ui =
    totalItems: 0  # 总页数
    currentPage: 1 # 当前页数
    perPage: 10
    maxSize: 10 # 最大条数数
    new_user: {}
    cancel_user: {}
    growth_user: {}
    cumulate_user: {}
    active_count: {}
    grow_statis: []
    subscribe_type: "all"
    table: []
    search_type: "subscribe_count"
    search_type_name: "新增關注人數"

    activeSort: 'statistical_time'
    activeSortType: true

    radioModel: '0'
    isContrast: false

    date:
      startDate: moment().subtract(7, 'days')
      endDate: moment().subtract(1, 'days')
    contrast:
      startDate: moment().subtract(14, 'days')
      endDate: moment().subtract(7, 'days')
    table_date:
      startDate: moment().subtract(7, 'days')
      endDate: moment()

    opts: config.opts

  # 設置日曆
  $scope.ui.opts.parentEl = 'div#user-grow'
  $scope.ui.opts.opens = 'right'

  $scope.toContrast = ->
    $scope.ui.isContrast = true

  $scope.noContrast = ->
    $scope.ui.isContrast = false
    fetchData()

  $scope.htmlPopover = $sce.trustAsHtml('<div class="help-popover" style="width: 300px"><dt>新關注人數</dt><dd>新關注的去重用戶數</dd><dt>取消關注人數</dt><dd>取消關注的去重用戶數</dd><dt>凈增關注人數</dt><dd>凈增長的去重關注用戶數</dd><dt>累積關注人數</dt><dd>當前關注的用戶總數</dd><dt>日、周、月</dt><dd>分別計算昨日數據相比1天、7天、30天前的變化情況</dd><dt>請註意</dt><dd>用戶總數根據昨日數據計算，而粉絲資料頁根據當前實時數據計算，兩者可能不一致</dd><dd class="border-top-solid padding-top-md">數據從2013年7月1日開始統計。由於服務器緩存，以及指標計算方法和統計時間的差異，數據可能出現微小誤差，一般在1%以內。</dd></div>')

  $scope.userGrowChart =
    title:
      text: ''
    tooltip:
      trigger: 'axis'
    legend:
      data: ['新增關注人數']
    grid:
      containLabel: true
    toolbox:
      show: true
      feature:
        restore:
          show: true
        saveAsImage:
          show: true
    xAxis: [{
      type: 'category'
      boundaryGap: false
      splitLine:
        show: false
      data: ['']
    }]
    yAxis: [
      type: 'value'
      axisLabel:
        formatter: '{value}'
    ]
    series: [{
      name: ''
      type: 'line'
      data: []
    }]

  # 取绝对值
  $scope.abs = (data) ->
    return Math.abs data

  fetchTable = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/grow_statis?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        start_statistical_time: moment($scope.ui.table_date.startDate).format('YYYY-MM-DD 00:00:00')
        end_statistical_time: moment($scope.ui.table_date.endDate).format('YYYY-MM-DD 23:59:59')
        sort: if $scope.ui.activeSortType is true then $scope.ui.activeSort else '-' + $scope.ui.activeSort
    .then (res) ->
      _.each res.data.data, (val) ->
        val.statistical_time = moment(val.statistical_time).format('YYYY-MM-DD')
      $scope.ui.table = res.data.data
      $scope.ui.totalItems = res.data.count
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  fetchData = ->
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/grow_statis?access_token=" + Storage.get('access_token'),
      params:
        start_statistical_time: moment($scope.ui.date.startDate).format('YYYY-MM-DD 00:00:00')
        end_statistical_time: moment($scope.ui.date.endDate).format('YYYY-MM-DD 23:59:59')
    .then (res) ->
      _.each res.data.data, (val) ->
        val.statistical_time = moment(val.statistical_time).format('YYYY-MM-DD')
      $scope.ui.grow_statis = res.data.data
      if res.data.count > 0
        $scope.userGrowChart.tooltip.formatter = null
        $scope.userGrowChart.series = [{
          name: ''
          type: 'line'
          data: []
        }]
        $scope.userGrowChart.xAxis[0].data = _.pluck res.data.data, "statistical_time"
        $scope.userGrowChart.legend.data = [$scope.ui.search_type_name]
        $scope.userGrowChart.series[0].name = $scope.ui.search_type_name
        $scope.userGrowChart.series[0].data = _.pluck res.data.data, $scope.ui.search_type
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/keyrate?access_token=" + Storage.get('access_token'),{}
    .then (resKeyrate) ->
      $scope.ui.new_user = resKeyrate.data.new_user
      $scope.ui.cancel_user = resKeyrate.data.cancel_user
      $scope.ui.cumulate_user = resKeyrate.data.cumulate_user
      $scope.ui.growth_user = resKeyrate.data.growth_user
      $scope.ui.active_count = resKeyrate.data.active_count
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.changeType = (type, name) ->
    if $scope.ui.isContrast
      contrastChart name
    else if type isnt 'subscribe_type'
      $scope.userGrowChart.legend.data = [name]
      $scope.userGrowChart.series[0].name = name
      $scope.userGrowChart.series[0].data = _.pluck $scope.ui.grow_statis, type
    else if $scope.ui.subscribe_type isnt 'all'
      name = switch $scope.ui.subscribe_type
        when 'public_search' then '公眾號搜索'
        when 'scan_qrcode' then '掃描二維碼'
        when 'article_page' then '圖文頁右上角菜單'
        when 'article_pub_name' then '圖文頁內公眾號名稱'
        when 'card_share' then '名片分享'
        when 'payment_page' then '支付後關注'
        when 'public_advertise' then '公眾號文章廣告'
        when 'ctrcle_advertise' then '朋友圈廣告'
        when 'other' then '其他合計'
      $scope.userGrowChart.legend.data = [name]
      $scope.userGrowChart.series[0].name = name
      $scope.userGrowChart.series[0].data = _.pluck $scope.ui.grow_statis, $scope.ui.subscribe_type
    else
      $scope.userGrowChart.legend.data = ['新增關注人數']
      $scope.userGrowChart.series[0].name = '新增關注人數'
      $scope.userGrowChart.series[0].data = _.pluck $scope.ui.grow_statis, 'subscribe_count'

    $scope.ui.search_type = type
    $scope.ui.search_type_name = name

  $scope.$watch 'ui.date', (newDate) ->
    if $scope.ui.date.startDate is null or $scope.ui.date.endDate is null or String(new Date($scope.ui.date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.date.endDate)) is 'Invalid Date'
      $scope.ui.date =
        startDate: moment().subtract(7, 'days')
        endDate: moment().subtract(1, 'days')
      growl.error('请输入正确的时间')

    if !$scope.ui.isContrast
      fetchData()
    else
      contrastChart $scope.ui.search_type_name

  $scope.$watch 'ui.contrast', (newDate) ->
    if $scope.ui.contrast.startDate is null or $scope.ui.contrast.endDate is null or String(new Date($scope.ui.contrast.startDate)) is 'Invalid Date' or String(new Date($scope.ui.contrast.endDate)) is 'Invalid Date'
      $scope.ui.contrast =
        startDate: moment().subtract(14, 'days')
        endDate: moment().subtract(7, 'days')
      growl.error('请输入正确的时间')

    if !$scope.ui.isContrast
      fetchData()
    else
      contrastChart $scope.ui.search_type_name

  $scope.$watch 'ui.currentPage', (newDate) ->
    fetchTable()

  $scope.$watch 'ui.table_date', (newDate) ->
    if $scope.ui.table_date.startDate is null or $scope.ui.table_date.endDate is null or String(new Date($scope.ui.table_date.startDate)) is 'Invalid Date' or String(new Date($scope.ui.table_date.endDate)) is 'Invalid Date'
      $scope.ui.table_date =
        startDate: moment().subtract(7, 'days')
        endDate: moment()
      growl.error('请输入正确的时间')

    fetchTable()

  $scope.contrastChart = contrastChart = (type) ->
    $scope.userGrowChart.tooltip.formatter = (params, ticket, callback) ->
      res = "#{params[0][0]}(#{params[0][1]}) :  #{params[0][2]}"
      old_date = params[0][0].match(/\d{4}.\d{1,2}.\d{1,2}/)[0]
      date_num = moment(params[0][1]).diff(moment(old_date), 'days')
      new_date = params[1][0].match(/\d{4}.\d{1,2}.\d{1,2}/)[0]
      test = moment(new_date).add(date_num, 'days').format('YYYY-MM-DD')
      res += "<br/>#{params[1][0]}(#{test}) :  #{params[1][2]}"
      setTimeout( ->
        callback ticket, res
      , 500)
      return 'loading'

    start_date = moment($scope.ui.date.startDate).format('YYYY.MM.DD')
    end_date = moment($scope.ui.date.endDate).format('YYYY.MM.DD')
    start_contrast_date = moment($scope.ui.contrast.startDate).format('YYYY.MM.DD')
    end_contrast_date = moment($scope.ui.contrast.endDate).format('YYYY.MM.DD')
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/grow_statis?access_token=" + Storage.get('access_token'),
      params:
        start_statistical_time: moment(start_date).format('YYYY-MM-DD 00:00:00')
        end_statistical_time: moment(end_date).format('YYYY-MM-DD 23:59:59')
    .then (res) ->
      _.each res.data.data, (val) ->
        val.statistical_time = moment(val.statistical_time).format('YYYY-MM-DD')
      $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/grow_statis?access_token=" + Storage.get('access_token'),
        params:
          start_statistical_time: moment(start_contrast_date).format('YYYY-MM-DD 00:00:00')
          end_statistical_time: moment(end_contrast_date).format('YYYY-MM-DD 23:59:59')
      .then (resContrast) ->
       _.each resContrast.data.data, (val) ->
        val.statistical_time = moment(val.statistical_time).format('YYYY-MM-DD')

        Y_date = if res.data.count > resContrast.data.count then res.data.data else resContrast.data.data
        $scope.userGrowChart.xAxis[0] =
          data: _.pluck Y_date, "statistical_time"
        seriesData = switch type
          when "新增關注人數" then _.pluck res.data.data, "subscribe_count"
          when "取消關注人數" then _.pluck res.data.data, "unsubscribe_count"
          when "淨增關注人數" then _.pluck res.data.data, "follower_count"
          when "累積關注人數" then _.pluck res.data.data, "total_count"
          when "活躍人數" then _.pluck res.data.data, "active_count"
        seriesContrastData = switch type
          when "新增關注人數" then _.pluck resContrast.data.data, "subscribe_count"
          when "取消關注人數" then _.pluck resContrast.data.data, "unsubscribe_count"
          when "淨增關注人數" then _.pluck resContrast.data.data, "follower_count"
          when "累積關注人數" then _.pluck resContrast.data.data, "total_count"
          when "活躍人數" then _.pluck resContrast.data.data, "active_count"
        $scope.userGrowChart.legend =
          data: ["#{start_date}至#{end_date}#{type}", "#{start_contrast_date}至#{end_contrast_date}#{type}"]
        $scope.userGrowChart.series = [
          name: "#{start_date}至#{end_date}#{type}"
          type: 'line'
          data: seriesData
        ,
          name: "#{start_contrast_date}至#{end_contrast_date}#{type}"
          type: 'line'
          data: seriesContrastData
        ]
      .catch (error) ->
        if error.data.error.indexOf('errtext') >= 0
          err = JSON.parse error.data.error
          growl.error err.errtext
        else
          growl.error('網絡連接失敗，請稍後重試')
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  $scope.userGrowClick = (param) ->
    return if $scope.ui.subscribe_type != 'all'
    return if $scope.ui.radioModel is '2' or $scope.ui.radioModel is '3'
    _type = ''
    _type = 'subscribe' if $scope.ui.radioModel is '0'
    _type = 'unsubscribe' if $scope.ui.radioModel is '1'
    dlg = dialogs.create('apps/analysis/user-analysis/user-details/user-details', 'UserDetailsCtrl', {type: _type, time: param.name}, {size: 'md'})
    dlg.result.then ((option) ->), ->

  # 下載資料
  prepare = (url) ->
    dialogs.wait '請稍後', '正在努力下載...', $scope.progress
    fakeProgress()

  success = (url) ->
    $rootScope.$broadcast 'dialogs.wait.complete'

  error = (response, url) ->
    dialogs.error '下載失敗!'

  fakeProgress = ->
    $timeout (->
      if $scope.progress < 95
        $scope.progress += (96 - ($scope.progress)) / 2
        $rootScope.$broadcast 'dialogs.wait.progress', 'progress': $scope.progress
        fakeProgress()
      else
        $rootScope.$broadcast 'dialogs.wait.complete'
    ), 100

  $scope.downloadData = ->
    $scope.progress = 0
    $.fileDownload "#{config.api.baseUrl}/#{$stateParams.original_id}/statistic/follower/grow_export?access_token=" + Storage.get('access_token'),
      httpMethod: 'POST'
      data:
        start_statistical_time: moment($scope.ui.table_date.startDate).format('YYYY-MM-DD 00:00:00')
        end_statistical_time: moment($scope.ui.table_date.endDate).format('YYYY-MM-DD 23:59:59')
      prepareCallback: prepare
      successCallback: success
      failCallback: error

  $scope.sortTable = (_sort) ->
    $scope.ui.activeSort = _sort
    $scope.ui.activeSortType = if $scope.ui.activeSort is _sort then !$scope.ui.activeSortType else true
    fetchTable()
