angular.module 'app'
.controller 'HeadlineSettingCtrl', ($scope, $http, $stateParams, $q, $state, config, Storage, growl, dialogs, SweetAlert) ->
  return $state.go 'app.wechat' if Storage.get('user_permission').campus.websiteSet.is_use == false

  $scope.ui =
    isLoading: false
    baseUrl: config.api.baseUrl
    user: Storage.get('login_user')

    currentPage: 1
    perPage: 20
    maxSize: 8
    totalItems: 0
    news: []
    showCount: 0

  do findShowCount = ->
    $http.get "#{config.api.baseUrl}/cumpus/headline",
      params:
        is_show: true
        group_id: $scope.ui.user.group_id
    .then (res) ->
      $scope.ui.showCount = res.data.count

  findData = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/cumpus/headline",
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        group_id: $scope.ui.user.group_id
        sort: 'sort'
        title: $scope.ui.title
    .then (res) ->
      $scope.ui.news = res.data.data
      $scope.ui.totalItems = res.data.count
      $scope.ui.isLoading = false
    .catch (error) ->
      $scope.ui.isLoading = false
      growl.error '查詢錯誤'

  $scope.$watch 'ui.currentPage', findData

  $scope.search = ->
    $scope.ui.currentPage = 1
    findData()

  $scope.createNew = ->
    dlg = dialogs.create('apps/campus/website-set/modal/add-content-select/add-content-select', 'AddContentSelectCtrl', {}, {size: 'lg'})
    dlg.result.then ((option) ->
      _url = ''
      _controller = ''
      _url = 'apps/campus/website-set/modal/article-content/article-content' if option is 'article'
      _url = 'apps/campus/website-set/modal/link-content/link-content' if option is 'link'
      _controller = 'ArticleContentCtrl' if option is 'article'
      _controller = 'LinkContentCtrl' if option is 'link'

      dlgLink = dialogs.create(_url, _controller, {type: 'headline', operationType: 'add'}, {size: 'lg'})
      dlgLink.result.then ((data) ->
        findData()
        findShowCount()
      ), ->
    ), ->

  $scope.delNew = (topNew) ->
    SweetAlert.swal {
      title: "你確定嗎?"
      text: "是否確定刪除“#{topNew.title}”頭條嗎？"
      type: "warning"
      showCancelButton: true
      confirmButtonClass: 'btn-danger'
      cancelButtonText: '取消'
      confirmButtonText: '確定'
    }, (isConfirm) ->
      if isConfirm
        $http.delete "#{config.api.baseUrl}/cumpus/headline/#{topNew._id}", {}
        .then (res) ->
          findData()
          findShowCount()
          growl.success '刪除成功'
        .catch (error) ->
          growl.error '刪除失敗'

  $scope.editNew = (topNew) ->
    _url = ''
    _controller = ''

    _url = 'apps/campus/website-set/modal/article-content/article-content' if topNew.type is 'article'
    _url = 'apps/campus/website-set/modal/link-content/link-content' if topNew.type is 'link'

    _controller = 'ArticleContentCtrl' if topNew.type is 'article'
    _controller = 'LinkContentCtrl' if topNew.type is 'link'

    dlgLink = dialogs.create(_url, _controller, {type: 'headline', operationType: 'edit', content: topNew}, {size: 'lg'})
    dlgLink.result.then ((data) ->
      findData()
      findShowCount()
    ), ->

  $scope.toUp = (index) ->
    return growl.error '已是第一位' if index is 0
    updata = angular.copy $scope.ui.news[index]
    updata.sort = updata.sort - 1
    downdata = angular.copy $scope.ui.news[index - 1]
    downdata.sort = downdata.sort + 1
    datas = [updata, downdata]
    promise = _.map datas, (val) ->
      $http.put "#{config.api.baseUrl}/cumpus/headline/#{val._id}", val
      .then (res) ->
    $q.all promise
    .then (res) ->
      growl.success '排序成功'
      findData()
    .catch (res) ->
      growl.error '排序失敗'

  $scope.top = (data) ->
    $http.post "#{config.api.baseUrl}/cumpus/headline/#{data._id}/top", data
    .then (res) ->
      growl.success '排序成功'
      findData()
    .catch (res) ->
      growl.error '排序失敗'

  $scope.toDown = (index) ->
    return growl.error '已是最後一位' if (index + 1) is $scope.ui.totalItems
    updata = angular.copy $scope.ui.news[index + 1]
    updata.sort = updata.sort - 1
    downdata = angular.copy $scope.ui.news[index]
    downdata.sort = downdata.sort + 1
    datas = [updata, downdata]
    promise = _.map datas, (val) ->
      $http.put "#{config.api.baseUrl}/cumpus/headline/#{val._id}", val
      .then (res) ->
    $q.all promise
    .then (res) ->
      growl.success '排序成功'
      findData()
    .catch (res) ->
      growl.error '排序失敗'

  $scope.editIsShow = (topNew) ->
    if topNew.is_show is true and $scope.ui.showCount is 8
      findData()
      growl.error '已滿8條頭條'
      return
    $http.put "#{config.api.baseUrl}/cumpus/headline/#{topNew._id}", {is_show: topNew.is_show}
    .then (res) ->
      growl.success '保存成功'
      findShowCount()
    .catch (error) ->
      topNew.is_show = !topNew.is_show
      growl.error '保存失敗'
