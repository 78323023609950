angular.module 'app'
.config ($stateProvider) ->
  $stateProvider
    .state 'app.pagetemplate',
      url: '/page-template'
      templateUrl: 'apps/page-template/page-template'
      controller: 'PageTemplateCtrl'

    .state 'app.addtemplate',
      url: '/add-template'
      templateUrl: 'apps/page-template/add/add'
      controller: 'AddTemplateCtrl'

    .state 'app.listtemplate',
      url: '/list-template/:_id'
      templateUrl: 'apps/page-template/list-template/list-template'
      controller: 'ListTemplateCtrl'
      resolve:
        loadPlugin: ($ocLazyLoad) ->
          $ocLazyLoad.load([
              name: 'css'
              insertAfter: '#app-level'
              files: [
                'bootstrap-daterangepicker/daterangepicker.css'
                'lightgallery/dist/css/lightbox.css'
              ]
            ,
              name: 'vendors'
              insertAfter: '#app-level-js'
              files: [
                'bootstrap-daterangepicker/daterangepicker.js'
                'Highcharts-4.2.4/js/highcharts.src.js'
              ]
          ])

    .state 'app.addlisttemplate',
      url: '/addlist-template'
      templateUrl: 'apps/page-template/add-template/add-template'
      controller: 'AddListTemplateCtrl'
