angular.module 'app'
.controller 'ArticleContentCtrl', ($uibModalInstance, $scope, $state, Storage, $http, $stateParams, ueditorConfig, config, growl, FileUploader, dialogs, data) ->
  $scope.ui =
    user: Storage.get('login_user')
    baseUrl: config.api.baseUrl
    resourcesUrl: config.resources.baseUrl

    data: data
    content:
      group_id: Storage.get('login_user').group_id
      type: 'article'
      head_img: ''
      is_show: false
      content: ''

    config:
      wordCount: true
      maximumWords: 20000
      wordCountMsg: '&nbsp;當前您已輸入&nbsp;{#count}&nbsp;個字符。'
      elementPathEnabled: false
      autoHeightEnabled: false
      autoFloatEnabled: false
      autoTransWordToList: true
      imagePopup: true
      insertorderedlist:
        'decimal': ''
        'lower-alpha': ''
        'lower-roman': ''
        'upper-alpha': ''
        'upper-roman': ''
      insertunorderedlist:
        circle: ''
        disc: ''
        square: ''
      lang: "zh-tw"
      initialFrameHeight: 300
      toolbars: ueditorConfig.ue.config.toolbars

  $scope.$on '$destroy', ->
    editor = UE.getEditor 'uecontainer'
    editor.destroy()

  ### 加载编辑器设置 ###
  _.defer ->
    uex('uecontainer', ->)

  do findData = ->
    if $scope.ui.data.operationType == 'edit'
      $http.get "#{config.api.baseUrl}/cumpus/#{$scope.ui.data.type}/#{$scope.ui.data.content._id}",
        params:
          is_content: true
          group_id: $scope.ui.user.group_id
      .then (res) ->
        $scope.ui.content = res.data
      .catch (error) ->
        growl.error '查詢錯誤'

  ### 上传 ###
  _uploadUrl = "#{config.api.baseUrl}/cumpus/upload/image?width=1024&height=683"
  _uploadUrl = "#{config.api.baseUrl}/cumpus/upload/image?width=524&height=140" if $scope.ui.data.type == "ad"
  uploader = $scope.uploader = new FileUploader(
    autoUpload: true
    url: _uploadUrl
  )

  uploader.filters.push
    name: 'imageFilter'
    fn: (item, options) ->
      if item.size / 1024 / 1024 > 0.5
        growl.error '文件大小超過限制!請選擇小於0.5M的圖片上傳。'
        return
      type = '|' + item.type.slice(item.type.lastIndexOf('/') + 1) + '|'
      '|jpg|png|jpeg|bmp|gif|'.indexOf(type) != -1

  uploader.onSuccessItem = (fileItem, response, status, headers) ->
    $scope.ui.content.head_img = response.url

  uploader.onErrorItem = (fileItem, response, status, headers) ->
    growl.error '上傳失敗', ttl: 2000

  $scope.openPic = ->
    dlg = dialogs.create('apps/material/image/choose/images', 'ImagesChooseCtrl', {radio: true, type: 'graphic'}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      ue = UE.getEditor("uecontainer")
      _.defer ->
        _.each materials, (valMaterial) ->
          ue.execCommand('inserthtml', "<img style='width:100%;max-width:100%;' src=" + $scope.ui.resourcesUrl + valMaterial.file.path + "></img>")
    ), ->

  # $scope.openVoice = ->
      # modalInstance = $modal.open
      #   templateUrl: "modules/library/graphic/add/voice/voice"
      #   windowClass: "new-task-win"
      #   controller: "SelectVoiceCtrl"

      # modalInstance.dismiss = (path) ->
      #   ue = UE.getEditor("container")
      #   _.defer ->
      #     ue.execCommand('inserthtml', "<p><audio style='width:100%;' src="+path+" controls='controls'></audio><br/></p>")

  $scope.openVideo = ->
    dlg = dialogs.create('apps/material/video/choose/choose', 'VideoChooseCtrl', {type: 'graphic', radio: true}, {size: 'md'})
    dlg.result.then ((materials) ->
      return if materials == 'Cancel'
      ue = UE.getEditor("uecontainer")
      _.defer ->
        ue.execCommand('inserthtml', '<iframe class="video_iframe" data-vidtype="2" style="z-index:1;" height="375" width="100%" frameborder="0" src="' + materials[0].file.wechat_url + '" allowfullscreen=""></iframe>')
    ), ->

    # modalInstance = $modal.open
    #   templateUrl: "modules/library/graphic/add/video/video"
    #   windowClass: "new-task-win"
    #   controller: "SelectVideoCtrl"

    # modalInstance.dismiss = (path) ->
    #   ue = UE.getEditor("container")
    #   htmlStr = "<p><video style='width:100%;' controls='controls' autobuffer='autobuffer' ><source src="+path+"></source></video><br/></p>"
    #   _.defer ->
    #     ue.execCommand('inserthtml', htmlStr)

  # $scope.openLink = ->
    # modalInstance = $modal.open
    #   templateUrl: "modules/library/graphic/add/link/link"
    #   controller: "InputLinkCtrl"

    # modalInstance.dismiss = (obj) ->
    #   ue = UE.getEditor("container")
    #   if obj.type is 'image'
    #     _.defer ->
    #       ue.execCommand('inserthtml', "<p><img src="+obj.link+"></img></p>")
    #   else if obj.type is 'voice'
    #     _.defer ->
    #       ue.execCommand('inserthtml', "<p><audio style='width:100%;' src="+obj.link+" controls='controls'></audio><br/></p>")
    #   else if obj.type is 'video'
    #     _.defer ->
    #       ue.execCommand('inserthtml', "<p><video style='width:100%;' controls='controls' autobuffer='autobuffer' ><source src="+obj.link+"></source></video><br/></p>")

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'

  $scope.save = ->
    if $scope.ui.data.operationType == 'add'
      $http.post "#{config.api.baseUrl}/cumpus/#{$scope.ui.data.type}",
        $scope.ui.content
      .then (res) ->
        $scope.ui.content = res.data
        growl.success '保存成功'
      .catch (error) ->
        growl.error '保存失敗'
    else
      $http.put "#{config.api.baseUrl}/cumpus/#{$scope.ui.data.type}/#{$scope.ui.content._id}",
        $scope.ui.content
      .then (res) ->
        growl.success '保存成功'
      .catch (error) ->
        growl.error '保存失敗'
