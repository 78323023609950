angular.module 'app'
.controller 'ReferenceMenuCtrl', ($rootScope, $scope, $http, $stateParams, $state, config, Storage, growl, dialogs, SweetAlert, $timeout, $uibModalInstance, data) ->

  $scope.ui =
    totalItems: 0
    currentPage: 1
    perPage: 7
    maxSize: 8
    individualization: []
    menuArr: data.menuArr
    wechatName: data.wechatName
    isLoading: true
    selected: ''

  # 获取个性化菜单列表
  findIndividualization = ->
    $scope.ui.isLoading = true
    $http.get "#{config.api.baseUrl}/#{$stateParams.original_id}/menu/all-menu?access_token=" + Storage.get('access_token'),
      params:
        limit: $scope.ui.perPage
        offset: ($scope.ui.currentPage - 1) * $scope.ui.perPage
        sort: '-created_at'
        menu_id: data.menu_id
    .then (res) ->
      _.each res.data.data, (data) ->
        data.created_at = moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')
      $scope.ui.individualization = res.data.data
      $scope.ui.totalItems = res.data.count
    .finally ->
      $scope.ui.isLoading = false
    .catch (error) ->
      if error.data.error.indexOf('errtext') >= 0
        err = JSON.parse error.data.error
        growl.error err.errtext
      else
        growl.error('網絡連接失敗，請稍後重試')

  # 引用菜單
  $scope.selectSingle = (data) ->
    $scope.ui.selected = data._id
    $scope.ui.menuArr = data.button
  $scope.isChecked = (data) ->
    if data._id == $scope.ui.selected
      return true
    else
      return false

  # 保存引用菜單
  $scope.save = ->
    if data.menuArr.length > 0
      SweetAlert.swal {
        title: "當前菜單已有按鈕設定，引用菜單后將會覆蓋原有設定"
        type: "warning"
        showCancelButton: true
        confirmButtonClass: 'btn-warning'
        confirmButtonText: '確定引用并覆蓋'
        cancelButtonText: '取消'
      }, (isConfirm) ->
        if isConfirm
          $uibModalInstance.close {
            menuArr: $scope.ui.menuArr
  }

  $scope.$watch 'ui.currentPage', findIndividualization

  $scope.cancel = ->
    $uibModalInstance.close 'Cancel'
